import {BaseModel} from "../shared/models/base.model";
export class Status extends BaseModel{
  description: string;
  type: string;

  constructor(object){
    super(object);
    if(object.description != undefined)
      this.description = object.description;
    if(object.type != undefined)
      this.type = object.type;
  }
}
