import {Component} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {NotificationService} from "../shared/notification/notification.service";
import { Location } from '@angular/common';
import {ActivatedRoute} from "@angular/router";
import {ConfigService} from "../config/config.service";
import {BaseService} from "../shared/services/base.service";
import {User} from "../user/user";

@Component({
  templateUrl: './config.component.html',
  styleUrls: ['config.component.css']
})

export class ConfigUpdateComponent{

  public title: string = 'Actualizar configuración';

  configForm = this.fb.group({
    strName_SponsoredPortfolio: ['', [Validators.required, Validators.maxLength(125)]],
    strName_PersonalPortfolio: ['', [Validators.required, Validators.maxLength(125)]],
    DefaultUserBalance: ['', [Validators.pattern('^[0-9]{1,8}([,.][0-9]{1,2})?$')]],
    strName_Stocks: ['', [Validators.required, Validators.maxLength(125)]],
    strName_Bonds: ['', [Validators.required, Validators.maxLength(125)]],
    strTextFavorite: ['', [Validators.required, Validators.maxLength(125)]],
    strStockFooter: ['', [Validators.required, Validators.maxLength(125)]]
  });

  constructor(
    private fb: FormBuilder,
    private location: Location,
    private configService: ConfigService,
    private activatedRouteService : ActivatedRoute,
    private notificationService : NotificationService
  ){

  }

  ngOnInit(){
      this.configService.getAll().subscribe(resp => {
        let confs = resp['result'];

        console.log(confs);

        confs.forEach(conf => {
          switch(conf['keyConfig']){
            case 'DefaultUserBalance':
              this.configForm.controls['DefaultUserBalance'].setValue(conf['valueConfig']);
              break;
            case 'strName_SponsoredPortfolio':
              this.configForm.controls['strName_SponsoredPortfolio'].setValue(conf['valueConfig']);
              break;
            case 'strName_PersonalPortfolio':
              this.configForm.controls['strName_PersonalPortfolio'].setValue(conf['valueConfig']);
              break;
            case 'strName_Stocks':
              this.configForm.controls['strName_Stocks'].setValue(conf['valueConfig']);
              break;
            case 'strName_Bonds':
              this.configForm.controls['strName_Bonds'].setValue(conf['valueConfig']);
              break;
            case 'strTextFavorite':
              this.configForm.controls['strTextFavorite'].setValue(conf['valueConfig']);
              break;
            case 'strStockFooter':
              this.configForm.controls['strStockFooter'].setValue(conf['valueConfig']);
              break;
          }
        });
      }, (err) => { this.notificationService.error('Hubo un problema cargando la información'); });

  }

  onlyNumberKey(event){
    if (event.charCode == 46) return true;
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  action($event){
    if(this.configForm.valid) {
      let obj = BaseService.builderObject(this.configForm.value);
        let conf = {
          'key': 'DefaultUserBalance',
          'value': obj['DefaultUserBalance']
        }
        this.configService.update(conf).subscribe(resp => {
          let conf2 = {
            'key': 'strName_SponsoredPortfolio',
            'value': obj['strName_SponsoredPortfolio']
          }
          this.configService.update(conf2).subscribe(resp2 => {
            let conf3 = {
              'key': 'strName_PersonalPortfolio',
              'value': obj['strName_PersonalPortfolio']
            }
            this.configService.update(conf3).subscribe(resp3 => {
              let conf4 = {
                'key': 'strName_Bonds',
                'value': obj['strName_Bonds']
              }
              this.configService.update(conf4).subscribe(resp4 => {
                let conf5 = {
                  'key': 'strName_Stocks',
                  'value': obj['strName_Stocks']
                }
                this.configService.update(conf5).subscribe(resp5 => {
                  let conf6 = {
                    'key': 'strTextFavorite',
                    'value': obj['strTextFavorite']
                  }
                  this.configService.update(conf6).subscribe(resp6 => {
                    let conf7 = {
                      'key': 'strStockFooter',
                      'value': obj['strStockFooter']
                    }
                    this.configService.update(conf7).subscribe(resp7=> {
                      this.notificationService.genericsuccess('Éxito', 'La configuracion fue actualizada exitosamente');
                    }, error2 => { this.notificationService.error('Error al actualizar la configuracion: Fuente de Indicadores'); });
                  }, error1 => { this.notificationService.error('Error al actualizar la configuracion: Mensaje de ayuda favoritos'); });
                }, (err) => { this.notificationService.error('Error al actualizar la configuracion: nombre de la seccion de acciones'); });
              }, (err) => { this.notificationService.error('Error al actualizar la configuracion: nombre de la seccion de bonos'); });
            }, (err) => { this.notificationService.error('Error al actualizar la configuracion: nombre del portafolio personal'); });
          }, (err) => { this.notificationService.error('Error al actualizar la configuracion: nombre del portafolio patrocinado'); });
        }, (err) => { this.notificationService.error('Error al actualizar la configuracion: saldo inicial del usuario'); });
    }
    else{
      this.notificationService.error('Verifique los datos del formulario');
    }
  }
}

