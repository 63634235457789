<div *ngIf="resetpage" class="container">
	<form [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword($event)" #f="ngForm" novalidate>
    <div *ngIf="reset" class="form-group">
      <label for="currentPassword">Contraseña actual</label>
      <input type="password" formControlName="currentPassword" class="form-control">
      <div *ngIf="!resetPasswordForm.controls['currentPassword'].valid" class="help-block">Contraseña actual no es valida</div>
    </div>
    <div class="form-group">
			<label for="newPassword">Nueva contraseña</label>
			<input type="password" minlength="6" formControlName="newPassword" class="form-control">
			<div *ngIf="!resetPasswordForm.controls['newPassword'].valid" class="help-block">Nueva contraseña no es valida</div>
      <div *ngIf="!resetPasswordForm.controls['newPassword'].valid" class="help-block">Contraseña debe tener 6 caracteres o más, al menos un caracter alfabetico y al menos un caracter numérico</div>
		</div>
		<div class="form-group">
			<label for="repeatedPassword">Repetir contraseña</label>
			<input type="password" minlength="6" formControlName="repeatedPassword" class="form-control">
			<div *ngIf="!resetPasswordForm.controls['repeatedPassword'].valid || (resetPasswordForm.controls['repeatedPassword'].value != resetPasswordForm.controls['newPassword'].value)" class="help-block">Contraseñas no coinciden</div>
		</div>
		<div class="form-group">
			<bt-text
	            [title]="'Cambiar'"
	            [type]="'submit'"
				      [text]="'Cambiar contraseña'"
	            (clicked)="'null'">
	        </bt-text>
		</div>
	</form>
</div>

<app-resetsuccess *ngIf="success"></app-resetsuccess>
