import {Injectable} from "@angular/core";
import {BaseService} from "../shared/services/base.service";
import {Observable} from "rxjs/Rx";
import {Group} from "./group";
import {HttpClient} from "@angular/common/http";
import {catchError} from "rxjs/operators";


@Injectable()
export class GroupService extends BaseService{

  BASE_URL: string = this.HOST +'/groups';

  constructor(
    private http: HttpClient){
    super();
  }

  getByUser(params : any = '{ }'): Observable<Object> {
    return this.http.get(this.HOST + '/user/groups', {
      params: params,
      headers: this.createAuthorizationHeader()
    }).pipe(
      catchError(this.handleError)
    );
  }

  getAll(params : any = '{ }'): Observable<Object> {
    return this.http.get(this.BASE_URL, {
      params: params,
      headers: this.createAuthorizationHeader()
    }).pipe(
      catchError(this.handleError)
    );
  }

  getById(id: number) : Observable<Group>{
    return this.http.get(this.BASE_URL + '/' + id,
      { headers: this.createAuthorizationHeader() }).map(this.extractData).pipe(
      catchError(this.handleError)
    );
  }

  create(group : Group) : Observable<Group>{
    return this.http.post(this.BASE_URL, group, { headers: this.createAuthorizationHeader() }).map(this.extractData).pipe(
      catchError(this.handleError)
    );
  }

  update(group : Group) : Observable<Group>{
    return this.http.put(this.BASE_URL + '/' + group.id, group,
      { headers: this.createAuthorizationHeader() }).map(this.extractData).pipe(
      catchError(this.handleError)
    );
  }
}
