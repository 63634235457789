import {Role} from "../role/role";

export class Group{
  id: string;
  description: string;
  active: boolean;
  updatable: boolean;
  roles: Role[];

  constructor(){
  }
}
