<div [hidden]="hiddenClose">
	<div class="dialog-overlay" (click)="hideDialog()"></div>
	<div class="dialog">
		<div class="tools row">
			<bt-icon
	            [title]="'close'"
	            [type]="'button'"
	            [icon]="'close'"
	            (clicked)="hideDialog()">
	        </bt-icon>
		</div>
		<div class="title">{{title || "Confirmation"}}</div>
		<div class="message">{{message}}</div>
		<div class="options row">
			<bt-text
				[title]="'no'"
				[type]="'button'"
				[text]="'no'"
				(clicked)="onNoClicked()">
			</bt-text>
			<bt-text
				[title]="'si'"
				[type]="'button'"
				[text]="'si'"
				(clicked)="onYesClicked()">
			</bt-text>
		</div>
	</div>
</div>
