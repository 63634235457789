<h3>{{title}}</h3>
<br>
<div class="container">
  <form [formGroup]="instrumentForm" name="instrumentForm" (ngSubmit)="action($event)" novalidate>
    <div class="form-group">
        <label for="idticker">Idticker</label>
        <input type="text" maxlength="255" formControlName="idticker" class="form-control" placeholder="Ingrese el idticker del instrumento" required><br>
        <div *ngIf="!instrumentForm.controls.idticker.valid" class="help-block">Idticker del instrumento es inválido</div>
    </div>
    <div class="form-group">
      <label for="isin">ISIN</label>
      <input type="text" maxlength="255" formControlName="isin" class="form-control" placeholder="Ingrese el ISIN del instrumento" required><br>
      <div *ngIf="!instrumentForm.controls.isin.valid" class="help-block">ISIN del instrumento es inválido</div>
    </div>
    <div class="form-group">
      <label for="currentPrice">Precio actual</label>
      <input type="text" maxlength="15" formControlName="currentPrice" (keypress)="onlyNumberKey($event)" class="form-control" placeholder="Ingrese el precio actual del instrumento" required><br>
      <div *ngIf="!instrumentForm.controls.currentPrice.valid" class="help-block">Precio actual del instrumento es inválido</div>
    </div>
    <div class="form-group">
      <label for="lastPrice">Último precio</label>
      <input type="text" maxlength="15" formControlName="lastPrice" (keypress)="onlyNumberKey($event)" class="form-control" placeholder="Ingrese el último precio del instrumento" required><br>
      <div *ngIf="!instrumentForm.controls.lastPrice.valid" class="help-block">Último precio del instrumento es inválido</div>
    </div>
    <div class="form-group">
      <label for="variation">Variación del precio</label>
      <input type="text" maxlength="9" formControlName="variation" (keypress)="onlyNumberKey($event)" class="form-control" placeholder="Ingrese la variación en el precio del instrumento" required><br>
      <div *ngIf="!instrumentForm.controls.variation.valid" class="help-block">Variación de precio del instrumento es inválida</div>
    </div>
    <div class="form-group">
      <label for="tradeVolume">Volumen de negociación</label>
      <input type="text" maxlength="15" formControlName="tradeVolume" (keypress)="onlyNumberKey($event)" class="form-control" placeholder="Ingrese el volumen de negociación del instrumento"><br>
      <div *ngIf="!instrumentForm.controls.tradeVolume.valid" class="help-block">Volumen de negociación del instrumento es inválido</div>
    </div>
    <div class="form-group">
      <label for="eps">EPS</label>
      <input type="text" maxlength="15" formControlName="eps" (keypress)="onlyNumberKey($event)" class="form-control" placeholder="Ingrese el EPS del instrumento"><br>
      <div *ngIf="!instrumentForm.controls.eps.valid" class="help-block">EPS del instrumento es inválido</div>
    </div>
    <div class="form-group">
      <label for="marketCap">Capital de mercado</label>
      <input type="text" maxlength="12" formControlName="marketCap" (keypress)="onlyNumberKey($event)" class="form-control" placeholder="Ingrese el capital de mercado del instrumento"><br>
      <div *ngIf="!instrumentForm.controls.marketCap.valid" class="help-block">Capital de mercado del instrumento es inválido</div>
    </div>
    <div class="form-group">
      <label for="nominalValue">Valor nominal</label>
      <input type="text" maxlength="20" formControlName="nominalValue" (keypress)="onlyNumberKey($event)" class="form-control" placeholder="Ingrese el valor nominal del instrumento"><br>
      <div *ngIf="!instrumentForm.controls.nominalValue.valid" class="help-block">Valor nominal del instrumento es inválido</div>
    </div>
    <div class="form-group">
      <label for="negotiatedAmount">Valor de negociación</label>
      <input type="text" maxlength="15" formControlName="negotiatedAmount" (keypress)="onlyNumberKey($event)" class="form-control" placeholder="Ingrese el valor de negociación del instrumento"><br>
      <div *ngIf="!instrumentForm.controls.negotiatedAmount.valid" class="help-block">Valor de negociación del instrumento es inválido</div>
    </div>
    <div class="form-group">
      <label for="serieAmount">Monto serie</label>
      <input type="text" maxlength="15" formControlName="serieAmount" (keypress)="onlyNumberKey($event)" class="form-control" placeholder="Ingrese el monto serie del instrumento"><br>
      <div *ngIf="!instrumentForm.controls.serieAmount.valid" class="help-block">Monto serie del instrumento es inválido</div>
    </div>
    <div class="form-group">
      <label for="rate">Tasa</label>
      <input type="text" maxlength="255" formControlName="rate" class="form-control" placeholder="Ingrese la tasa del instrumento"><br>
      <div *ngIf="!instrumentForm.controls.rate.valid" class="help-block">Tasa del instrumento es inválida</div>
    </div>
    <div class="form-group">
      <label for="lastDateDividend">Fecha del último dividendo</label>
      <input type="date" min="2010-01-01" formControlName="lastDateDividend" (keypress)="onlyNumberKey($event)" class="form-control"><br>
      <div *ngIf="!instrumentForm.controls.lastDateDividend.valid" class="help-block">Fecha del último dividendo es inválida</div>
    </div>
    <div class="form-group">
      <label for="issueDate">Fecha de emisión</label>
      <input type="date" min="2010-01-01" formControlName="issueDate" (keypress)="onlyNumberKey($event)" class="form-control" required><br>
      <div *ngIf="!instrumentForm.controls.issueDate.valid" class="help-block">Fecha de emisión es inválida</div>
    </div>
    <div class="form-group">
      <label for="expirationDate">Fecha de vencimiento</label>
      <input type="date" min="2010-01-01" formControlName="expirationDate" (keypress)="onlyNumberKey($event)" class="form-control"><br>
      <div *ngIf="!instrumentForm.controls.expirationDate.valid" class="help-block">Fecha de vencimiento es inválida</div>
    </div>
    <div class="form-group">
      <label for="class">Clase</label>
      <select  [(ngModel)]="selectedClass" [ngModelOptions]="{standalone: true}" required>
        <option *ngFor="let class of classes" [ngValue]="class">{{class.name}}</option>
      </select>
    </div>
    <div class="form-group">
      <label for="issuer">Emisor</label>
      <select  [(ngModel)]="selectedIssuer" [ngModelOptions]="{standalone: true}" required>
        <option *ngFor="let issuer of issuers" [ngValue]="issuer">{{issuer.name}}</option>
      </select>
    </div>
    <div class="form-group">
      <label for="status">Estado</label>
      <select  [(ngModel)]="selectedStatus" [ngModelOptions]="{standalone: true}" required>
        <option *ngFor="let stat of status" [ngValue]="stat">{{stat.description}}</option>
      </select>
    </div>
    <br><br>
    <div class="form-group">
      <bt-text
        [title]="'Aceptar'"
        [type]="'submit'"
        [text]="'Guardar'"
        (clicked)="'null'">
      </bt-text>
    </div>
  </form>
</div>
