import {Routes} from "@angular/router/router";
import {BannerComponent} from "./banner.component";
import {BannerUpdateComponent} from "./banner-update.component";
import {BannerGuardService} from "./banner-guard.service";

export const bannerRoutes: Routes = [
  {
    path: 'banner',
    component: BannerComponent,
    canActivateChild: [BannerGuardService],
    data: {
      breadcrumb: "Banner",
      icon: "list"
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: BannerUpdateComponent,
        data: {
          breadcrumb: undefined
        }
      }
      ]
  }
]
