import { Injectable } from '@angular/core';
import {NotificationsService} from "angular2-notifications";

@Injectable()
export class NotificationService {
	constructor(private service: NotificationsService) { }

	sucessInsert(name?: string) {
		if (name) {
			this.service.success('Creado Correctamente', `¡El registro '${name}' ha sido creado`);
		} else {
			this.service.success('Creado(s) Correctamente', `El(los) registro(s) han sido creado(s)`);
		}
	}

	sucessUpdate(name?: string) {
		if (name) {
			this.service.success('Actualizado Correctamente', `¡El registro '${name}' ha sido actualizado!`);
		} else {
			this.service.success('Creado(s) Correctamente', `El(los) registro(s) han sido creado(s)`);
		}
	}

	sucessDelete(name?: string) {
		if (name) {
			this.service.success('Borrado Correctamente', `¡El registro '${name}' ha sido borrado!`);
		} else {
			this.service.success('Borrado Correctamente', `¡El(los) registro(s) han sido borrado(s)!`);
		}
	}

	sucessLogin() {
		this.service.success('Login Exitoso', 'Ha ingresado correctamente');
	}

	errorDuplicated(name: string) {
		this.service.error('Registro duplicado', `El registro '${name}' ya existe en el sistema`);
	}

	error(msg = 'Hubo un problema. Intentelo mas tarde') {
		this.service.error('Error', msg);
	}

	alert(msg?: string) {
		this.service.alert('Notificación', msg);
	}

	genericsuccess(title: string, body: string) {
		this.service.success(title, body);
	}

	genericerror(title: string, body: string) {
		this.service.error(title, body);
	}
}
