<header>
  <div *mkLoadingPage="{checkPendingRoute: true, checkPendingHttp: true}">
    <mk-sliding-bar colorOne="#4a2ff7" colorTwo="#00994C"></mk-sliding-bar>
  </div>
  <br><br>
  <usertool></usertool> </header>
<side-nav></side-nav>

<div id="breadcrumbs">
	<breadcrumbs></breadcrumbs>
</div>

<div id="data">
  <router-outlet></router-outlet>
</div>

<footer></footer>

