import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {SharedModule} from "../shared/shared.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {homeRouting} from "../home/home.routes";
import {HttpClientModule} from "@angular/common/http";
import {GroupComponent} from "./group.component";
import {GroupListComponent} from "./group-list.component";
import {GroupCreateComponent} from "./group-create.component";
import {GroupUpdateComponent} from "./group-update.component";
import {GroupReadComponent} from "./group-read.component";
import {GroupService} from "./group.service";
import {TableService} from "../shared/table-ns/table-ns.service";
import {RoleService} from "../role/role.service";
import { UserGuardService } from "../user/user-guard.service";

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    homeRouting,
    ReactiveFormsModule,
    HttpClientModule
  ],
  declarations:[
    GroupComponent,
    GroupListComponent,
    GroupCreateComponent,
    GroupUpdateComponent,
    GroupReadComponent
  ],
  providers:[
    GroupService,
    RoleService,
    UserGuardService
  ]
})

export class GroupModule { }
