import {Component, ViewChild} from "@angular/core";
import {IndiceType} from "./indicetype";
import {TableColumn} from "../shared/table-ns/table-ns-column";
import {ToolBase} from "../shared/tool-ns/tool/tool-base";
import {Confirmation} from "../shared/confirmation-ns/confirmation-ns.service";
import {QuestionFilterBase} from "../shared/filter-ns/question-filter/question-filter-base";
import {Router, ActivatedRoute} from "@angular/router";
import {TableService} from "../shared/table-ns/table-ns.service";
import {NotificationService} from "../shared/notification/notification.service";
import {IndiceTypeService} from "./indicetype.service";
import {StatusService} from "../status/status.service";
import {QuestionFilterTextbox} from "../shared/filter-ns/question-filter/question-filter-textbox";
import {IconTool} from "../shared/tool-ns/tool/tool-icon";
import {FilterService} from "../shared/filter-ns/filter-ns.service";

@Component({
  templateUrl: '../utils/crud/list/list.component.html',
  styleUrls: ['./indicetype-list.component.css']
})

export class IndiceTypeListComponent {
  public title: string = 'Tipos de índices';
  public tableTitle: string = '';
  public actions2: any[] = [];
  public items: Object[];

  //Se utiliza el campo updatedAt para mostrar el nombre del estado en el que se encuentra el tipo de indice
  //Se utiliza el campo createdAt para mostrar si se muestra o no en la aplicacion movil
  cols: TableColumn[] = [
    new TableColumn({name: 'Nombre', key: 'name', proportion: 8}),
    new TableColumn({name: 'Descripción', key: 'description', proportion: 14}),
    new TableColumn({name: 'Home', key: 'createdAt', proportion: 2}),
    new TableColumn({name: 'Estado',key: 'updatedAt', proportion: 6})
  ];

  tools: ToolBase<any>[] = [
    new IconTool({
      title: 'Crear',
      icon: 'create',
      clicked: this.create.bind(this)
    }),
    new IconTool({
      title: 'Eliminar',
      icon: 'delete',
      clicked: this.confirmationDeletes.bind(this)
    })
  ];

  actions = [
    {
      html: (item) => 'Actualizar',
      click: (item) => this.update(item)
    },
    {
      html: (item) => 'Eliminar',
      click: (item) => this.confirmationDelete(item)
    },{
      html: (item) => 'Índices de mercado',
      click: (item) => this.stockindice(item)
    },
  ];

  confirmation: Confirmation = {hiddenClose: true};
  @ViewChild('tableCmp') tableCmp;
  pager: any;
  questionFilters: QuestionFilterBase<any>[] = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private indicetypeService: IndiceTypeService,
    private statusService : StatusService,
    private tableService: TableService,
    private filterService: FilterService,
    private notificationService: NotificationService,
  ){}

  ngOnInit(){
    this.tableService.setSort(this.cols[0].key);
    this.filter();
    this.list(this.tableService.pager.pageIndex);
  }

  filter(){
    //Componente para filtrar de acuerdo al nombre del tipo de indice
    let questionFilterName =
      new QuestionFilterTextbox({
        key: 'name',
        label: 'Nombre',
        value: this.filterService.filter['name']!=undefined? this.filterService.filter['name']: '',
        optionsKey: 'name',
        proportion: 2
      });

    this.questionFilters = [
      questionFilterName
    ];
  }

  list(page?: number){

    //Fixing sort
    if(this.tableService.sort == 'createdAt')
      this.tableService.sort = 'showIndiceHome';
    else if(this.tableService.sort == '-createdAt')
      this.tableService.sort = '-showIndiceHome';
    else if(this.tableService.sort == 'updatedAt')
      this.tableService.sort = 'status';
    else if(this.tableService.sort == '-updatedAt')
      this.tableService.sort = '-status';

    this.indicetypeService.getAll(this.indicetypeService.buildRequestOptionsFinder(this.tableService.sort,
    undefined, this.filterService.filter, {pageIndex: page, pageSize: this.tableService.pager.pageSize})).subscribe(
      response => {
        this.items = response['result'];
        //Por cada tipo de indice se busca su estado
        for (let item of this.items) {
          this.statusService.getById(item['status']['id']).subscribe(status => {
            item['updatedAt'] = status.description;
          });
          item['createdAt'] = item['showIndiceHome'] ? 'Si' : 'No';
        }
        this.pager = response['pager'];
        this.tableService.pager.pageIndex = page;
        this.tableCmp.deselectAll();
        this.filterService.filter = {};
      }, (err) => {
        this.notificationService.error('Error del servidor');
      }
    );
  }

  create(){
    this.router.navigate(['./create'], {relativeTo: this.activatedRoute});
  }

  read(item: IndiceType){
    this.router.navigate(['./' + item.id], {relativeTo: this.activatedRoute});
  }

  confirmationDeletes(){
    let ids: number[] = this.tableService.getSelectKeys();
    if(ids.length > 0)
      this.confirmation = {
        title : 'Confirmacion de eliminacion',
        message: "¿Está seguro que desea eliminar los tipos de índices seleccionados?",
        yesClicked: this.deletes.bind(this, ids),
        hiddenClose: false
      };
  }

  update(item: IndiceType){
    this.router.navigate(["./" + item.id + '/update'], {relativeTo: this.activatedRoute});
  }

  stockindice(item: IndiceType){
    this.router.navigate(['./' + item.id + '/stockindices'], {relativeTo: this.activatedRoute});
  }

  confirmationDelete(item){
    this.confirmation = {
      title : "Confirmacion de eliminación",
      message: "¿Está seguro que desea eliminar el tipo de índice seleccionado?",
      yesClicked: this.delete.bind(this, item),
      hiddenClose: false
    };
  }

  deletes(this, ids: number[]){
    if(!this.indicetypeService.checkRoles(['indicetype_manager'])) {
      this.notificationService.error('Usted no cuenta con acceso a esta función. Contacte al administrador');
      return;
    }
    for(let id of ids){
      this.indicetypeService.getById(id).subscribe(indicetype => {
        let type = new IndiceType(indicetype);
        type.status.id = 2;
        this.indicetypeService.update(type).subscribe(any => {
          this.tableService.setSort(this.cols[0].key);
          this.filter();
          this.list(this.tableService.pager.pageIndex);
        }, (err) => {this.notificationService.error(err); });
      }, (err) => { this.notificationService.error(err); });
    }
  }

  delete(this, item: IndiceType){
    if(!this.indicetypeService.checkRoles(['indicetype_manager'])) {
      this.notificationService.error('Usted no cuenta con acceso a esta función. Contacte al administrador');
      return;
    }
    this.indicetypeService.getById(item.id).subscribe(indicetype => {
      let type = new IndiceType(indicetype);
      type.status.id = 2;
      this.indicetypeService.update(type).subscribe(any => {
        this.tableService.setSort(this.cols[0].key);
        this.filter();
        this.list(this.tableService.pager.pageIndex);
      }, (err) => {this.notificationService.error(err); });
    }, (err) => { this.notificationService.error(err); });
  }
}
