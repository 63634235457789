import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {SharedModule} from "../shared/shared.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {homeRouting} from "../home/home.routes";
import {HttpClientModule} from "@angular/common/http";
import {StatusService} from "../status/status.service";
import {ClassService} from "../class/class.service";
import {IssuerService} from "../issuer/issuer.service";
import {InstrumentService} from "./instrument.service";
import {InstrumentUpdateComponent} from "./instrument-update.component";
import {InstrumentComponent} from "./instrument.component";
import {InstrumentListComponent} from "./instrument-list.component";
import {InstrumentReadComponent} from "./instrument-read.component";
import { InstrumentGuardService } from "./instrument-guard.service";

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    homeRouting,
    ReactiveFormsModule,
    HttpClientModule
  ],
  declarations:[
    InstrumentComponent,
    InstrumentReadComponent,
    InstrumentListComponent,
    InstrumentUpdateComponent
  ],
  providers:[
    InstrumentService,
    IssuerService,
    ClassService,
    StatusService,
    InstrumentGuardService
  ]
})

export class InstrumentModule { }
