import {Component} from '@angular/core';
import {Status} from "../status/status";
import {NotificationService} from "../shared/notification/notification.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Confirmation} from "../shared/confirmation-ns/confirmation-ns.service";
import {ToolBase} from "../shared/tool-ns/tool/tool-base";
import {IconTool} from "../shared/tool-ns/tool/tool-icon";
import {StatusService} from "../status/status.service";
import {StockIndice} from "./stockindice";
import {StockIndiceService} from "./stockindice.service";

@Component({
  templateUrl: './stockindice-read.component.html',
  styleUrls: ['stockindice-read.component.css']
})

export class StockIndiceReadComponent{

  public stockIndice: StockIndice;
  public status : Status;
  toolsLeft: ToolBase<any>[] = [];

  tools: ToolBase<any>[] = [
    new IconTool({
      title: "Editar",
      icon: "update",
      clicked: this.update.bind(this)
    }),
    new IconTool({
      title: "Eliminar",
      icon: "delete",
      clicked: this.confirmationDelete.bind(this)
    })
  ];
  confirmation: Confirmation = {hiddenClose: true};


  constructor(
    private router: Router,
    private stockindiceService : StockIndiceService,
    private statusService : StatusService,
    private activatedRouteService : ActivatedRoute,
    private notificationService : NotificationService
  ){
  }

  ngOnInit(){
    this.activatedRouteService.parent.params.subscribe(param => {

      this.stockindiceService.getById(param['stockindiceID']).subscribe(result => {
        this.stockIndice = result;

        this.activatedRouteService.parent.parent.parent.params.subscribe(param => {

          if(this.stockIndice.indiceType.id != param['indicetypeID']){
            this.notificationService.genericerror('URL no válida', '');
            this.router.navigateByUrl('/home');
            return;
          }

        let stat = result.status.id;
        this.statusService.getById(stat).subscribe(status => {
          this.status = status;

        }, (err) => { this.notificationService.error('Hubo un problema obteniendo los datos de la URL'); });
        }, (err) => { this.notificationService.error('Hubo un problema cargando la información'); });
      }, (err) => { this.notificationService.error('Hubo un problema cargando la información'); });
    }, (err) => { this.notificationService.error('Hubo un problema obteniendo los datos de la URL'); });
  }

  update(){
    this.router.navigate(['./update'], {relativeTo: this.activatedRouteService});
  }

  confirmationDelete(){
    this.confirmation = {
      message: "¿Está seguro que desea eliminar el índice de mercado?",
      yesClicked: this.delete.bind(this),
      hiddenClose: false
    };
  }

  returnVariation(variation : number) : string{
    switch(variation){
      case 1:
        return "Aumento";
        break;  
      case 2:
        return "Bajo";
        break;
      default:
        return "Mantiene";
    }
  }

  delete(this){
    if(!this.stockindiceService.checkRoles(['stockindice_manager'])) {
      this.notificationService.error('Usted no cuenta con acceso a esta función. Contacte al administrador');
      return;
    }
    this.stockindiceService.getById(this.stockIndice.id).subscribe(stockindice => {
        let indice = new StockIndice(stockindice);
        indice.status.id = 2;
        this.stockindiceService.update(indice).subscribe(any => {
          this.ngOnInit();
          this.notificationService.sucessUpdate(this.stockIndice.name);
      }, (err) => {this.notificationService.error(err); });
      }, (err) => { this.notificationService.error(err); });
    }
}
