import {Routes} from "@angular/router/router";
import {SeasonComponent} from "./season.component";
import {SeasonListComponent} from "./season-list.component";
import {SeasonCreateComponent} from "./season-create.component";
import {SeasonReadComponent} from "./season-read.component";
import {SeasonUpdateComponent} from "./season-update.component";
import { SeasonGuardService } from './season-guard.service';

export const seasonRoutes: Routes = [
  {
    path: 'seasons',
    component: SeasonComponent,
    canActivateChild: [SeasonGuardService],
    data: {
      breadcrumb: "Temporadas",
      icon: "list"
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: SeasonListComponent,
        data: {
          breadcrumb: undefined
        }
      },
      {
        path:'create',
        component: SeasonCreateComponent,
        canActivate: [SeasonGuardService],
        data: {
          breadcrumb : "Crear"
        }
      },
      {
        path: ':seasonID',
        component: SeasonComponent,
        data: {
          breadcrumb: "Detalle",
          icon: "detail"
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: SeasonReadComponent,
            data: {
              breadcrumb: undefined,
            }
          },
          {
            path: 'update',
            component: SeasonUpdateComponent,
            canActivate: [SeasonGuardService],
            data: {
              breadcrumb: "Actualizar",
              icon: "update"
            }
          }
        ]
      }
    ]
  }
]
