import { Http,Headers,Response ,RequestOptions,URLSearchParams} from '@angular/http';
import { Observable } from 'rxjs/Rx';
import { environment } from '../../../environments/environment';
import {HttpErrorResponse, HttpParams, HttpHeaders} from "@angular/common/http";

export class BaseService{

    public HOST: string = environment.backendUrl;
    public token : string = "";

    public createAuthorizationHeader(): HttpHeaders {
      let headers = new HttpHeaders({ 'Authorization' : 'Bearer ' + sessionStorage.getItem('token') });
      return headers;
    }

    public checkRoles(roles : string[]) : boolean{
      let access = false;
      let rolesSession = <string[]> JSON.parse(sessionStorage.getItem('roles'));
      rolesSession.forEach( rol => {
        if(roles.indexOf(rol) != -1){
          access = true;
        }
      });
      return access;
    }

    public extractData(res: any) {
        return res.result || { };
    }

    public extractDataFull(res: Response) {
        return res.json();
    }

    public extractHeaders(res: Response) {
        return res.headers;
    }

    public extractDataAndHeaders(res: Response) {
        let body = res.json();
        return { data: body.result || { }, headers: res.headers };
    }

    public handleResponse(res: Response) {
        return res.ok;
    }

    public buildRequestOptionsFinder(sort?: string, collection?: string, filter?: {}, pager?: {pageIndex: number, pageSize: number}): any{
        let params = {};

        if(sort != undefined){
            params['sort'] = sort;
        }
        if(collection != undefined){
            params['collection'] = collection;
        }
        for (var key in filter){
            params[key] = filter[key];
        }
        if(pager != undefined){
            params['pager.index'] = pager.pageIndex.toString();
            params['pager.size'] = pager.pageSize.toString();
        }

        return params;
    }

  protected handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof Error) {
      // A client-side or network error occurred.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Error ${err.status} ${err.statusText}`;
    }
    console.error(errorMessage);
    return Observable.throw(errorMessage);
  }

    //http://stackoverflow.com/questions/18936915/dynamically-set-property-of-nested-object

     public static builderObject(json: {}): {}{
        let obj = {};
        for(var key in json){
            let innerObj = obj;
            let keySplits = key.split('.');

            for(var i = 0; i < keySplits.length-1; ++i){
                let keySplit = keySplits[i];
                if( !innerObj[keySplit] ) {
                    innerObj[keySplit] = {};
                }
                innerObj = innerObj[keySplit];
            }

            innerObj[keySplits[keySplits.length-1]] = (json[key] == '') ? null : json[key];
        }
        return obj;
    }

    public removeItem(items: any[], id: number){
        for(let i = 0; items.length; ++i)
            if(items[i].id == id){
                items.splice(i, 1);
                return;
            }
    }

    public removeItems(items: any[], ids: number[]){
        ids.map(id => this.removeItem(items, id));
    }

   public handleErrorCreate (error: Response | any) {
        if (error instanceof Response) {
            if(error.status == 400)
                return Observable.throw(error.json().message);
            if(error.status == 409)
                return Observable.throw(error.json().message);
        }
        return Observable.throw(error.json().message);
    }

     public static handleErrorUpdate (error: Response | any) {
        if (error instanceof Response) {
            if(error.status == 400)
                return Observable.throw(error.json().message);
            if(error.status == 404)
                return Observable.throw(error.json().message);
            if(error.status == 409)
                return Observable.throw(error.json().message);
        }
        return Observable.throw(error.json().message);
    }

    public static handleErrorDelete (error: Response | any) {
        if (error instanceof Response) {
            if(error.status == 409)
                return Observable.throw(error.json().message);
        }
        return Observable.throw(error.json().message);
    }
}
