<h3>{{title}}</h3>
<br>
<div class="container">
  <form [formGroup]="groupForm" name="groupForm" (ngSubmit)="action($event)" novalidate>
    <div class="form-group">
        <label for="id">Identificador</label>
        <input type="text" [readonly]="readonly" maxlength="20" formControlName="id" class="form-control" placeholder="Ingrese el identificador del grupo" required><br>
        <div *ngIf="!groupForm.controls.id.valid" class="help-block">Identificador del grupo no es valido</div>
    </div>
    <div class="form-group">
        <label for="description">Descripción</label>
        <textarea maxlength="190" formControlName="description" class="form-control" placeholder="Ingrese la descripción del grupo"></textarea>
    </div>
    <div class="form-group">
      <label for="roles">Roles</label>
    </div>
    <filter-ns *ngIf="questionFilters!=undefined"
               [questionFilters]="questionFilters"
               (list)="list($event)">
    </filter-ns>
    <table-ns-menu
      #tableCmp
      [title]="tableTitle"
      [items]="items"
      [cols]="cols"
      [actions2]="actions2"
      [actions]="actions"
      (read)="read($event)"
      (list)="list($event)">
    </table-ns-menu>

    <pagination *ngIf="pager!=undefined && pager.pages > 0"
                [totalItems]="pager.totalEntities"
                [initPage]="pager.pageIndex+1"
                [pageSize]="pager.pageSize"
                (paginated)=list($event)>
    </pagination><br>
    <div class="form-group">
      <label for="status">Estado</label>
      <select  [(ngModel)]="selectedStatus" [ngModelOptions]="{standalone: true}" required>
        <option [ngValue]="true">Activo</option>
        <option [ngValue]="false">Inactivo</option>
      </select>
    </div>
    <br><br>
    <div class="form-group">
      <bt-text
        [title]="'Aceptar'"
        [type]="'submit'"
        [text]="'Guardar'"
        (clicked)="'null'">
      </bt-text>
    </div>
  </form>
</div>
