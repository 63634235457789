<h3>Información del índice de mercado</h3>

<tool-ns
  [tools]="tools"
  [toolsLeft]="toolsLeft">
</tool-ns>

<div class="read">
  <div *ngIf="stockIndice != undefined && status != undefined">
    <div class="form-group">
      <div class="form-subgroup">
        <p> <label>Tipo de Índice</label> {{ stockIndice.indiceType.name }} </p>
      </div>
    </div>
  <div class="form-group">
    <div class="form-subgroup">
      <p> <label>Nombre</label> {{ stockIndice.name }} </p>
    </div>
  </div>
  <div class="form-group">
    <div class="form-subgroup">
      <p><label>Tipo</label> {{ stockIndice.type }} </p>
    </div>
  </div>
  <div class="form-group">
    <div class="form-subgroup">
      <p><label>Valor</label> {{ stockIndice.value }} </p>
    </div>
  </div>
  <div class="form-group">
    <div class="form-subgroup">
      <p><label>Valor actual</label> {{ stockIndice.currentValue }} </p>
    </div>
  </div>
    <div class="form-group">
      <div class="form-subgroup">
        <p><label>Valor de cambio</label> {{ stockIndice.changeValue }} </p>
      </div>
    </div>
    <div class="form-group">
    <div class="form-subgroup">
      <p><label>Variación</label> {{ returnVariation(stockIndice.variation) }} </p>
    </div>
  </div>
    <div class="form-group">
      <div class="form-subgroup">
        <p><label>Porcentaje de cambio</label> {{ stockIndice.changePercent }} % </p>
      </div>
    </div>
  <div class="form-group">
    <div class="form-subgroup">
      <p> <label>Mostrar en la pantalla principal</label> {{ stockIndice.showMainHome ? 'Si' : 'No' }} </p>
    </div>
  </div>

  <div class="form-group">
    <div class="form-subgroup">
      <p> <label>Estado</label> {{ status.description }} </p>
    </div>
  </div>
</div>
</div>
<br>
<br>
<confirm-ns
  [title]="confirmation.title"
  [message]="confirmation.message"
  [(hiddenClose)]="confirmation.hiddenClose"
  (yesClicked)="confirmation.yesClicked($event)">
</confirm-ns>
