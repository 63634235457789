
import {Injectable} from "@angular/core";
import {HttpParams, HttpClient} from "@angular/common/http";
import {Observable} from "rxjs/Rx";
import {BaseService} from "../shared/services/base.service";
import {catchError} from "rxjs/operators";
import {Issuer} from "./issuer";

@Injectable()
export class IssuerService extends BaseService{

  BASE_URL: string = this.HOST +'/issuers';

  constructor(
    private http: HttpClient){
    super();
  }

  getAll(params : any = '{}'): Observable<Object> {
    return this.http.get(this.BASE_URL, {
      params: params,
      headers: this.createAuthorizationHeader()
    }).pipe(
      catchError(this.handleError)
    );
  }

  getById(id: number) : Observable<Issuer>{
    return this.http.get(this.BASE_URL + '/' + id, { headers: this.createAuthorizationHeader() }).map(this.extractData).pipe(
      catchError(this.handleError)
    );
  }
}
