<h3>Información de la noticia</h3>

<tool-ns
  [tools]="tools"
  [toolsLeft]="toolsLeft">
</tool-ns>

  <div *ngIf="post != undefined && status != undefined">
    <div class="form-group">
      <div class="form-subgroup">
        <p> <label>Proveedor</label> {{ post.provider.name }} </p>
      </div>
    </div>
  <div class="form-group">
    <div class="form-subgroup">
      <p><label>Título</label> </p>
    </div>
  </div><br>
    <div>
      {{ (post.title.length>100) ? (post.title | slice:0:100)+'..' : (post.title) }}
    </div><br>
  <div class="form-group">
    <div class="form-subgroup">
      <p><label>Descripción</label></p>
    </div>
  </div><br>
  <div>{{ (post.description.length>100) ? (post.description | slice:0:100)+'..' : (post.description) }}</div><br>
    <div class="form-group">
      <p><label>Imagen principal</label></p>
    </div>
    <img src="{{ post.mainImageUrl }}" width="480px" height="240px"/><br>
  <div class="form-group">
    <div class="form-subgroup">
      <p><label>Contenido</label></p>
      </div>
    </div>
    <div class="fr-view" [innerHTML]="post.content | safeHtml" id="content">
    </div><br>
    <div class="form-group">
      <div class="form-subgroup">
        <p><label>Fuente</label> {{ post.source }} </p>
      </div>
    </div>
    <div class="form-group">
      <div class="form-subgroup">
        <p><label>Fecha</label> {{ post.date | date:"dd/MM/yy" }} </p>
      </div>
    </div>

   <p><label>URL</label></p> {{ (post.url.length>100) ? (post.url | slice:0:100)+'..' : (post.url) }} <br><br>

  <div class="form-group">
    <div class="form-subgroup">
      <p> <label>Estado</label> {{ status.description }} </p>
    </div>
  </div>
</div>
<br>
<br>
<confirm-ns
  [title]="confirmation.title"
  [message]="confirmation.message"
  [(hiddenClose)]="confirmation.hiddenClose"
  (yesClicked)="confirmation.yesClicked($event)">
</confirm-ns>
