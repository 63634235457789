import { Injectable } from '@angular/core';
import {CanActivateChild, Router} from '@angular/router';
import {NotificationService} from "../shared/notification/notification.service";
import {BaseService} from "../shared/services/base.service";

@Injectable()
export class ConfigGuardService extends BaseService implements CanActivateChild{
  constructor(public router: Router,
              private notificationService: NotificationService) {
    super();
  }

  canActivateChild(): boolean {
    if(!this.checkRoles(['config_manager'])) {
      this.notificationService.error('Usted no cuenta con acceso a esta función. Contacte al administrador');
      return false;
    }
    return true;
  }
}
