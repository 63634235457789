import {Component} from '@angular/core';
import {NotificationService} from "../shared/notification/notification.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Confirmation} from "../shared/confirmation-ns/confirmation-ns.service";
import {ToolBase} from "../shared/tool-ns/tool/tool-base";
import {IconTool} from "../shared/tool-ns/tool/tool-icon";
import {Group} from "./group";
import {GroupService} from "./group.service";

@Component({
  templateUrl: './group-read.component.html',
  styleUrls: ['group-read.component.css']
})

export class GroupReadComponent{

  public group: Group;
  toolsLeft: ToolBase<any>[] = [];

  tools: ToolBase<any>[] = [
    new IconTool({
      title: "Editar",
      icon: "update",
      clicked: this.update.bind(this)
    }),
    new IconTool({
      title: "Eliminar",
      icon: "delete",
      clicked: this.confirmationDelete.bind(this)
    })
  ];
  confirmation: Confirmation = {hiddenClose: true};


  constructor(
    private router: Router,
    private groupService : GroupService,
    private activatedRouteService : ActivatedRoute,
    private notificationService : NotificationService
  ){
  }

  ngOnInit(){
    this.activatedRouteService.parent.params.subscribe(param => {

      this.groupService.getById(param['groupID']).subscribe(result => {
        this.group = result;

      }, (err) => { this.notificationService.error('Hubo un problema cargando la información'); })
    }, (err) => { this.notificationService.error('Hubo un problema obteniendo los datos de la URL'); });
  }

  update(){
    this.router.navigate(['./update'], {relativeTo: this.activatedRouteService});
  }

  confirmationDelete(){
    this.confirmation = {
      message: "¿Está seguro que desea eliminar el grupo?",
      yesClicked: this.delete.bind(this),
      hiddenClose: false
    };
  }

  delete(this){
  this.groupService.getById(this.group.id).subscribe(group => {
      let gr = new Group();
      gr.id = group.id;
      gr.active = false;
      this.groupService.update(gr).subscribe(any => {
        this.ngOnInit();
        this.notificationService.sucessUpdate(this.group.id);
    }, (err) => {this.notificationService.error(err); });
    }, (err) => { this.notificationService.error(err); });
  }
}
