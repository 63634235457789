<h3>{{title}}</h3>
<br>
<div class="container">
  <form [formGroup]="indiceTypeForm" name="indiceTypeForm" (ngSubmit)="action($event)" novalidate>
    <div class="form-group">
        <label for="name">Nombre</label>
        <input type="text" maxlength="125" formControlName="name" class="form-control" placeholder="Ingrese nombre del tipo de índice" required><br>
        <div *ngIf="!indiceTypeForm.controls.name.valid" class="help-block">Nombre del tipo de índice es inválido</div>
    </div>
    <div class="form-group">
        <label for="description">Descripción</label>
        <textarea formControlName="description" class="form-control" placeholder="Ingrese descripción del tipo de índice"></textarea>
        <div *ngIf="!indiceTypeForm.controls.description.valid" class="help-block">Descripción del tipo de índice es inválido</div>
    </div>
    <div class="form-group">
      <label for="showIndiceHome">¿Mostrar en la pantalla principal?</label>
      <select  [(ngModel)]="selectedShowIndiceHome" [ngModelOptions]="{standalone: true}" required>
        <option [ngValue]="true"> Si </option>
        <option [ngValue]="false"> No </option>
      </select>
    </div>
    <div class="form-group">
      <label for="status">Estado</label>
      <select  [(ngModel)]="selectedStatus" [ngModelOptions]="{standalone: true}" required>
        <option *ngFor="let stat of status" [ngValue]="stat">{{stat.description}}</option>
      </select>
    </div>
    <br><br>
    <div class="form-group">
      <bt-text
        [title]="'Aceptar'"
        [type]="'submit'"
        [text]="'Guardar'"
        (clicked)="'null'">
      </bt-text>
    </div>
  </form>
</div>
