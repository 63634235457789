<h3>Información de la temporada</h3>

<tool-ns
  [tools]="tools"
  [toolsLeft]="toolsLeft">
</tool-ns>

<div class="read">
  <div *ngIf="season != undefined && status != undefined && histories != undefined">
    <div class="form-group">
      <div class="form-subgroup">
        <p> <label>Nombre</label> {{ season.name }} </p>
      </div>
    </div>
    <div class="form-group">
      <div class="form-subgroup">
        <p><label>Fecha de inicio</label> {{  season.startDate | date:"dd/MM/yy"}} </p>
      </div>
    </div>
    <div class="form-group">
      <div class="form-subgroup">
        <p><label>Fecha de fin</label> {{  season.endDate | date:"dd/MM/yy"}} </p>
      </div>
    </div>
    <div class="form-group">
      <div class="form-subgroup">
        <p> <label>Último(s) ganador(es)</label></p>
      </div>
    </div>
    <div class="form-group" *ngIf="histories.length === 0">
      <div class="form-subgroup">
        <p>No disponible</p>
      </div>
    </div>
    <div *ngIf="histories.length > 0">
      <div class="form-group" *ngFor="let winner of histories[0].winners">
        <div class="form-subgroup">
          <p> {{ winner.name + " " + winner.lastName + " (" + winner.authUser.email + ")"  }} </p><br>
          <p> <strong>Puntuación: </strong> {{ histories[0].performance }} </p>
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="form-subgroup">
        <p> <label>Estado</label> {{ status.description }} </p>
      </div>
    </div>
  </div>
</div>
<br>
<br>
<confirm-ns
  [title]="confirmation.title"
  [message]="confirmation.message"
  [(hiddenClose)]="confirmation.hiddenClose"
  (yesClicked)="confirmation.yesClicked($event)">
</confirm-ns>
