import {Component, ViewChild} from "@angular/core";
import {TableColumn} from "../shared/table-ns/table-ns-column";
import {ToolBase} from "../shared/tool-ns/tool/tool-base";
import {Confirmation} from "../shared/confirmation-ns/confirmation-ns.service";
import {QuestionFilterBase} from "../shared/filter-ns/question-filter/question-filter-base";
import {Router, ActivatedRoute} from "@angular/router";
import {TableService} from "../shared/table-ns/table-ns.service";
import {NotificationService} from "../shared/notification/notification.service";
import {StatusService} from "../status/status.service";
import {QuestionFilterTextbox} from "../shared/filter-ns/question-filter/question-filter-textbox";
import {IconTool} from "../shared/tool-ns/tool/tool-icon";
import {FilterService} from "../shared/filter-ns/filter-ns.service";
import {InstrumentService} from "./instrument.service";
import {Instrument} from "./instrument";

@Component({
  templateUrl: '../utils/crud/list/list.component.html',
  styleUrls: ['./instrument-list.component.css']
})

export class InstrumentListComponent {
  public title: string = 'Instrumentos';
  public tableTitle: string = '';
  public actions2: any[] = [];
  public items: Object[];

  cols: TableColumn[] = [
    new TableColumn({name: 'Idticker', key: 'idticker', proportion: 6}),
    new TableColumn({name: 'ISIN', key: 'isin', proportion: 6}),
    new TableColumn({name: 'Precio actual', key: 'currentPrice', proportion: 6}),
    new TableColumn({name: 'Emisor', key: 'createdAt', proportion: 8}),
    new TableColumn({name: 'Estado',key: 'updatedAt', proportion: 6})
  ];

  tools: ToolBase<any>[] = [
    new IconTool({
      title: 'Eliminar',
      icon: 'delete',
      clicked: this.confirmationDeletes.bind(this)
    })
  ];

  actions = [
    {
      html: (item) => 'Actualizar',
      click: (item) => this.update(item)
    },
    {
      html: (item) => 'Eliminar',
      click: (item) => this.confirmationDelete(item)
    }
  ];

  confirmation: Confirmation = {hiddenClose: true};
  @ViewChild('tableCmp') tableCmp;
  pager: any;
  questionFilters: QuestionFilterBase<any>[] = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private instrumentService: InstrumentService,
    private statusService : StatusService,
    private tableService: TableService,
    private filterService: FilterService,
    private notificationService: NotificationService,
  ){}

  ngOnInit(){
    this.tableService.setSort(this.cols[0].key);
    this.filter();
    this.list(this.tableService.pager.pageIndex);
  }

  filter(){
    //Componente para filtrar de acuerdo al idticker,isin, issuer.name del instrumento
    let questionFilterSearch =
      new QuestionFilterTextbox({
        key: 'search',
        label: 'Buscar',
        value: this.filterService.filter['search']!=undefined? this.filterService.filter['search']: '',
        optionsKey: 'search',
        proportion: 2
      });

    this.questionFilters = [
      questionFilterSearch
    ];
  }

  list(page?: number){

    //Fixing sort
    if(this.tableService.sort == 'createdAt')
      this.tableService.sort = 'issuer.name';
    else if(this.tableService.sort == '-createdAt')
      this.tableService.sort = '-issuer.name';
    else if(this.tableService.sort == 'updatedAt')
      this.tableService.sort = 'status';
    else if(this.tableService.sort == '-updatedAt')
      this.tableService.sort = '-status';

    this.instrumentService.getAll(this.instrumentService.buildRequestOptionsFinder(this.tableService.sort,
    undefined, this.filterService.filter, {pageIndex: page, pageSize: this.tableService.pager.pageSize})).subscribe(
      response => {
        this.items = response['result'];
        //Por cada tipo de indice se busca su estado
        for (let item of this.items) {
          this.statusService.getById(item['status']['id']).subscribe(status => {
            item['updatedAt'] = status.description;
          });
          item['createdAt'] = item['issuer']['name'];
        }
        this.pager = response['pager'];
        this.tableService.pager.pageIndex = page;
        this.tableCmp.deselectAll();
        this.filterService.filter = {};
      }, (err) => {
        this.notificationService.error('Error del servidor');
      }
    );
  }

  read(item: Instrument){
    this.router.navigate(['./' + item.id], {relativeTo: this.activatedRoute});
  }

  confirmationDeletes(){
    let ids: number[] = this.tableService.getSelectKeys();
    if(ids.length > 0)
      this.confirmation = {
        title : 'Confirmacion de eliminacion',
        message: "¿Está seguro que desea eliminar los instrumentos seleccionados?",
        yesClicked: this.deletes.bind(this, ids),
        hiddenClose: false
      };
  }

  update(item: Instrument){
    this.router.navigate(["./" + item.id + '/update'], {relativeTo: this.activatedRoute});
  }

  confirmationDelete(item){
    this.confirmation = {
      title : "Confirmacion de eliminación",
      message: "¿Está seguro que desea eliminar el instrumento seleccionado?",
      yesClicked: this.delete.bind(this, item),
      hiddenClose: false
    };
  }

  deletes(this, ids: number[]){
    if(!this.instrumentService.checkRoles(['instrument_assistant','instrument_manager'])) {
      this.notificationService.error('Usted no cuenta con acceso a esta función. Contacte al administrador');
      return;
    }
    for(let id of ids){
      this.instrumentService.getById(id).subscribe(instrument => {
        instrument.status = {"id": 2};
        instrument.issuer = {"id": instrument.issuer.id};
        instrument.instrumentClass = {"id": instrument.instrumentClass.id};
        delete instrument.createdAt;
        delete instrument.updatedAt;
        this.instrumentService.update(instrument).subscribe(any => {
          this.tableService.setSort(this.cols[0].key);
          this.filter();
          this.list(this.tableService.pager.pageIndex);
        }, (err) => {this.notificationService.error(err); });
      }, (err) => { this.notificationService.error(err); });
    }
  }

  delete(this, item: Instrument){
    if(!this.instrumentService.checkRoles(['instrument_assistant','instrument_manager'])) {
      this.notificationService.error('Usted no cuenta con acceso a esta función. Contacte al administrador');
      return;
    }
    this.instrumentService.getById(item.id).subscribe(instrument => {
      instrument.status = {"id": 2};
      instrument.issuer = {"id": instrument.issuer.id};
      instrument.instrumentClass = {"id": instrument.instrumentClass.id};
      delete instrument.createdAt;
      delete instrument.updatedAt;
      this.instrumentService.update(instrument).subscribe(any => {
        this.tableService.setSort(this.cols[0].key);
        this.filter();
        this.list(this.tableService.pager.pageIndex);
      }, (err) => {this.notificationService.error(err); });
    }, (err) => { this.notificationService.error(err); });
  }
}
