import {Injectable} from "@angular/core";
import {BaseService} from "../shared/services/base.service";
import {Observable} from "rxjs/Rx";
import {HttpClient} from "@angular/common/http";
import {catchError} from "rxjs/operators";
import {Post} from "./post";


@Injectable()
export class PostService extends BaseService{

  BASE_URL: string = this.HOST +'/posts';

  constructor(
    private http: HttpClient){
      super();
  }

  getAll(params : any = '{ }'): Observable<Object> {
      return this.http.get(this.BASE_URL, {
        params: params,
        headers: this.createAuthorizationHeader()
      }).pipe(
        catchError(this.handleError)
      );
  }

  getById(id: number) : Observable<Post>{
    return this.http.get(this.BASE_URL + '/' + id,
      { headers: this.createAuthorizationHeader() }).map(this.extractData).pipe(
      catchError(this.handleError)
    );
  }

  create(post : Post) : Observable<Post>{
    return this.http.post(this.BASE_URL, post,
      { headers: this.createAuthorizationHeader() }).map(this.extractData).pipe(
      catchError(this.handleError)
    );
  }

  update(post : Post) : Observable<Post>{
    return this.http.put(this.BASE_URL + '/' + post.id, post,
      { headers: this.createAuthorizationHeader() }).map(this.extractData).pipe(
      catchError(this.handleError)
    );
  }
}
