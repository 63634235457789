<h3>Información del proveedor</h3>

<tool-ns
  [tools]="tools"
  [toolsLeft]="toolsLeft">
</tool-ns>

<div class="read">
  <div *ngIf="provider != undefined && status != undefined">
  <div class="form-group">
    <div class="form-subgroup">
      <p> <label>Nombre</label> {{ provider.name }} </p>
    </div>
  </div>
  <div class="form-group">
    <div class="form-subgroup">
      <p><label>Tipo</label> {{ provider.type }} </p>
    </div>
  </div>
  <div class="form-group">
    <div class="form-subgroup">
      <p> <label>URL</label> {{ provider.url }} </p>
    </div>
  </div>

  <div class="form-group">
    <div class="form-subgroup">
      <p> <label>Estado</label> {{ status.description }} </p>
    </div>
  </div>
</div>
</div>
<br>
<br>
<confirm-ns
  [title]="confirmation.title"
  [message]="confirmation.message"
  [(hiddenClose)]="confirmation.hiddenClose"
  (yesClicked)="confirmation.yesClicked($event)">
</confirm-ns>
