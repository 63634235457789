import { Component, Inject} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import { AuthService } from '../auth.service';
import { Global } from '../..//global';
import { NotificationService } from '../../../shared/notification/notification.service';
import {AuthorizationRequest} from "../oauth2/authorizationRequest";
import {UserService} from "../../../user/user.service";
import {RoleService} from "../../../role/role.service";
import {Role} from "../../../role/role";
import {GroupService} from "../../../group/group.service";
import {Group} from "../../../group/group";

@Component({
	selector: 'login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css'],
})
export class LoginComponent {

  opened:boolean;
  loginForm:FormGroup;

  constructor(@Inject(FormBuilder) fb:FormBuilder,
              private authService:AuthService,
              private router:Router,
              private userService : UserService,
              private roleService : RoleService,
              private groupService : GroupService,
              private activatedRoute: ActivatedRoute,
              private notificationService:NotificationService) {
    this.loginForm = fb.group({
      email: ['', [Validators.required, Validators.pattern(Global.EMAIL_PATTERN)]],
      password: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.opened = false;
  }

  login($event) {
    // debugger;
    // console.log(this.loginForm.get('email').status + ' ' + this.loginForm.get('password').status);
    // console.log(this.isValid('email') + ' ' +  this.isValid('password'));
    if (this.loginForm.controls['email'].valid && this.loginForm.controls['password'].valid) {
      let auth = new AuthorizationRequest();
      auth.client_id = 'web_site';
      auth.username = this.loginForm.controls['email'].value;
      auth.password = this.loginForm.controls['password'].value;
      auth.grant_type = 'password';
      this.authService.login(auth).subscribe(accessTokenResponse => {
        sessionStorage.setItem('token', accessTokenResponse.access_token);

        this.userService.getById(accessTokenResponse.user_id).subscribe(user => {
        localStorage.setItem('panabolsawebadmin', JSON.stringify({user: user}));

        this.roleService.getByUser().subscribe(response => {
          let rolesResponse = <Role[]> response['result'];

          let roles = [];
          rolesResponse.map( (value) => {
            roles.push(value.id);
          });

          sessionStorage.setItem('roles', JSON.stringify(roles));

          this.groupService.getByUser().subscribe(resp => {
              let groups = <Group[]> resp['result'];
              let permit = true;

              if (groups.length === 0) permit = false;

              groups.forEach( (group) => {
                  if(group.id === 'client'){
                    permit = false;
                  }
              });

              if(permit) {
                this.notificationService.sucessLogin();
                this.router.navigate(['/home'], {relativeTo: this.activatedRoute});
              }else{
                this.notificationService.error('Usted no cuenta con los permisos necesarios para acceder al portal');
              }
          }
         , (err) => {
            this.notificationService.error('Error del servidor');
            }); }, (err) => {
          this.notificationService.error('Error del servidor');
        });
        }, (err) => {
          this.notificationService.error('Error del servidor');
        });
      }, (err) => {
        if(err == 'Error 400 Bad Request')
          this.notificationService.error('Email o contraseña inválidos');
        else
          this.notificationService.error('Error del servidor');
      });
    }
  }

  showDialog() {
    this.opened = true;
  }

  onOpen(isOpened: boolean) {
    this.opened = isOpened;
  }

}
