<h3>Información del cliente</h3>

<tool-ns
  [tools]="tools"
  [toolsLeft]="toolsLeft">
</tool-ns>

<div class="read">
  <div *ngIf="user != undefined">
    <div class="form-group">
      <div class="form-subgroup">
        <p> <label>Nombre y Apellido</label> {{ user.name + " " + user.lastName }} </p>
      </div>
    </div>
    <div class="form-group">
      <div class="form-subgroup">
        <p><label>Email</label> {{ user.authUser.email }} </p>
      </div>
    </div>
    <div class="form-group">
      <div class="form-subgroup">
        <p><label>Saldo</label> {{ user.balance }} </p>
      </div>
    </div>
    <div class="form-group">
      <div class="form-subgroup">
        <p><label>Desempeño</label> {{ user.performance }} </p>
      </div>
    </div>
  </div>
</div>
<br>
<br>
<confirm-ns
  [title]="confirmation.title"
  [message]="confirmation.message"
  [(hiddenClose)]="confirmation.hiddenClose"
  (yesClicked)="confirmation.yesClicked($event)">
</confirm-ns>
