<h3>{{title}}</h3>
<br>
<div class="container">
  <form [formGroup]="configForm" name="configForm" (ngSubmit)="action($event)" novalidate>
    <div class="form-group">
      <label for="strName_SponsoredPortfolio">Nombre del portafolio sponsored</label>
      <input type="text" maxlength="125" class="form-control" formControlName="strName_SponsoredPortfolio" placeholder="Ingrese nombre del portafolio patrocionado" required>
      <div *ngIf="!configForm.controls.strName_SponsoredPortfolio.valid" class="help-block">Nombre del portafolio patrocinado invalido</div>
    </div>
    <div class="form-group">
      <label for="strName_PersonalPortfolio">Nombre del portafolio personal</label>
      <input type="text" maxlength="125" class="form-control" formControlName="strName_PersonalPortfolio" placeholder="Ingrese nombre del portafolio personal" required>
      <div *ngIf="!configForm.controls.strName_PersonalPortfolio.valid" class="help-block">Nombre del portafolio personal invalido</div>
    </div>
    <div class="form-group">
      <label for="DefaultUserBalance">Saldo inicial del usuario</label>
      <input type="text" class="form-control" formControlName="DefaultUserBalance" (keypress)="onlyNumberKey($event)" required>
    </div>
    <div class="form-group">
      <label for="strName_Stocks">Nombre de la seccion de acciones</label>
      <input type="text" class="form-control" formControlName="strName_Stocks" placeholder="Ingrese nombre de la seccion de acciones" required>
      <div *ngIf="!configForm.controls.strName_Stocks.valid" class="help-block">Nombre de la seccion de acciones invalida</div>
    </div>
    <div class="form-group">
      <label for="strName_Bonds">Nombre de la seccion de bonos</label>
      <input type="text" class="form-control" formControlName="strName_Bonds" placeholder="Ingrese nombre de la seccion de bonos" required>
      <div *ngIf="!configForm.controls.strName_Bonds.valid" class="help-block">Nombre de la seccion de bonos es invalida</div>
    </div>
    <div class="form-group">
      <label for="strTextFavorite">Mensaje de ayuda Favoritos</label>
      <input type="text" class="form-control" formControlName="strTextFavorite" placeholder="Ingrese MEnsaje" required>
      <div *ngIf="!configForm.controls.strTextFavorite.valid" class="help-block">Mensaje es invalido</div>
    </div>
    <div class="form-group">
      <label for="strStockFooter"> Fuente de Indicadores</label>
      <input type="text" class="form-control" formControlName="strStockFooter" placeholder="Ingrese Fuente de Indicadores" required>
      <div *ngIf="!configForm.controls.strStockFooter.valid" class="help-block">Fuente de indicadores Invalido</div>
    </div>
    <br><br>
    <div class="form-group">
      <bt-text
        [title]="'Aceptar'"
        [type]="'submit'"
        [text]="'Guardar'"
        (clicked)="action($event)">
      </bt-text>
    </div>
  </form>
</div>

