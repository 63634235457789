<h3>Información del grupo</h3>

<tool-ns
  [tools]="tools"
  [toolsLeft]="toolsLeft">
</tool-ns>

<div class="read">
  <div *ngIf="group != undefined">
    <div class="form-group">
      <div class="form-subgroup">
        <p> <label>Identificador</label> {{ group.id }} </p>
      </div>
    </div>
  <div class="form-group">
      <p> <label>Descripción</label> {{ (group.description.length>60) ? (group.description | slice:0:60)+'..' : (group.description) }} </p>
  </div>
    <div class="form-group">
      <div class="form-subgroup">
        <p> <label>Roles</label></p>
      </div>
    </div>
    <div class="form-group" *ngFor="let rol of group.roles">
      <div class="form-subgroup">
        <p> {{ rol.id }} </p>
      </div>
      <div class="form-subgroup">
        <p> {{ rol.description }} </p>
      </div>
    </div>
  <div class="form-group">
    <div class="form-subgroup">
      <p><label>Estado</label> {{ group.active ? 'Activo' : 'Inactivo' }}  </p>
    </div>
  </div>
</div>
</div>
<br>
<br>
<confirm-ns
  [title]="confirmation.title"
  [message]="confirmation.message"
  [(hiddenClose)]="confirmation.hiddenClose"
  (yesClicked)="confirmation.yesClicked($event)">
</confirm-ns>
