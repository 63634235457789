import {BaseModel} from "../shared/models/base.model";
import {AuthUser} from "./authuser";
export class User extends BaseModel{
  authUser : AuthUser;
  name : string;
  lastName : string;
  balance : number;
  performance : number;

  constructor(object){
    super(object);
    if(object.authUser != undefined) this.authUser = new AuthUser(object.authUser);
    if(object.name != undefined) this.name = object.name;
    if(object.lastName != undefined) this.lastName = object.lastName;
    if(object.balance != undefined) this.balance = object.balance;
    if(object.performance != undefined) this.performance = object.performance;
  }
}
