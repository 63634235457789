import {Component, ViewChild} from "@angular/core";
import {TableColumn} from "../shared/table-ns/table-ns-column";
import {ToolBase} from "../shared/tool-ns/tool/tool-base";
import {Confirmation} from "../shared/confirmation-ns/confirmation-ns.service";
import {QuestionFilterBase} from "../shared/filter-ns/question-filter/question-filter-base";
import {Router, ActivatedRoute} from "@angular/router";
import {TableService} from "../shared/table-ns/table-ns.service";
import {NotificationService} from "../shared/notification/notification.service";
import {QuestionFilterTextbox} from "../shared/filter-ns/question-filter/question-filter-textbox";
import {IconTool} from "../shared/tool-ns/tool/tool-icon";
import {FilterService} from "../shared/filter-ns/filter-ns.service";
import {GroupService} from "./group.service";
import {Group} from "./group";

@Component({
  templateUrl: '../utils/crud/list/list.component.html',
  styleUrls: ['./group-list.component.css']
})

export class GroupListComponent {
  public title: string = 'Grupos';
  public tableTitle: string = '';
  public actions2: any[] = [];
  public items: Object[];

  cols: TableColumn[] = [
    new TableColumn({name: 'Identificador', key: 'id', proportion: 6}),
    new TableColumn({name: 'Descripción', key: 'description', proportion: 14}),
    new TableColumn({name: 'Estado', key: 'createdAt', proportion: 6}),
  ];

  tools: ToolBase<any>[] = [
    new IconTool({
      title: 'Crear',
      icon: 'create',
      clicked: this.create.bind(this)
    }),
    new IconTool({
      title: 'Eliminar',
      icon: 'delete',
      clicked: this.confirmationDeletes.bind(this)
    })
  ];

  actions = [
    {
      html: (item) => 'Actualizar',
      click: (item) => this.update(item)
    },
    {
      html: (item) => 'Eliminar',
      click: (item) => this.confirmationDelete(item)
    }
  ];

  confirmation: Confirmation = {hiddenClose: true};
  @ViewChild('tableCmp') tableCmp;
  pager: any;
  questionFilters: QuestionFilterBase<any>[] = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private groupService : GroupService,
    private tableService: TableService,
    private filterService: FilterService,
    private notificationService: NotificationService,
  ){}

  ngOnInit(){
    this.tableService.setSort(this.cols[0].key);
    this.filter();
    this.list(this.tableService.pager.pageIndex);
  }

  filter(){
    let questionFilterDescription =
      new QuestionFilterTextbox({
        key: 'description',
        label: 'Descripción',
        value: this.filterService.filter['description']!=undefined? this.filterService.filter['description']: '',
        optionsKey: 'description',
        proportion: 2
      });
    this.questionFilters = [
      questionFilterDescription
    ];
  }

  list(page?: number){

    this.filterService.filter['updatable'] = "true";

    this.groupService.getAll(this.groupService.buildRequestOptionsFinder(this.tableService.sort,
      undefined, this.filterService.filter, {pageIndex: page, pageSize: this.tableService.pager.pageSize})).subscribe(
      response => {
        console.log(response);
        this.items = response['result'];
        for (let item of this.items) {
          item['createdAt'] = item['active'] ? 'Activo' : 'Inactivo';
        }
        this.pager = response['result']['pager'];
        this.tableService.pager.pageIndex = page;
        this.tableCmp.deselectAll();
        this.filterService.filter = {};

      }, (err) => {
        this.notificationService.error('Error del servidor');
      }
    );
  }

  create(){
    this.router.navigate(['./create'], {relativeTo: this.activatedRoute});
  }

  read(item: Group){
    this.router.navigate(['./' + item.id], {relativeTo: this.activatedRoute});
  }

  confirmationDeletes(){
    let ids: number[] = this.tableService.getSelectKeys();
    if(ids.length > 0)
      this.confirmation = {
        title : 'Confirmacion de eliminacion',
        message: "¿Está seguro que desea eliminar los grupos seleccionados?",
        yesClicked: this.deletes.bind(this, ids),
        hiddenClose: false
      };
  }

  update(item: Group){
    this.router.navigate(["./" + item.id + '/update'], {relativeTo: this.activatedRoute});
  }

  confirmationDelete(item){
    this.confirmation = {
      title : "Confirmacion de eliminación",
      message: "¿Está seguro que desea eliminar el grupo seleccionado?",
      yesClicked: this.delete.bind(this, item),
      hiddenClose: false
    };
  }

  delete(this, item: Group){
    this.groupService.getById(item.id).subscribe(group => {
      let gr = new Group();
      gr.id = group.id;
      gr.active = false;
      this.groupService.update(<Group> gr).subscribe(any => {
        this.tableService.setSort(this.cols[0].key);
        this.filter();
        this.list(this.tableService.pager.pageIndex);
      }, (err) => {this.notificationService.error(err); });
    }, (err) => { this.notificationService.error(err); });
  }

  deletes(this, ids: number[]){
    for(let id of ids){
      this.groupService.getById(id).subscribe(group => {
        let gr = new Group();
        gr.id = group.id;
        gr.active = false;
        this.groupService.update(<Group> gr).subscribe(any => {
          this.tableService.setSort(this.cols[0].key);
          this.filter();
          this.list(this.tableService.pager.pageIndex);
        }, (err) => {this.notificationService.error(err); });
      }, (err) => { this.notificationService.error(err); });
    }
  }
}
