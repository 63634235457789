<h3>Información del tipo de índice</h3>

<tool-ns
  [tools]="tools"
  [toolsLeft]="toolsLeft">
</tool-ns>

<div class="read">
  <div *ngIf="indiceType != undefined && status != undefined">
  <div class="form-group">
    <div class="form-subgroup">
      <p> <label>Nombre</label> {{ indiceType.name }} </p>
    </div>
  </div>
  <div class="form-group">
    <div class="form-subgroup">
      <p><label>Descripción</label> {{ indiceType.description }} </p>
    </div>
  </div>
  <div class="form-group">
    <div class="form-subgroup">
      <p> <label>Mostrar en la pantalla principal</label> {{ indiceType.showIndiceHome ? 'Si' : 'No' }} </p>
    </div>
  </div>

  <div class="form-group">
    <div class="form-subgroup">
      <p> <label>Estado</label> {{ status.description }} </p>
    </div>
  </div>
</div>
</div>
<br>
<br>
<confirm-ns
  [title]="confirmation.title"
  [message]="confirmation.message"
  [(hiddenClose)]="confirmation.hiddenClose"
  (yesClicked)="confirmation.yesClicked($event)">
</confirm-ns>
