import {Component, ViewChild} from "@angular/core";
import {TableColumn} from "../shared/table-ns/table-ns-column";
import {ToolBase} from "../shared/tool-ns/tool/tool-base";
import {Confirmation} from "../shared/confirmation-ns/confirmation-ns.service";
import {QuestionFilterBase} from "../shared/filter-ns/question-filter/question-filter-base";
import {Router, ActivatedRoute} from "@angular/router";
import {TableService} from "../shared/table-ns/table-ns.service";
import {NotificationService} from "../shared/notification/notification.service";
import {StatusService} from "../status/status.service";
import {QuestionFilterTextbox} from "../shared/filter-ns/question-filter/question-filter-textbox";
import {IconTool} from "../shared/tool-ns/tool/tool-icon";
import {FilterService} from "../shared/filter-ns/filter-ns.service";
import { StockIndiceService } from './stockindice.service';
import {StockIndice} from "./stockindice";
import {IndiceTypeService} from "../indice-type/indicetype.service";

@Component({
  templateUrl: '../utils/crud/list/list.component.html',
  styleUrls: ['./stockindice-list.component.css']
})

export class StockIndiceListComponent {
  public title: string = 'Índices de mercado';
  public tableTitle: string = '';
  public actions2: any[] = [];
  public items: Object[];

  cols: TableColumn[] = [
    new TableColumn({name: 'Nombre', key: 'name', proportion: 14}),
    new TableColumn({name: 'Valor actual', key: 'currentValue', proportion: 6}),
    new TableColumn({name: 'Tipo', key: 'type', proportion: 8}),
    new TableColumn({name: 'Home', key: 'createdAt', proportion: 2}),
    new TableColumn({name: 'Estado',key: 'updatedAt', proportion: 6})
  ];

  tools: ToolBase<any>[] = [
    new IconTool({
      title: 'Crear',
      icon: 'create',
      clicked: this.create.bind(this)
    }),
    new IconTool({
      title: 'Eliminar',
      icon: 'delete',
      clicked: this.confirmationDeletes.bind(this)
    })
  ];

  actions = [
    {
      html: (item) => 'Actualizar',
      click: (item) => this.update(item)
    },
    {
      html: (item) => 'Eliminar',
      click: (item) => this.confirmationDelete(item)
    }
  ];

  confirmation: Confirmation = {hiddenClose: true};
  @ViewChild('tableCmp') tableCmp;
  pager: any;
  questionFilters: QuestionFilterBase<any>[] = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private stockindiceService: StockIndiceService,
    private indicetypeService : IndiceTypeService,
    private statusService : StatusService,
    private tableService: TableService,
    private filterService: FilterService,
    private notificationService: NotificationService,
  ){}

  ngOnInit(){
    this.tableService.setSort(this.cols[0].key);
    this.filter();
    this.list(this.tableService.pager.pageIndex);
  }

  filter(){
    //Componente para filtrar de acuerdo al nombre del indice
    let questionFilterName =
      new QuestionFilterTextbox({
        key: 'name',
        label: 'Nombre',
        value: this.filterService.filter['name']!=undefined? this.filterService.filter['name']: '',
        optionsKey: 'name',
        proportion: 2
      });

    let questionFilterType =
      new QuestionFilterTextbox({
        key: 'itype',
        label: 'Tipo',
        value: this.filterService.filter['itype']!=undefined? this.filterService.filter['itype']: '',
        optionsKey: 'itype',
        proportion: 2
      });

    this.questionFilters = [
      questionFilterName,
      questionFilterType
    ];
  }

  list(page?: number){

    //Fixing sort
    if(this.tableService.sort == 'createdAt')
      this.tableService.sort = 'showMainHome';
    else if(this.tableService.sort == '-createdAt')
      this.tableService.sort = '-showMainHome';
    else if(this.tableService.sort == 'updatedAt')
      this.tableService.sort = 'status';
    else if(this.tableService.sort == '-updatedAt')
      this.tableService.sort = '-status';

    this.activatedRoute.parent.parent.params.subscribe(param => {
      if(this.filterService.filter['indicetype'] == undefined) this.filterService.filter['indicetype'] = param['indicetypeID'];

    this.stockindiceService.getAll(this.stockindiceService.buildRequestOptionsFinder(this.tableService.sort,
    undefined, this.filterService.filter, {pageIndex: page, pageSize: this.tableService.pager.pageSize})).subscribe(
      response => {
        this.items = response['result'];
        //Por cada indice se busca su estado
        for (let item of this.items) {
          this.statusService.getById(item['status']['id']).subscribe(status => {
            item['updatedAt'] = status.description;
          });
          item['createdAt'] = item['showMainHome'] ? 'Si' : 'No';
        }
        this.pager = response['pager'];
        this.tableService.pager.pageIndex = page;
        this.tableCmp.deselectAll();
        this.filterService.filter = {};
      }, (err) => {
        this.notificationService.error('Error del servidor');
      }
    );
    }, (err) => { this.notificationService.error('Hubo un problema obteniendo los datos de la URL'); });
  }

  create(){
    this.router.navigate(['./create'], {relativeTo: this.activatedRoute});
  }

  read(item: StockIndice){
    this.router.navigate(['./' + item.id], {relativeTo: this.activatedRoute});
  }

  confirmationDeletes(){
    let ids: number[] = this.tableService.getSelectKeys();
    if(ids.length > 0)
      this.confirmation = {
        title : 'Confirmacion de eliminacion',
        message: "¿Está seguro que desea eliminar los índices seleccionados?",
        yesClicked: this.deletes.bind(this, ids),
        hiddenClose: false
      };
  }

  update(item: StockIndice){
    this.router.navigate(["./" + item.id + '/update'], {relativeTo: this.activatedRoute});
  }

  confirmationDelete(item){
    this.confirmation = {
      title : "Confirmacion de eliminación",
      message: "¿Está seguro que desea eliminar el índice seleccionado?",
      yesClicked: this.delete.bind(this, item),
      hiddenClose: false
    };
  }

  deletes(this, ids: number[]){
    if(!this.stockindiceService.checkRoles(['stockindice_manager'])) {
      this.notificationService.error('Usted no cuenta con acceso a esta función. Contacte al administrador');
      return;
    }
    for(let id of ids){
      this.stockindiceService.getById(id).subscribe(stockindice => {
        let indice = new StockIndice(stockindice);
        indice.status.id = 2;
        this.stockindiceService.update(indice).subscribe(any => {
          this.tableService.setSort(this.cols[0].key);
          this.filter();
          this.list(this.tableService.pager.pageIndex);
        }, (err) => {this.notificationService.error(err); });
      }, (err) => { this.notificationService.error(err); });
    }
  }

  delete(this, item: StockIndice){
    if(!this.stockindiceService.checkRoles(['stockindice_manager'])) {
      this.notificationService.error('Usted no cuenta con acceso a esta función. Contacte al administrador');
      return;
    }
    this.stockindiceService.getById(item.id).subscribe(stockindice => {
    let indice = new StockIndice(stockindice);
    indice.status.id = 2;
    this.stockindiceService.update(indice).subscribe(any => {
      this.tableService.setSort(this.cols[0].key);
      this.filter();
      this.list(this.tableService.pager.pageIndex);
    }, (err) => {this.notificationService.error(err); });
  }, (err) => { this.notificationService.error(err); });
  }
}
