<table *ngIf="items?.length > 0" class="table table-menu table-pointer">
    <thead>
        <tr class="table-row table-row-head row">
            <div class="container-checkbox row">
                <label class="checkbox checkbox-header">
                    <input type="checkbox" [checked]="items.length == tableService.getSelectsSize()" (change)="selectAllToogle($event)">
                    <span></span>
                </label>
            </div>
            <div class="container-data row">
              <th *ngFor="let col of cols"
                    class="data-title col-{{col.proportion}}"
                    [ngClass]="{ 'selected': tableService.sort==col.key || tableService.sort=='-'+col.key}">
                    <div class="sort sort-up"
                        [ngClass]="{ 'selected': tableService.sort==col.key }"
                        (click)="sortAsc(col.key)">
                    </div>
                    {{col.name}}
                    <div class="sort sort-down"
                        [ngClass]="{ 'selected': tableService.sort=='-'+col.key }"
                        (click)="sortDesc(col.key)">
                    </div>
                </th>
            </div>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let item of items"
            id="{{item.id}}"
            class="table-row table-row-body row"
            [ngClass]="{'selected': tableService.selects[item.id]!=undefined}">
            <div class="container-checkbox row">
                <label class="checkbox">
                    <input type="checkbox" [checked]="tableService.selects[item.id]!=undefined" (change)="selectToogle(item)">
                    <span></span>
                </label>
            </div>
            <!--<div class="container-data row" (contextmenu)="onContextMenu($event, item); selectOne(item)" (click)="onRead(item)">-->
            <div class="container-data row" (click)="onRead(item)">
                <td *ngFor="let col of cols"
                    class="data-content col-{{col.proportion}}">
                    {{this.tableService.getItemValue(item, col.key)}}
                </td>
            </div>
            <div class="container-actions row">
                <div *ngFor="let action of actions2" [ngSwitch]="action.controlType">
                    <bt-icon class="button"
                        *ngSwitchCase="'buttonIcon'"
                        [title]="action.title"
                        [type]="action.type"
                        [icon]="action.icon"
                        (clicked)="action.clicked($event, item)">
                    </bt-icon>
                </div>
                <bt-icon class="action"
                    *ngIf="actions!=undefined"
                    [title]="'menu'"
                    [type]="'buttom'"
                    [icon]="'menu-context'"
                    (click)="onContextMenu2($event, item)">
                </bt-icon>
            </div>
        </tr>
    </tbody>
</table>

<div *ngIf="items == undefined" class="table-empty">
    Cargando...
</div>

<div *ngIf="items?.length === 0" class="table-empty">
  No se encontraron registros existentes
</div>

<context-menu style="pointer-events:all; background: red;">
  <ng-template *ngFor="let action of actions" contextMenuItem let-item (execute)="action.click($event.item)">
  {{ action.html(item) }}
  </ng-template>
</context-menu>
