import {Injectable} from "@angular/core";
import {BaseService} from "../shared/services/base.service";
import {Observable} from "rxjs/Rx";
import {Role} from "./role";
import {HttpClient} from "@angular/common/http";
import {catchError} from "rxjs/operators";


@Injectable()
export class RoleService extends BaseService{

  BASE_URL: string = this.HOST +'/roles';

  constructor(
    private http: HttpClient){
    super();
  }

  checkRole(rolesList: string[]) : boolean{
    let roles = <any[]> JSON.parse(sessionStorage.getItem('roles'));
    if(roles != undefined){
      rolesList.forEach( (role) => {
        if(roles.indexOf(role) != -1)
          return true;
      });
    }
    return false;
  }

  getAll(params : any = '{ }'): Observable<Object> {
    return this.http.get(this.BASE_URL, {
      params: params,
      headers: this.createAuthorizationHeader()
    }).pipe(
      catchError(this.handleError)
    );
  }

  getByUser(params : any = '{ }'): Observable<Object> {
    return this.http.get(this.HOST + '/user/roles', {
      params: params,
      headers: this.createAuthorizationHeader()
    }).pipe(
      catchError(this.handleError)
    );
  }

  getById(id: number) : Observable<Role>{
    return this.http.get(this.BASE_URL + '/' + id,
      { headers: this.createAuthorizationHeader() }).map(this.extractData).pipe(
      catchError(this.handleError)
    );
  }

  create(Role : Role) : Observable<Role>{
    return this.http.post(this.BASE_URL, Role, { headers: this.createAuthorizationHeader() }).map(this.extractData).pipe(
      catchError(this.handleError)
    );
  }

  update(Role : Role) : Observable<Role>{
    return this.http.put(this.BASE_URL + '/' + Role.id, Role,
      { headers: this.createAuthorizationHeader() }).map(this.extractData).pipe(
      catchError(this.handleError)
    );
  }
}
