import {Injectable} from "@angular/core";
import {BaseService} from "../shared/services/base.service";
import {Observable} from "rxjs/Rx";
import {HttpClient} from "@angular/common/http";
import {catchError} from "rxjs/operators";
import {SeasonHistory} from "./season-history";


@Injectable()
export class SeasonHistoryService extends BaseService{

  BASE_URL: string = this.HOST +'/seasonhistories';

  constructor(
    private http: HttpClient){
    super();
  }

  getAll(params : any = '{ }'): Observable<Object> {
    return this.http.get(this.BASE_URL, {
      params: params,
      headers: this.createAuthorizationHeader()
    }).pipe(
      catchError(this.handleError)
    );
  }

  getById(id: number) : Observable<SeasonHistory>{
    return this.http.get(this.BASE_URL + '/' + id,
      { headers: this.createAuthorizationHeader() }).map(this.extractData).pipe(
      catchError(this.handleError)
    );
  }
}
