export class Global {

    public static ONLY_NUMBER_PATTERN: string = '^\\d+$';
    public static DECIMAL_NUMBER_PATTERN: string = '/^\d*(?:[.,]\d{1,2})?$/';
    public static PHONE_PATTERN : string = '/^(\([0-9]{3}\)\s*|[0-9]{3}\-)[0-9]{3}-[0-9]{4}$/';
    public static EMAIL_PATTERN: string = '^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$';


    public static isEmpty(obj: Object) : boolean {
        return Object.keys(obj).length === 0 && obj.constructor === Object;
    }
}
