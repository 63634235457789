import {Component, ViewChild} from "@angular/core";
import {TableColumn} from "../shared/table-ns/table-ns-column";
import {ToolBase} from "../shared/tool-ns/tool/tool-base";
import {Confirmation} from "../shared/confirmation-ns/confirmation-ns.service";
import {QuestionFilterBase} from "../shared/filter-ns/question-filter/question-filter-base";
import {Router, ActivatedRoute} from "@angular/router";
import {TableService} from "../shared/table-ns/table-ns.service";
import {NotificationService} from "../shared/notification/notification.service";
import {StatusService} from "../status/status.service";
import {QuestionFilterTextbox} from "../shared/filter-ns/question-filter/question-filter-textbox";
import {IconTool} from "../shared/tool-ns/tool/tool-icon";
import {FilterService} from "../shared/filter-ns/filter-ns.service";
import {ProviderService} from "./provider.service";
import {Provider} from "./provider";

@Component({
  templateUrl: '../utils/crud/list/list.component.html',
  styleUrls: ['./provider-list.component.css']
})

export class ProviderListComponent {
  public title: string = 'Proveedores';
  public tableTitle: string = '';
  public actions2: any[] = [];
  public items: Object[];

  //Se utiliza el campo updatedAt para mostrar el nombre del estado en el que se encuentra el tipo de indice
  //Se utiliza el campo createdAt para mostrar si se muestra o no en la aplicacion movil
  cols: TableColumn[] = [
    new TableColumn({name: 'Nombre', key: 'name', proportion: 8}),
    new TableColumn({name: 'Tipo', key: 'type', proportion: 14}),
    new TableColumn({name: 'Estado',key: 'updatedAt', proportion: 6})
  ];

  tools: ToolBase<any>[] = [
    new IconTool({
      title: 'Crear',
      icon: 'create',
      clicked: this.create.bind(this)
    }),
    new IconTool({
      title: 'Eliminar',
      icon: 'delete',
      clicked: this.confirmationDeletes.bind(this)
    })
  ];

  actions = [
    {
      html: (item) => 'Actualizar',
      click: (item) => this.update(item)
    },
    {
      html: (item) => 'Eliminar',
      click: (item) => this.confirmationDelete(item)
    },{
      html: (item) => 'Noticias',
      click: (item) => this.post(item)
    },
  ];

  confirmation: Confirmation = {hiddenClose: true};
  @ViewChild('tableCmp') tableCmp;
  pager: any;
  questionFilters: QuestionFilterBase<any>[] = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private providerService: ProviderService,
    private statusService : StatusService,
    private tableService: TableService,
    private filterService: FilterService,
    private notificationService: NotificationService,
  ){}

  ngOnInit(){
    this.tableService.setSort(this.cols[0].key);
    this.filter();
    this.list(this.tableService.pager.pageIndex);
  }

  filter(){
    //Componente para filtrar de acuerdo al nombre del proveedor de noticias
    let questionFilterName =
      new QuestionFilterTextbox({
        key: 'name',
        label: 'Nombre',
        value: this.filterService.filter['name']!=undefined? this.filterService.filter['name']: '',
        optionsKey: 'name',
        proportion: 2
      });

    let questionFilterType =
      new QuestionFilterTextbox({
        key: 'providertype',
        label: 'Tipo',
        value: this.filterService.filter['providertype']!=undefined? this.filterService.filter['providertype']: '',
        optionsKey: 'providertype',
        proportion: 2
      });

    this.questionFilters = [
      questionFilterName,
      questionFilterType
    ];
  }

  list(page?: number){

    //Fixing sort
    if(this.tableService.sort == 'updatedAt')
      this.tableService.sort = 'status';
    if(this.tableService.sort == '-updatedAt')
      this.tableService.sort = '-status';

    this.providerService.getAll(this.providerService.buildRequestOptionsFinder(this.tableService.sort,
    undefined, this.filterService.filter, {pageIndex: page, pageSize: this.tableService.pager.pageSize})).subscribe(
      response => {
        this.items = response['result'];
        //Por cada proveedor se busca su estado
        for (let item of this.items) {
          this.statusService.getById(item['status']['id']).subscribe(status => {
            item['updatedAt'] = status.description;
          });
        }
        this.pager = response['pager'];
        this.tableService.pager.pageIndex = page;
        this.tableCmp.deselectAll();
        this.filterService.filter = {};
      }, (err) => {
        this.notificationService.error('Error del servidor');
      }
    );
  }

  create(){
    this.router.navigate(['./create'], {relativeTo: this.activatedRoute});
  }

  read(item: Provider){
    this.router.navigate(['./' + item.id], {relativeTo: this.activatedRoute});
  }

  confirmationDeletes(){
    let ids: number[] = this.tableService.getSelectKeys();
    if(ids.length > 0)
      this.confirmation = {
        title : 'Confirmacion de eliminacion',
        message: "¿Está seguro que desea eliminar los proveedores seleccionados?",
        yesClicked: this.deletes.bind(this, ids),
        hiddenClose: false
      };
  }

  update(item: Provider){
    this.router.navigate(["./" + item.id + '/update'], {relativeTo: this.activatedRoute});
  }

  post(item: Provider){
    this.router.navigate(['./' + item.id + '/posts'], {relativeTo: this.activatedRoute});
  }

  confirmationDelete(item){
    this.confirmation = {
      title : "Confirmacion de eliminación",
      message: "¿Está seguro que desea eliminar el proveedor seleccionado?",
      yesClicked: this.delete.bind(this, item),
      hiddenClose: false
    };
  }

  deletes(this, ids: number[]){
    if(!this.providerService.checkRoles(['provider_manager'])) {
      this.notificationService.error('Usted no cuenta con acceso a esta función. Contacte al administrador');
      return;
    }
    for(let id of ids){
      this.providerService.getById(id).subscribe(provider => {
        let prov = new Provider(provider);
        prov.status.id = 2;
        this.providerService.update(prov).subscribe(any => {
          this.tableService.setSort(this.cols[0].key);
          this.filter();
          this.list(this.tableService.pager.pageIndex);
        }, (err) => {this.notificationService.error(err); });
      }, (err) => { this.notificationService.error(err); });
    }
  }

  delete(this, item: Provider){
    if(!this.providerService.checkRoles(['provider_manager'])) {
      this.notificationService.error('Usted no cuenta con acceso a esta función. Contacte al administrador');
      return;
    }
    this.providerService.getById(item.id).subscribe(provider => {
      let prov = new Provider(provider);
      prov.status.id = 2;
      this.providerService.update(prov).subscribe(any => {
        this.tableService.setSort(this.cols[0].key);
        this.filter();
        this.list(this.tableService.pager.pageIndex);
      }, (err) => {this.notificationService.error(err); });
    }, (err) => { this.notificationService.error(err); });
  }
}
