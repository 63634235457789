<br><br>
<div id="side-nav">
	<header>
		<img id="logo" src="/assets/images/logo.png" routerLink="/home"/>
	</header>

	<nav>
		<side-nav-item [menuItems]="menuItems"></side-nav-item>
	</nav>
</div>
