import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import {homeRoutes} from "./home/home.routes";
import {authRoutes} from "./utils/auth/auth.routes";

const appRoutes: Routes = [
  {
    path: '',
    component: AppComponent,
    children: [
      ...authRoutes,
      ...homeRoutes
    ]
  }
];

@NgModule({
  imports: [ RouterModule.forRoot(appRoutes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
