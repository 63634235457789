<h3>{{title}}</h3>
<br>
<div class="container">
  <form [formGroup]="seasonForm" name="seasonForm" (ngSubmit)="action($event)" novalidate>
    <div class="form-group">
      <label for="name">Nombre</label>
      <input type="text" maxlength="125" formControlName="name" class="form-control" placeholder="Ingrese nombre de la temporada" required><br>
      <div *ngIf="!seasonForm.controls.name.valid" class="help-block">Nombre de la temporada es inváĺida</div>
    </div>
    <div class="form-group">
      <label for="startDate">Fecha de inicio</label>
      <input type="date" min="2010-01-01" formControlName="startDate" class="form-control" required><br>
      <div *ngIf="!seasonForm.controls.startDate.valid" class="help-block">Fecha de inicio es inválida</div>
    </div>
    <div class="form-group">
      <label for="endDate">Fecha de fin</label>
      <input type="date" min="{{seasonForm.controls.startDate.value}}" formControlName="endDate" class="form-control" required><br>
      <div *ngIf="!seasonForm.controls.endDate.valid || seasonForm.controls.endDate.value < seasonForm.controls.startDate.value" class="help-block">Fecha de fin es inválida</div>
    </div>
    <div class="form-group">
      <label for="status">Estado</label>
      <select  [(ngModel)]="selectedStatus" [ngModelOptions]="{standalone: true}" required>
        <option *ngFor="let stat of status" [ngValue]="stat">{{stat.description}}</option>
      </select>
    </div>
    <br><br>
    <div class="form-group">
      <bt-text
        [title]="'Aceptar'"
        [type]="'submit'"
        [text]="'Guardar'"
        (clicked)="'null'">
      </bt-text>
    </div>
  </form>
</div>
