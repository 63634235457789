import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationComponent } from './notification/notification.component';

import { TableBasicComponent } from './table-ns/table-ns-basic/table-ns-basic.component';
import { TableMenuComponent } from './table-ns/table-ns-menu/table-ns-menu.component';
import { PaginationComponent } from './pagination/pagination.component';
import { PagerService } from './pagination/pagination.service';

import { ToolComponent } from './tool-ns/tool-ns.component';

import { ConfirmationComponent } from './confirmation-ns/confirmation-ns.component';

import { FilterComponent } from './filter-ns/filter-ns.component';

import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule }  from '@angular/forms';

import { ButtonIconComponent } from './button/button-icon/button-icon.component';
import { ButtonIconTextComponent } from './button/button-icon-text/button-icon-text.component';
import { ButtonTextComponent } from './button/button-text/button-text.component';
import { ButtonToogleComponent } from './button/button-toogle/button-toogle.component';
import { ButtonMediaComponent } from './button/button-media/button-media.component';

import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { DynamicFormQuestionComponent } from './dynamic-form/dynamic-form-question.component';
import { DropdownComponent } from './dynamic-form/dropdown-ns/dropdown-ns.component';
import { DropdownMultiComponent } from './dynamic-form/dropdown-ns/dropdown-multi-ns.component';
import { ClickOutsideDirective } from './dynamic-form/dropdown-ns/clickOutside';
import { MyDatePickerModule } from './dynamic-form/my-date-picker/my-date-picker.module';


import { DynamicShowComponent } from './dynamic-show/dynamic-show.component';
import { DynamicShowSmallComponent } from './dynamic-show/dynamic-show-small/dynamic-show-small.component';
import { TextboxClickComponent } from './dynamic-show/textbox-click/textbox-click.component';
import {FilterModComponent} from "./filter-ns-mod/filter-ns-mod.component";
import {OverlayModule} from "@angular/cdk/overlay";
import {ContextMenuModule} from "ngx-contextmenu/lib/ngx-contextmenu";
import {SimpleNotificationsModule} from "angular2-notifications";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
	FormsModule,
    OverlayModule,
	SimpleNotificationsModule.forRoot(),
    ContextMenuModule.forRoot(),
	MyDatePickerModule
  ],
  declarations: [
  	TableBasicComponent,
	TableMenuComponent,
	PaginationComponent,
	ToolComponent,
	ConfirmationComponent,
	FilterComponent,
    FilterModComponent,
  	NotificationComponent,
  	ButtonIconComponent,
	ButtonIconTextComponent,
	ButtonTextComponent,
	ButtonToogleComponent,
	ButtonMediaComponent,

	DynamicFormComponent,
	DynamicFormQuestionComponent,
	DropdownComponent,
	DropdownMultiComponent,
	ClickOutsideDirective,

	DynamicShowComponent,
	DynamicShowSmallComponent,
	TextboxClickComponent

	],
  exports: [
	  	TableBasicComponent,
		TableMenuComponent,
		PaginationComponent,
		ToolComponent,
		ConfirmationComponent,
		FilterComponent,
    	FilterModComponent,
		NotificationComponent,
		ButtonIconComponent,
		ButtonIconTextComponent,
		ButtonTextComponent,
		ButtonToogleComponent,
		ButtonMediaComponent,
		DynamicFormComponent,
		DynamicFormQuestionComponent,
		DropdownComponent,
		DropdownMultiComponent,
		ClickOutsideDirective,
		DynamicShowComponent,
		DynamicShowSmallComponent,
		TextboxClickComponent,
	],
	providers: [
		PagerService
	]
})
export class SharedModule { }
