import {Component, ViewChild} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {BaseService} from "../shared/services/base.service";
import {NotificationService} from "../shared/notification/notification.service";
import { Location } from '@angular/common';
import {Group} from "./group";
import {GroupService} from "./group.service";
import {ActivatedRoute} from "@angular/router";
import {TableColumn} from "../shared/table-ns/table-ns-column";
import {TableService} from "../shared/table-ns/table-ns.service";
import {Role} from "../role/role";
import {RoleService} from "../role/role.service";
import {User} from "../user/user";
import {QuestionFilterTextbox} from "../shared/filter-ns/question-filter/question-filter-textbox";
import {FilterService} from "../shared/filter-ns/filter-ns.service";
import {QuestionFilterBase} from "../shared/filter-ns/question-filter/question-filter-base";

@Component({
  templateUrl: './group.component.html',
  styleUrls: ['group.component.css']
})

export class GroupUpdateComponent{

  public title: string = 'Actualizar grupo';
  public group: Group;
  public selectedStatus : boolean;
  public tableTitle: string = '';
  public actions2: any[] = [];
  public actions: any[] = [];
  public items: Object[];
  @ViewChild('tableCmp') tableCmp;
  pager: any;
  readonly = true;
  questionFilters: QuestionFilterBase<any>[] = [];

  cols: TableColumn[] = [
    new TableColumn({name: 'Identificador', key: 'id', proportion: 6}),
    new TableColumn({name: 'Descripción', key: 'description', proportion: 14})
  ];

  groupForm = this.fb.group({
    id: ['', [Validators.required, Validators.maxLength(125)]],
    description: ['', [Validators.maxLength(190)]]
  });

  constructor(
    private fb: FormBuilder,
    private location: Location,
    private tableService: TableService,
    private groupService : GroupService,
    private filterService : FilterService,
    private roleService : RoleService,
    private activatedRouteService : ActivatedRoute,
    private notificationService : NotificationService
  ){}

  ngOnInit(){

    //Numero de roles máximos por pagina del listado
    this.tableService.pager.pageSize = 15;

    this.tableService.setSort(this.cols[0].key);
    this.filter();
    this.list(this.tableService.pager.pageIndex);

  }

  read($event){

  }

  filter(){
    let questionFilterDescription =
      new QuestionFilterTextbox({
        key: 'description',
        label: 'Descripción',
        value: this.filterService.filter['description']!=undefined? this.filterService.filter['description']: '',
        optionsKey: 'description',
        proportion: 2
      });
    this.questionFilters = [
      questionFilterDescription
    ];
  }

  list(page?: number) {

    this.filterService.filter['type'] = "external";

    this.roleService.getAll(this.groupService.buildRequestOptionsFinder(this.tableService.sort, undefined, this.filterService.filter, {pageIndex: page, pageSize: this.tableService.pager.pageSize})).subscribe(response => {
      this.items = response['result'];
      this.pager = response['result']['pager'];
      this.tableService.pager.pageIndex = page;
      //this.tableCmp.deselectAll();


      this.activatedRouteService.parent.params.subscribe(param => {

        this.groupService.getById(param['groupID']).subscribe(result => {
          this.group = <Group> result;
          this.groupForm.controls['id'].setValue(this.group.id);
          this.groupForm.controls['description'].setValue(this.group.description);
          this.selectedStatus = this.group.active;

          for(let item of this.items){
            for(let rol of this.group.roles){
              if(item['id'] == rol.id){
                this.tableService.addSelect(item);
              }
            }
          }

        }, (err) => {
          this.notificationService.error('Hubo un problema cargando la información');
        })
      }, (err) => {
        this.notificationService.error('Hubo un problema obteniendo los datos de la URL');
      });
    }, (err) => {
      this.notificationService.error("Error al cargar la información");
    });
  }

  action($event){

    let obj = <Group> BaseService.builderObject(this.groupForm.value);
    obj['id'] = this.group.id;

    if(this.groupForm.valid) {
      obj['active'] = this.selectedStatus;
      obj['roles'] = [];

      for(let it of this.tableService.getSelectKeys()){
        let rol = new Role();
        rol.id = it;
        obj['roles'].push(rol);
      }

      this.groupService.update(<Group> obj).subscribe(response => {
        this.notificationService.sucessInsert(this.group.id);
        this.location.back();
      }, (err) => { this.notificationService.error('Error al actualizar el grupo') });
    }
    else{
      this.notificationService.error('Verifique los datos del formulario');
    }
  }
}
