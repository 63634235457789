import { Injectable } from '@angular/core';

interface Pager {
    totalEntitiesPerPage: number;
    pages: number;
}

@Injectable()
export class TableService{
	selects: {} = {};	//use for items selected
    pager: {pageIndex: number, pageSize: number} = {pageIndex: 0, pageSize: 20};
   	sort: string;

   	public TableService(){
   		this.pager.pageIndex = 0;
   		this.pager.pageSize = 20;
   	}

   	setSort(key){
   		if(this.sort === undefined)
			this.sort = key;
   	}

    refreshPageIndexAfterRemove(itemsRemoved: number, pager: Pager) {
        if(	pager.totalEntitiesPerPage === itemsRemoved &&
        	this.pager.pageIndex > 0 &&
        	this.pager.pageIndex === pager.pages-1){
			return --this.pager.pageIndex;
		}
        return this.pager.pageIndex;
	}

	getItemValue(item, columnKey){
		let keySplits = columnKey.split('.');
      	let innerObj = item;

		for(var i = 0; i < keySplits.length-1; ++i){
			if(innerObj[keySplits[i]] == undefined){
				return "";
			}
			innerObj = innerObj[keySplits[i]];
		}

		return innerObj[keySplits[keySplits.length-1]];
	}

	getSelectKeys(){
		let keys: any[] = [];
		for(var key in this.selects)
			keys.push(this.selects[key].id);
		return keys;
	}

	getSelectsSize(){
		return Object.keys(this.selects).length;
	}

	addSelect(item){
		this.selects[item.id] = item;
	}

	addSelects(items){
		items.forEach(item => this.addSelect(item));
	}

	deleteSelect(key){
		delete this.selects[key];
	}

	deleteSelects(keys: any[]){
		keys.forEach(key => this.deleteSelect(key));
	}

	toogleSelect(item){
		if(item.id in this.selects)
			this.deleteSelect(item.id);
		else
			this.addSelect(item);
	}

	emptySelects(){
		this.selects = {};
	}
}
