import {Component} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {BaseService} from "../shared/services/base.service";
import {Status} from "../status/status";
import {StatusService} from "../status/status.service";
import {NotificationService} from "../shared/notification/notification.service";
import { Location } from '@angular/common';
import {Season} from "./season";
import {SeasonService} from "./season.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  templateUrl: './season.component.html',
  styleUrls: ['season.component.css']
})

export class SeasonCreateComponent{

  public title: string = 'Crear temporada';
  public season: Season;
  public status : Status[];
  public selectedStatus : Status;

  seasonForm = this.fb.group({
    name: ['', [Validators.required, Validators.maxLength(125)]],
    startDate: ['', [Validators.required]],
    endDate: ['', [Validators.required]]
  });

  constructor(
    private fb: FormBuilder,
    private location: Location,
    private statusService : StatusService,
    private seasonService : SeasonService,
    private activatedRouteService : ActivatedRoute,
    private notificationService : NotificationService
  ){}

  ngOnInit(){
    this.statusService.getAll({type: 'season'}).subscribe(response => {
      this.status = <Status[]> response['result'];
      this.selectedStatus = this.status[0];
    }, (err) => { this.notificationService.error('Hubo un problema cargando la información'); });
  }

  formatDateOut(format: string) : string{
    let split = format.split("-");
    let date = new Date(Number.parseInt(split[0]),Number.parseInt(split[1])-1,Number.parseInt(split[2])); // had to remove the colon (:) after the T in order to make it work
    let day = date.getDate().toString();
    let hour = date.getHours().toString();
    let minutes = date.getMinutes().toString();
    let seconds = date.getSeconds().toString();
    let timezone = date.toString().match(/([-\+][0-9]+)\s/)[1];
    let monthIndex = date.getMonth();
    let year = date.getFullYear();
    let month = (monthIndex+1).toString();
    if(month.length == 1) month = "0"+month;
    if(day.length == 1) day = "0"+day;
    if(hour.length == 1) hour = "0"+hour;
    if(minutes.length == 1) minutes = "0"+minutes;
    if(seconds.length == 1) seconds = "0"+seconds;
    return year+"-"+month+"-"+day+"T"+hour+":"+minutes+":"+seconds+timezone;
  }

  action($event){
    this.season = <Season> BaseService.builderObject(this.seasonForm.value);

    if(this.seasonForm.valid && this.seasonForm.controls.endDate.value >= this.seasonForm.controls.startDate.value) {

      let obj = <Object> this.season;
      obj['startDate'] = this.formatDateOut(this.season.startDate.toString());
      obj['endDate'] = this.formatDateOut(this.season.endDate.toString());
      obj['status'] = {'id' : this.selectedStatus.id };

      this.seasonService.create(<Season> obj).subscribe(response => {
        this.notificationService.sucessInsert(this.season.name);
        this.location.back();
      }, (err) => { 
        let error = err.error.developerMessage;
        if(error != undefined){
          if(error === 'Violation constrain: Cannot have more than one active season')
            this.notificationService.error("Solo puede existir una temporada activa");
          else if(error.toString().includes('Duplicate entry'))
            this.notificationService.error("Nombre de la temporada no puede repetirse");
        }
        else
          this.notificationService.error("Error del servidor");
         });
      }
    else{
      this.notificationService.error('Verifique los datos del formulario');
    }
  }
}
