
<div class="dialog-overlay" (click)="closeDialog()"></div>
<div class="dialog-container">
	<bt-icon
		[title]="'close'"
		[type]="'button'"
		[icon]="'close'"
		(clicked)="closeDialog()">
	</bt-icon>
	<div class="content">
		<p>{{title}}</p>
		<form [formGroup]="forgotForm" novalidate>
			<input type="text" formControlName="email" placeholder="Ingrese su email">
			<div *ngIf="submitted && !forgotForm.controls['email'].valid" class="help-block">Email is required</div>
		</form>
	</div>
	<div class="btn-container">
		<bt-text
			[title]="'cancel'"
			[type]="'button'"
			[text]="'cancelar'"
			(clicked)="closeDialog()">
		</bt-text>
		<bt-text
			[title]="'send'"
			[type]="'button'"
			[text]="'enviar'"
			(clicked)="sendMail()">
		</bt-text>
	</div>
</div>
