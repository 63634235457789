import { ModuleWithProviders } from "@angular/core";
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home.component';
import {indicetypeRoutes} from "../indice-type/indicetype.routes";
import {providerRoutes} from "../provider/provider.routes";
import {instrumentRoutes} from "../instrument/instrument.routes";
import {userRoutes} from "../user/user.routes";
import {clientRoutes} from "../client/client.routes";
import {groupRoutes} from "../group/group.routes";
import {bannerRoutes} from "../banner/banner.routes";
import {seasonRoutes} from "../season/season.routes";
import {configRoutes} from "../config/config.routes";
import { eventRoutes } from "../event/event.routes";

export const homeRoutes: Routes = [
    {
        path: 'home',
        component: HomeComponent,
        children: [
          ...indicetypeRoutes,
          ...providerRoutes,
          ...instrumentRoutes,
          ...clientRoutes,
          ...userRoutes,
          ...groupRoutes,
          ...bannerRoutes,
          ...configRoutes,
          ...seasonRoutes,
          ...eventRoutes
        ],
        data: {
            breadcrumb: "Home",
            icon: "home"
        }
    }
];

export const homeRouting : ModuleWithProviders =  RouterModule.forChild(homeRoutes);
