import { Component } from '@angular/core';
import { MenuService } from './menu.service';

interface menuItem {
	title: string;
	routerLink?: string;
	selected?: boolean;
	expanded?: boolean;
	children?: menuItem[];
}

@Component({
	selector: 'side-nav',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.css'],
	providers: [MenuService],
})
export class MenuComponent {
	menuItems: menuItem[] = [
    {
      title: 'Banner',
      routerLink: './banner',
      selected: false
    },
		{
			title: 'Índices de Mercado',
			routerLink: './indicetypes'
		},
    {
      title: 'Noticias',
      routerLink: './providers'
    },
    {
      title: 'Instrumentos',
      routerLink: './instruments'
    },
    {
      title: 'Temporadas',
      routerLink: './seasons'
    },
    {
			title: 'Clientes',
      routerLink: './clients'
		},
    {
      title: 'Administración',
      children: [
        {
          title: 'Usuarios',
          routerLink: './users'
        },
        {
          title: 'Grupos',
          routerLink: './groups'
        },
        {
          title: 'Configuración',
          routerLink: './config'
        },
        {
          title: 'Eventos',
          routerLink: './event'
        }
      ]
    }
	];
}
