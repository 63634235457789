import {Component} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {Status} from "../status/status";
import {StatusService} from "../status/status.service";
import {NotificationService} from "../shared/notification/notification.service";
import { Location } from '@angular/common';
import {ActivatedRoute} from "@angular/router";
import {Provider} from "./provider";
import {ProviderService} from "./provider.service";

@Component({
  templateUrl: './provider.component.html',
  styleUrls: ['provider.component.css']
})

export class ProviderUpdateComponent{

  public title: string = 'Actualizar proveedor';
  public provider: Provider;
  public status : Status[];
  public selectedStatus : Status;

  providerForm = this.fb.group({
    name: ['', [Validators.required, Validators.maxLength(125)]],
    url: ['', [Validators.pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?"))]],
    type: ['', [Validators.maxLength(125)]]
  });

  constructor(
    private fb: FormBuilder,
    private location: Location,
    private statusService : StatusService,
    private providerService : ProviderService,
    private activatedRouteService : ActivatedRoute,
    private notificationService : NotificationService
  ){

  }

  ngOnInit(){
    this.statusService.getAll({type: 'provider'}).subscribe(response => {
      this.status = <Status[]> response['result'];

      this.activatedRouteService.parent.params.subscribe(param => {

        this.providerService.getById(param['providerID']).subscribe(result => {
          this.provider = new Provider(result);
          this.providerForm.controls['name'].setValue(this.provider.name);
          this.providerForm.controls['type'].setValue(this.provider.type);
          this.providerForm.controls['url'].setValue(this.provider.url);

          let id = result.status.id;
          for(let stat of this.status){
            if(stat.id == id){
              this.selectedStatus = stat;
            }
          }

        }, (err) => {
          this.notificationService.error('Hubo un problema cargando la información');
        })
      }, (err) => {
        this.notificationService.error('Hubo un problema obteniendo los datos de la URL');
      });
    }, (err) => { this.notificationService.error('Hubo un problema cargando la información'); });
  }

  action($event){
    if(this.providerForm.valid) {
      this.provider.name = this.providerForm.controls['name'].value;
      this.provider.type = this.providerForm.controls['type'].value;
      this.provider.url = this.providerForm.controls['url'].value;

      let obj = <Object> this.provider;
      obj['status'] = {'id' : this.selectedStatus.id };
      this.providerService.update(<Provider> obj).subscribe(response => {
        this.notificationService.sucessUpdate(this.provider.name);
        this.location.back();
      }, (err) => { this.notificationService.error('Error al actualizar el proveedor') })
    }
    else{
      this.notificationService.error('Verifique los datos del formulario');
    }
  }
}

