import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {SharedModule} from "../shared/shared.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {homeRouting} from "../home/home.routes";
import {HttpClientModule} from "@angular/common/http";
import {StatusService} from "../status/status.service";
import {ProviderService} from "./provider.service";
import {ProviderComponent} from "./provider.component";
import {ProviderListComponent} from "./provider-list.component";
import {ProviderUpdateComponent} from "./provider-update.component";
import {ProviderReadComponent} from "./provider-read.component";
import {ProviderCreateComponent} from "./provider-create.component";
import { ProviderGuardService } from './provider-guard.service';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    homeRouting,
    ReactiveFormsModule,
    HttpClientModule
  ],
  declarations:[
    ProviderComponent,
    ProviderListComponent,
    ProviderCreateComponent,
    ProviderUpdateComponent,
    ProviderReadComponent
  ],
  providers:[
    ProviderService,
    StatusService,
    ProviderGuardService
  ]
})

export class ProviderModule { }
