import {Component} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {BaseService} from "../shared/services/base.service";
import {NotificationService} from "../shared/notification/notification.service";
import { Location } from '@angular/common';
import {User} from "./user";
import {UserService} from "./user.service";
import {GroupService} from "../group/group.service";

@Component({
  templateUrl: './user.component.html',
  styleUrls: ['user.component.css']
})

export class UserCreateComponent{

  public title: string = 'Crear usuario';
  public user: User;

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {
    singleSelection: false,
    text:"Seleccionar grupos",
    enableSearchFilter: true,
    enableCheckAll: false,
    classes:"myclass custom-class"};

  userForm = this.fb.group({
    name: ['', [Validators.required, Validators.maxLength(125)]],
    lastName: ['', [Validators.required, Validators.maxLength(125)]],
    email: ['', [Validators.required, Validators.maxLength(100), Validators.pattern("^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$")]],
    password: ['', [Validators.required, Validators.minLength(6), Validators.pattern("[^\\w\\d]*(([0-9]+.*[A-Za-z]+.*)|[A-Za-z]+.*([0-9]+.*))")]],
    repeatedPassword: ['', [Validators.required, Validators.minLength(6), Validators.pattern("[^\\w\\d]*(([0-9]+.*[A-Za-z]+.*)|[A-Za-z]+.*([0-9]+.*))")]]
  });

  constructor(
    private fb: FormBuilder,
    private location: Location,
    private userService : UserService,
    private groupService : GroupService,
    private notificationService : NotificationService
  ){}

  ngOnInit(){

    this.groupService.getAll({type: 'webadmin'}).subscribe(params => {
      for(let g of params['result']){
        let item = [];
        item['id'] = g.id;
        item['itemName'] = g.id;
        this.dropdownList.push(item);
      }
    }, (err) => { this.notificationService.error("Error cargando la información")});
  }

  action($event){
    if(this.userForm.valid && this.userForm.controls['password'].value == this.userForm.controls['repeatedPassword'].value) {

      let obj = BaseService.builderObject(this.userForm.value);
      obj['authUser'] = { };
      obj['authUser']['email'] = obj['email'];
      obj['authUser']['password'] = obj['password'];
      obj['authUser']['groups'] = [];
      for(let item of this.selectedItems){
        let group = {};
        group['id'] = item.id;
        obj['authUser']['groups'].push(group);
      }
      this.user = new User(obj);
      this.userService.create(this.user).subscribe(response => {
        this.notificationService.sucessInsert(this.user.name);
        this.location.back();
      }, (err) => {
        if(err == 'Error 409 Conflict') this.notificationService.error('Email ya está siendo utilizado');
        else this.notificationService.error('Error al crear el usuario'); })
    }
    else{
      this.notificationService.error('Verifique los datos del formulario');
    }
  }
}
