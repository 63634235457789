import {Component, ViewChild} from "@angular/core";
import {TableColumn} from "../shared/table-ns/table-ns-column";
import {ToolBase} from "../shared/tool-ns/tool/tool-base";
import {Confirmation} from "../shared/confirmation-ns/confirmation-ns.service";
import {QuestionFilterBase} from "../shared/filter-ns/question-filter/question-filter-base";
import {Router, ActivatedRoute} from "@angular/router";
import {TableService} from "../shared/table-ns/table-ns.service";
import {NotificationService} from "../shared/notification/notification.service";
import {StatusService} from "../status/status.service";
import {QuestionFilterTextbox} from "../shared/filter-ns/question-filter/question-filter-textbox";
import {IconTool} from "../shared/tool-ns/tool/tool-icon";
import {FilterService} from "../shared/filter-ns/filter-ns.service";
import {PostService} from "./post.service";
import {ProviderService} from "../provider/provider.service";
import {Post} from "./post";

@Component({
  templateUrl: '../utils/crud/list/list.component.html',
  styleUrls: ['./post-list.component.css']
})

export class PostListComponent {
  public title: string = 'Noticias';
  public tableTitle: string = '';
  public actions2: any[] = [];
  public items: Object[];

  cols: TableColumn[] = [
    new TableColumn({name: 'Titulo', key: 'title', proportion: 8}),
    new TableColumn({name: 'Fuente', key: 'source', proportion: 8}),
    new TableColumn({name: 'Fecha', key: 'date', proportion: 6}),
    new TableColumn({name: 'Estado',key: 'updatedAt', proportion: 6})
  ];

  tools: ToolBase<any>[] = [
    new IconTool({
      title: 'Crear',
      icon: 'create',
      clicked: this.create.bind(this)
    }),
    new IconTool({
      title: 'Eliminar',
      icon: 'delete',
      clicked: this.confirmationDeletes.bind(this)
    })
  ];

  actions = [
    {
      html: (item) => 'Actualizar',
      click: (item) => this.update(item)
    },
    {
      html: (item) => 'Eliminar',
      click: (item) => this.confirmationDelete(item)
    }
  ];

  confirmation: Confirmation = {hiddenClose: true};
  @ViewChild('tableCmp') tableCmp;
  pager: any;
  questionFilters: QuestionFilterBase<any>[] = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private postService : PostService,
    private providerService : ProviderService,
    private statusService : StatusService,
    private tableService: TableService,
    private filterService: FilterService,
    private notificationService: NotificationService,
  ){}

  ngOnInit(){
    this.tableService.setSort(this.cols[0].key);
    this.filter();
    this.list(this.tableService.pager.pageIndex);
  }

  filter(){
    //Componente para filtrar de acuerdo al título de la noticia
    let questionFilterTitle =
      new QuestionFilterTextbox({
        key: 'title',
        label: 'Título',
        value: this.filterService.filter['title']!=undefined? this.filterService.filter['title']: '',
        optionsKey: 'title',
        proportion: 2
      });

    let questionFilterSource =
      new QuestionFilterTextbox({
        key: 'source',
        label: 'Fuente',
        value: this.filterService.filter['source']!=undefined? this.filterService.filter['source']: '',
        optionsKey: 'source',
        proportion: 2
      });

    this.questionFilters = [
      questionFilterTitle,
      questionFilterSource
    ];
  }

  list(page?: number){

    //Fixing sort
    if(this.tableService.sort == 'updatedAt')
      this.tableService.sort = 'status';
    if(this.tableService.sort == '-updatedAt')
      this.tableService.sort = '-status';

    this.activatedRoute.parent.parent.params.subscribe(param => {
      if(this.filterService.filter['provider'] == undefined) this.filterService.filter['provider'] = param['providerID'];

    this.postService.getAll(this.postService.buildRequestOptionsFinder(this.tableService.sort,
    undefined, this.filterService.filter, {pageIndex: page, pageSize: this.tableService.pager.pageSize})).subscribe(
      response => {
        this.items = response['result'];
        //Por cada noticia se busca su estado
        for (let item of this.items) {
          item['date'] = this.formatDate(item['date']);
          this.statusService.getById(item['status']['id']).subscribe(status => {
            item['updatedAt'] = status.description;
          });
        }
        this.pager = response['pager'];
        this.tableService.pager.pageIndex = page;
        this.tableCmp.deselectAll();
        this.filterService.filter = {};
      }, (err) => {
        this.notificationService.error('Error del servidor');
      }
    );
    }, (err) => { this.notificationService.error('Hubo un problema obteniendo los datos de la URL'); });
  }

  create(){
    this.router.navigate(['./create'], {relativeTo: this.activatedRoute});
  }

  read(item: Post){
    this.router.navigate(['./' + item.id], {relativeTo: this.activatedRoute});
  }

  formatDate(format: string) : string{
    let date = new Date(format); // had to remove the colon (:) after the T in order to make it work
    let day = date.getDate();
    let monthIndex = date.getMonth();
    let year = date.getFullYear();
    let month = (monthIndex+1).toString();
    if(month.length == 1) month = "0"+month;
    return day+"/"+month+"/"+year;
  }

  confirmationDeletes(){
    let ids: number[] = this.tableService.getSelectKeys();
    if(ids.length > 0)
      this.confirmation = {
        title : 'Confirmacion de eliminacion',
        message: "¿Está seguro que desea eliminar las noticias seleccionadas?",
        yesClicked: this.deletes.bind(this, ids),
        hiddenClose: false
      };
  }

  update(item: Post){
    this.router.navigate(["./" + item.id + '/update'], {relativeTo: this.activatedRoute});
  }

  confirmationDelete(item){
    this.confirmation = {
      title : "Confirmacion de eliminación",
      message: "¿Está seguro que desea eliminar la noticia seleccionada?",
      yesClicked: this.delete.bind(this, item),
      hiddenClose: false
    };
  }

  deletes(this, ids: number[]){
    if(!this.postService.checkRoles(['post_manager'])) {
      this.notificationService.error('Usted no cuenta con acceso a esta función. Contacte al administrador');
      return;
    }
    for(let id of ids){
      this.postService.getById(id).subscribe(post => {
        let p = new Post(post);
        p.status.id = 2;
        this.postService.update(p).subscribe(any => {
          this.tableService.setSort(this.cols[0].key);
          this.filter();
          this.list(this.tableService.pager.pageIndex);
        }, (err) => {this.notificationService.error(err); });
      }, (err) => { this.notificationService.error(err); });
    }
  }

  delete(this, item: Post){
    if(!this.postService.checkRoles(['post_manager'])) {
      this.notificationService.error('Usted no cuenta con acceso a esta función. Contacte al administrador');
      return;
    }
    this.postService.getById(item.id).subscribe(post => {
    let p = new Post(post);
    p.status.id = 2;
    this.postService.update(p).subscribe(any => {
      this.tableService.setSort(this.cols[0].key);
      this.filter();
      this.list(this.tableService.pager.pageIndex);
    }, (err) => {this.notificationService.error(err); });
  }, (err) => { this.notificationService.error(err); });
  }
}
