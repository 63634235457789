import {Component} from '@angular/core';
import {IndiceType} from "./indicetype";
import {FormBuilder, Validators} from "@angular/forms";
import {BaseService} from "../shared/services/base.service";
import {Status} from "../status/status";
import {StatusService} from "../status/status.service";
import {NotificationService} from "../shared/notification/notification.service";
import {IndiceTypeService} from "./indicetype.service";
import { Location } from '@angular/common';

@Component({
  templateUrl: './indicetype.component.html',
    styleUrls: ['indicetype.component.css']
})

export class IndiceTypeCreateComponent{

  public title: string = 'Crear tipo de índice';
  public indiceType: IndiceType;
  public selectedShowIndiceHome : boolean = true;
  public status : Status[];
  public selectedStatus : Status;

  indiceTypeForm = this.fb.group({
    name: ['', [Validators.required, Validators.maxLength(125)]],
    description: ['', [Validators.required, Validators.maxLength(255)]]
  });

  constructor(
    private fb: FormBuilder,
    private location: Location,
    private statusService : StatusService,
    private indicetypeService : IndiceTypeService,
    private notificationService : NotificationService
  ){}

  ngOnInit(){
    this.statusService.getAll({type: 'indicetype'}).subscribe(response => {
      this.status = <Status[]> response['result'];
      this.selectedStatus = this.status[0];
    }, (err) => { this.notificationService.error('Hubo un problema cargando la información'); });
  }

  action($event){
    this.indiceType = <IndiceType> BaseService.builderObject(this.indiceTypeForm.value);

    if(this.indiceTypeForm.valid) {
      this.indiceType.showIndiceHome = this.selectedShowIndiceHome;
      let obj = <Object> this.indiceType;
      obj['status'] = {'id' : this.selectedStatus.id }
      this.indicetypeService.create(<IndiceType> obj).subscribe(response => {
        this.notificationService.sucessInsert(this.indiceType.name);
        this.location.back();
      }, (err) => { this.notificationService.error('Error al crear el tipo de índice') })
    }
    else{
      this.notificationService.error('Verifique los datos del formulario');
    }
  }
}
