<h3>{{title}}</h3>
<br>
<div class="container">
  <form [formGroup]="userForm" name="userForm" (ngSubmit)="action($event)" novalidate>
    <div class="form-group">
      <label for="name">Nombre</label>
      <input type="text" maxlength="125" formControlName="name" class="form-control" placeholder="Ingrese nombre del usuario" required><br>
      <div *ngIf="!userForm.controls.name.valid" class="help-block">Nombre del usuario es inválido</div>
    </div>
    <div class="form-group">
      <label for="lastName">Apellidos</label>
      <input type="text" maxlength="125" formControlName="lastName" class="form-control" placeholder="Ingrese apellido del usuario" required><br>
      <div *ngIf="!userForm.controls.lastName.valid" class="help-block">Apellido del usuario es inválido</div>
    </div>
    <div *ngIf="title == 'Crear usuario'" class="form-group">
      <label for="email">Email</label>
      <input type="text" maxlength="100" formControlName="email" class="form-control" placeholder="Ingrese email del usuario" required><br>
      <div *ngIf="!userForm.controls.email.valid" class="help-block">Email del usuario es inválido</div>
    </div>
    <div *ngIf="title == 'Crear usuario'" class="form-group">
      <label for="password">Contraseña</label>
      <input type="password" minlength="6" formControlName="password" class="form-control" placeholder="Ingrese contraseña del usuario" required><br>
      <div *ngIf="!userForm.controls.password.valid" class="help-block">Contraseña del usuario es inválida</div>
    </div>
    <div *ngIf="title == 'Crear usuario'" class="form-group">
      <label for="repeatedPassword">Repita contraseña</label>
      <input type="password" minlength="6" formControlName="repeatedPassword" class="form-control" placeholder="Repita contraseña del usuario" required><br>
      <div *ngIf="userForm.controls.password.value != userForm.controls.repeatedPassword.value" class="help-block">Contraseña del usuario es inválida</div>
    </div>
    <div class="groups">
      <label for="groups">Grupos</label>
      <angular2-multiselect [data]="dropdownList" [(ngModel)]="selectedItems"
                            [settings]="dropdownSettings"
                            [selectedItems]="selectedItems" [ngModelOptions]="{standalone: true}"></angular2-multiselect>
    </div>
    <br><br>
    <div class="form-group">
      <bt-text
        [title]="'Aceptar'"
        [type]="'submit'"
        [text]="'Guardar'"
        (clicked)="'null'">
      </bt-text>
    </div>
  </form>
</div>

