<h3>{{title}}</h3>
<br>
<div class="container" *ngIf="url != undefined">
    <div class="form-group">
      <img *ngIf="image != undefined" [src]="image" alt="Banner preview" height="60" width="400">
      <img *ngIf="image == undefined" [src]="url" alt="Banner preview" height="60" width="400">
    </div><br>
    <div class="form-group">
      <label for="media">Banner</label>
      <input type="file"
             accept="image/jpeg,image/png"
             (change)="readUrl($event)"><br>
    </div>
    <div class="form-group">
      <label for="status">Estado</label>
      <select  [(ngModel)]="selectedStatus" [ngModelOptions]="{standalone: true}" required>
        <option *ngFor="let stat of status" [ngValue]="stat">{{stat.description}}</option>
      </select>
    </div>
    <br><br>
    <div class="form-group">
      <bt-text
        [title]="'Aceptar'"
        [type]="'submit'"
        [text]="'Guardar'"
        (clicked)="action($event)">
      </bt-text>
    </div>
</div>

