import {Routes} from "@angular/router/router";
import {InstrumentComponent} from "./instrument.component";
import {InstrumentListComponent} from "./instrument-list.component";
import {InstrumentReadComponent} from "./instrument-read.component";
import {InstrumentUpdateComponent} from "./instrument-update.component";
import { InstrumentGuardService } from './instrument-guard.service';

export const instrumentRoutes: Routes = [
  {
    path: 'instruments',
    component: InstrumentComponent,
    canActivateChild: [InstrumentGuardService],
    data: {
      breadcrumb: "Instrumentos",
      icon: "list"
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: InstrumentListComponent,
        data: {
          breadcrumb: undefined
        }
      },
      {
        path: ':instrumentID',
        component: InstrumentComponent,
        data: {
          breadcrumb: "Detalle",
          icon: "detail"
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: InstrumentReadComponent,
            data: {
              breadcrumb: undefined,
            }
          },
          {
            path: 'update',
            component: InstrumentUpdateComponent,
            canActivate: [InstrumentGuardService],
            data: {
              breadcrumb: "Actualizar",
              icon: "update"
            }
          }
        ]
      }
    ]
  }
]
