import {Routes} from "@angular/router/router";
import {GroupComponent} from "./group.component";
import {GroupListComponent} from "./group-list.component";
import {GroupCreateComponent} from "./group-create.component";
import {GroupReadComponent} from "./group-read.component";
import {GroupUpdateComponent} from "./group-update.component";
import { UserGuardService } from '../user/user-guard.service';

export const groupRoutes: Routes = [
  {
    path: 'groups',
    component: GroupComponent,
    canActivateChild: [UserGuardService],
    data: {
      breadcrumb: "Grupos",
      icon: "list"
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: GroupListComponent,
        data: {
          breadcrumb: undefined
        }
      },
      {
        path:'create',
        component: GroupCreateComponent,
        data: {
          breadcrumb : "Crear"
        }
      },
      {
        path: ':groupID',
        component: GroupComponent,
        data: {
          breadcrumb: "Detalle",
          icon: "detail"
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: GroupReadComponent,
            data: {
              breadcrumb: undefined,
            }
          },
          {
            path: 'update',
            component: GroupUpdateComponent,
            data: {
              breadcrumb: "Actualizar",
              icon: "update"
            }
          }
        ]
      }
    ]
  }
]
