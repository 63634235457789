<h3>{{title}}</h3>
<br>
<div class="container">
  <form [formGroup]="clientForm" name="clientForm" (ngSubmit)="action($event)" novalidate>
    <div class="form-group">
      <label for="name">Nombre</label>
      <input type="text" maxlength="125" formControlName="name" class="form-control" placeholder="Ingrese nombre del cliente" required><br>
      <div *ngIf="!clientForm.controls.name.valid" class="help-block">Nombre del cliente es inválido</div>
    </div>
    <div class="form-group">
      <label for="lastName">Apellidos</label>
      <input type="text" maxlength="125" formControlName="lastName" class="form-control" placeholder="Ingrese apellido del cliente" required><br>
      <div *ngIf="!clientForm.controls.lastName.valid" class="help-block">Apellido del cliente es inválido</div>
    </div>
    <div *ngIf="title == 'Crear cliente'" class="form-group">
      <label for="email">Email</label>
      <input type="text" maxlength="100" formControlName="email" class="form-control" placeholder="Ingrese email del cliente" required><br>
      <div *ngIf="!clientForm.controls.email.valid" class="help-block">Email del cliente es inválido</div>
    </div>
    <div *ngIf="title == 'Crear cliente'" class="form-group">
      <label for="password">Contraseña</label>
      <input type="password" minlength="6" formControlName="password" class="form-control" placeholder="Ingrese contraseña del cliente" required><br>
      <div *ngIf="!clientForm.controls.password.valid" class="help-block">Contraseña del cliente es inválida</div>
    </div>
    <div *ngIf="title == 'Crear cliente'" class="form-group">
      <label for="repeatedPassword">Repita contraseña</label>
      <input type="password" minlength="6" formControlName="repeatedPassword" class="form-control" placeholder="Repita contraseña del cliente" required><br>
      <div *ngIf="clientForm.controls.password.value != clientForm.controls.repeatedPassword.value" class="help-block">Contraseña del cliente es inválida</div>
    </div>
    <div class="form-group">
      <label for="balance">Saldo</label>
      <input type="text" formControlName="balance" maxlength="8" (keypress)="onlyNumberKey($event)" class="form-control" placeholder="Ingrese el saldo del cliente">
      <div *ngIf="!clientForm.controls.balance.valid" class="help-block">Saldo del cliente es inválido</div>
    </div>
    <div class="form-group">
      <label for="performance">Desempeño</label>
      <input type="text" formControlName="performance" maxlength="8" (keypress)="onlyNumberKey($event)" class="form-control" placeholder="Ingrese el desempeño del cliente">
      <div *ngIf="!clientForm.controls.performance.valid" class="help-block">Desempeño del cliente es inválido</div>
    </div>
    <br><br>
    <div class="form-group">
      <bt-text
        [title]="'Aceptar'"
        [type]="'submit'"
        [text]="'Guardar'"
        (clicked)="'null'">
      </bt-text>
    </div>
  </form>
</div>

