import {Component, ViewChild} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {BaseService} from "../shared/services/base.service";
import {Status} from "../status/status";
import {StatusService} from "../status/status.service";
import {NotificationService} from "../shared/notification/notification.service";
import { Location } from '@angular/common';
import {ActivatedRoute} from "@angular/router";
import {Post} from "./post";
import {PostService} from "./post.service";
import {FileHolder, UploadMetadata} from "angular2-image-upload/index";

@Component({
  templateUrl: './post.component.html',
    styleUrls: ['post.component.css']
})

export class PostCreateComponent{

  @ViewChild('imageUpload') imageUpload : any;

  public title: string = 'Crear noticia';
  public post: Post;
  public mainImageUrl : string = '';
  public status : Status[];
  public selectedStatus : Status;

  // ImgUr API for image upload https://apidocs.imgur.com
  public urlImageUploader : string = 'https://api.imgur.com/3/image';

  // Client secret 1f7b59c62b6605ef583ad96bbbb9ae32e8ff906e
  public headersImageUploader : Object = {
    'Authorization': 'Client-ID 2d2d8cbba514f15'
  };

  public options: Object = {
    placeholderText: 'Ingrese el contenido de la noticia',
    language: 'es',
    fileUpload: false,
    imageUploadParam: 'image',
    imageUploadURL: this.urlImageUploader,
    requestHeaders: this.headersImageUploader,
    videoUpload: false,
    events: {
      'froalaEditor.image.uploaded' : function(e, editor, response) {
        let link = JSON.parse(response).data.link;
        editor.image.insert(link, false, null, editor.image.get(), response);

        return false;
      }
    }
  }

  postForm = this.fb.group({
    title: ['', [Validators.required]],
    description: ['', [Validators.required]],
    source: [''],
    content: ['', [Validators.required]],
    url: ['', [Validators.pattern("^(https?|ftp|file)://[-a-zA-Z0-9+&@#/%?=~_|!:,.;]*[-a-zA-Z0-9+&@#/%=~_|]")]]
  });

  constructor(
    private fb: FormBuilder,
    private location: Location,
    private statusService : StatusService,
    private postService : PostService,
    private activatedRouteService : ActivatedRoute,
    private notificationService : NotificationService
  ){}

  ngOnInit(){
    this.statusService.getAll({type: 'post'}).subscribe(response => {
      this.status = <Status[]> response['result'];
      this.selectedStatus = this.status[0];
    }, (err) => { this.notificationService.error('Hubo un problema cargando la información'); });
  }

  onUploadFinished(file : FileHolder){
    if(file.serverResponse.status == 200){
      let response = JSON.parse(file.serverResponse.response._body);
      this.mainImageUrl = response.data.link;
    }
    else{
      this.notificationService.error('Error al subir imagen');
    }
  }

  action($event){
    this.post = <Post> BaseService.builderObject(this.postForm.value);

    if(this.postForm.valid) {

      if(this.imageUpload.files.length == 0 || this.mainImageUrl == '') {
        this.notificationService.error('Debe seleccionar la imagen principal de la noticia');
        return;
      }

      this.post.mainImageUrl = this.mainImageUrl;

      this.activatedRouteService.parent.parent.params.subscribe(param => {

        let obj = <Object> this.post;
        obj['provider'] = {'id' : param['providerID'] }
        obj['status'] = {'id' : this.selectedStatus.id };
        this.postService.create(<Post> obj).subscribe(response => {
          this.notificationService.sucessInsert(this.post.title);
          this.location.back();
        }, (err) => { this.notificationService.error('Error al crear la noticia') });

      }, (err) => { this.notificationService.error('Hubo un problema obteniendo los datos de la URL') });
    }
    else{
      this.notificationService.error('Verifique los datos del formulario');
    }
  }
}
