import {Component} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {NotificationService} from "../shared/notification/notification.service";
import { Location } from '@angular/common';
import {ActivatedRoute} from "@angular/router";
import {BaseService} from "../shared/services/base.service";
import {User} from "../user/user";
import {UserService} from "../user/user.service";

@Component({
  templateUrl: './client.component.html',
  styleUrls: ['client.component.css']
})

export class ClientUpdateComponent{

  public title: string = 'Actualizar cliente';
  public user: User;

  clientForm = this.fb.group({
    name: ['', [Validators.required, Validators.maxLength(125)]],
    lastName: ['', [Validators.required, Validators.maxLength(125)]],
    balance: ['', [Validators.pattern("^[0-9]{1,5}([,.][0-9]{1,2})?$")]],
    performance: ['', [Validators.pattern("^[0-9]{1,5}([,.][0-9]{1,2})?$")]]
  });

  constructor(
    private fb: FormBuilder,
    private location: Location,
    private userService : UserService,
    private activatedRouteService : ActivatedRoute,
    private notificationService : NotificationService
  ){

  }

  ngOnInit(){
    this.activatedRouteService.parent.params.subscribe(param => {

      this.userService.getById(param['userID']).subscribe(result => {
        this.user = new User(result);
        this.clientForm.controls['name'].setValue(this.user.name);
        this.clientForm.controls['lastName'].setValue(this.user.lastName);
        this.clientForm.controls['balance'].setValue(this.user.balance);
        this.clientForm.controls['performance'].setValue(this.user.performance);

      }, (err) => {
        this.notificationService.error('Hubo un problema cargando la información');
      })
    }, (err) => {
      this.notificationService.error('Hubo un problema obteniendo los datos de la URL');
    });
  }

  onlyNumberKey(event){
    if (event.charCode == 46) return true;
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  action($event){
    if(this.clientForm.valid) {
      let obj = BaseService.builderObject(this.clientForm.value);
      obj['id'] = this.user.id;
      this.user = new User(obj);
      this.userService.update(this.user).subscribe(response => {
        this.notificationService.sucessUpdate(this.user.name);
        this.location.back();
      }, (err) => { this.notificationService.error('Error al actualizar el usuario') })
    }
    else{
      this.notificationService.error('Verifique los datos del formulario');
    }
  }
}

