import { Component, OnInit } from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {NotificationService} from "../shared/notification/notification.service";
import { Location } from '@angular/common';
import { EventService } from './event.service';
import { config } from 'rxjs';
declare const EBWidgets: any;

@Component({
  template:`
  <h3>Configurar evento</h3>
  <br>
  <div class="container">
    <form  (ngSubmit)="action($event)" novalidate>

      

      
      <div class="form-group">
          <label for="fechainicio">Buscar desde</label>
          <input type="date" name="since_date"  placeholder="None"  [(ngModel)]="since_date" >
      </div>

      <div class="form-group">
        <label for="fechainicio">Buscar desde</label>
        <input type="date" name="until_date"  placeholder="None"  [(ngModel)]="until_date" >
      </div>

  
      
    

      <br><br>
      <div class="form-group">
        <bt-text
          [title]="'Aceptar'"
          [type]="'submit'"
          [text]="'Guardar'"
          (clicked)="'null'">
        </bt-text>
      </div>
    </form>

<!--
    <button id="example-widget-trigger" type="button" (click)="checkout()">Comprar ticket modal</button>

    <div style="margin-top: 50px;" id="example-widget-trigger2"></div> -->

  </div>
  `,
  styleUrls: ['./event.component.css']
})
export class EventComponent implements OnInit {

  since_date;
  until_date;
  constructor(
    private fb: FormBuilder,
    private location: Location,
    private eventService : EventService,
    private notificationService : NotificationService
  ) { }

  ngOnInit() {
   /* EBWidgets.createWidget({
      widgetType: "checkout",
      eventId: "59064830498",
      iframeContainerId: "example-widget-trigger2",
      iframeContainerHeight: 425,
      onOrderComplete: this.exampleCallback
    });*/
    this.eventService.getConfigs().subscribe( configs => {
      this.setValues(configs.result);
    })   
  }

  exampleCallback(){
    console.log("Listo");
  }

  checkout(){
    EBWidgets.createWidget({
      widgetType: "checkout",
      eventId: "59064830498",
      modal: true,
      modalTriggerElementId: "example-widget-trigger",
      onOrderComplete: this.exampleCallback
    });
  }

  setValues(array){
    var start = array.filter(cat => cat.keyConfig == 'event_start');
    this.since_date = start[0].valueConfig;
    var until = array.filter(cat => cat.keyConfig == 'event_end');
    this.until_date = until[0].valueConfig;

  }

  action($event){
    if(this.since_date == undefined){
        this.notificationService.error('Debe ingresar una fecha de inicio');
        return;
    }
   // se arma el evento para enviar al back
   var event = {start:this.since_date, end:this.until_date}
   this.eventService.create(event).subscribe(response =>{
      this.notificationService.genericsuccess("Exito","Se han guardado con exito los parametros")
    }, err =>{
      console.log(err);
      this.notificationService.error("No se pudieron guardar con exito los parametros")
    });
  }
}
