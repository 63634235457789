import {Component, ViewChild} from "@angular/core";
import {TableColumn} from "../shared/table-ns/table-ns-column";
import {ToolBase} from "../shared/tool-ns/tool/tool-base";
import {Confirmation} from "../shared/confirmation-ns/confirmation-ns.service";
import {QuestionFilterBase} from "../shared/filter-ns/question-filter/question-filter-base";
import {Router, ActivatedRoute} from "@angular/router";
import {TableService} from "../shared/table-ns/table-ns.service";
import {NotificationService} from "../shared/notification/notification.service";
import {QuestionFilterTextbox} from "../shared/filter-ns/question-filter/question-filter-textbox";
import {IconTool} from "../shared/tool-ns/tool/tool-icon";
import {FilterService} from "../shared/filter-ns/filter-ns.service";
import {UserService} from "../user/user.service";
import {User} from "../user/user";

@Component({
  templateUrl: '../utils/crud/list/list.component.html',
  styleUrls: ['./client-list.component.css']
})

export class ClientListComponent {
  public title: string = 'Clientes';
  public tableTitle: string = '';
  public actions2: any[] = [];
  public items: Object[];


  cols: TableColumn[] = [
    new TableColumn({name: 'Nombre', key: 'name', proportion: 14}),
    new TableColumn({name: 'Email', key: 'createdAt', proportion: 14}),
    new TableColumn({name: 'Saldo', key: 'balance', proportion: 6}),
    new TableColumn({name: 'Desempeño',key: 'performance', proportion: 6})
  ];

  tools: ToolBase<any>[] = [
    new IconTool({
      title: 'Crear',
      icon: 'create',
      clicked: this.create.bind(this)
    }),
    new IconTool({
      title: 'Eliminar',
      icon: 'delete',
      clicked: this.confirmationDeletes.bind(this)
    })
  ];

  actions = [
    {
      html: (item) => 'Actualizar',
      click: (item) => this.update(item)
    },
    {
      html: (item) => 'Eliminar',
      click: (item) => this.confirmationDelete(item)
    }
  ];

  confirmation: Confirmation = {hiddenClose: true};
  @ViewChild('tableCmp') tableCmp;
  pager: any;
  questionFilters: QuestionFilterBase<any>[] = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private tableService: TableService,
    private filterService: FilterService,
    private notificationService: NotificationService,
  ){}

  ngOnInit(){
    this.tableService.setSort(this.cols[0].key);
    this.filter();
    this.list(this.tableService.pager.pageIndex);
  }

  filter(){
    let questionFilterEmail =
      new QuestionFilterTextbox({
        key: 'email',
        label: 'Email',
        value: this.filterService.filter['email']!=undefined? this.filterService.filter['email']: '',
        optionsKey: 'email',
        proportion: 2
      });

    this.questionFilters = [
      questionFilterEmail
    ];
  }

  list(page?: number){

    //Fixing sort
    if(this.tableService.sort == 'createdAt')
      this.tableService.sort = 'authUser.email';
    else if(this.tableService.sort == '-createdAt')
      this.tableService.sort = '-authUser.email';

    this.filterService.filter['group'] = "client";

    this.userService.getAll(this.userService.buildRequestOptionsFinder(this.tableService.sort,
      undefined, this.filterService.filter, {pageIndex: page, pageSize: this.tableService.pager.pageSize})).subscribe(
      response => {
        this.items = response['result'];
        for (let item of this.items) {
          item['name'] = item['name'] + " " + item['lastName'];
          item['createdAt'] = item['authUser']['email'];
        }
        this.pager = response['pager'];
        this.tableService.pager.pageIndex = page;
        this.tableCmp.deselectAll();
        this.filterService.filter = {};
      }, (err) => {
        this.notificationService.error('Error del servidor');
      }
    );
  }

  create(){
    this.router.navigate(['./create'], {relativeTo: this.activatedRoute});
  }

  read(item: User){
    this.router.navigate(['./' + item.id], {relativeTo: this.activatedRoute});
  }

  confirmationDeletes(){
    let ids: number[] = this.tableService.getSelectKeys();
    if(ids.length > 0)
      this.confirmation = {
        title : 'Confirmacion de eliminacion',
        message: "¿Está seguro que desea eliminar los clientes seleccionados?",
        yesClicked: this.deletes.bind(this, ids),
        hiddenClose: false
      };
  }

  update(item: User){
    this.router.navigate(["./" + item.id + '/update'], {relativeTo: this.activatedRoute});
  }

  confirmationDelete(item){
    this.confirmation = {
      title : "Confirmacion de eliminación",
      message: "¿Está seguro que desea eliminar el cliente seleccionado?",
      yesClicked: this.delete.bind(this, item),
      hiddenClose: false
    };
  }

  deletes(this, ids: number[]){
  for(let id of ids){
  this.userService.getById(id).subscribe(user => {
  let us = new User(user);
  us.authUser.archived = true;
  this.userService.update(us).subscribe(any => {
  this.tableService.setSort(this.cols[0].key);
  this.filter();
  this.list(this.tableService.pager.pageIndex);
}, (err) => {this.notificationService.error(err); });
}, (err) => { this.notificationService.error(err); });
}
}

delete(this, item: User){
  this.userService.getById(item.id).subscribe(user => {
    let us = new User(user);
    us.authUser.archived = true;
    this.userService.update(us).subscribe(any => {
      this.tableService.setSort(this.cols[0].key);
      this.filter();
      this.list(this.tableService.pager.pageIndex);
    }, (err) => {this.notificationService.error(err); });
  }, (err) => { this.notificationService.error(err); });
}
}
