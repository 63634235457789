import { Component, Inject} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import { AuthService } from '../auth.service';
import { Global } from '../..//global';
import { NotificationService } from '../../../shared/notification/notification.service';
import {AuthorizationRequest} from "../oauth2/authorizationRequest";
import {UserService} from "../../../user/user.service";

@Component({
	selector: 'reset',
	templateUrl: './resetpassword.component.html',
	styleUrls: ['./resetpassword.component.css'],
})
export class ResetPasswordComponent {

  reset: boolean = true;
  resetPasswordForm:FormGroup;
  pin:string;
  email:string;
  web: boolean = false;
  resetpage: boolean;
  success: boolean;
  constructor(@Inject(FormBuilder) fb:FormBuilder,
              private authService:AuthService,
              private router:Router,
              private userService : UserService,
              private activatedRoute: ActivatedRoute,
              private notificationService:NotificationService) {
    this.resetPasswordForm = fb.group({
      currentPassword: ['', Validators.required],
      newPassword: ['', [Validators.required, Validators.minLength(6), Validators.pattern("[^\\w\\d]*(([0-9]+.*[A-Za-z]+.*)|[A-Za-z]+.*([0-9]+.*))")]],
      repeatedPassword: ['', [Validators.required, Validators.minLength(6), Validators.pattern("[^\\w\\d]*(([0-9]+.*[A-Za-z]+.*)|[A-Za-z]+.*([0-9]+.*))")]]
    });
  }

  ngOnInit() {
    this.resetpage = true;
    this.success = false;
    this.activatedRoute.queryParams.subscribe(params => {
        if(params['security'] != undefined && params['email'] != undefined){
          this.email = params['email'];
          this.pin = params['security'];
          this.reset = false;
          if(params['user']){
            this.web = true;
          }
        }
    });
    if(this.pin == undefined && sessionStorage.getItem('token') == undefined){
      this.notificationService.error('No está autorizado para acceder');
      this.router.navigateByUrl('/');
    }
  }

  resetPassword($event) {
    if (this.resetPasswordForm.controls['newPassword'].valid &&
      this.resetPasswordForm.controls['repeatedPassword'].valid && (this.resetPasswordForm.controls['newPassword'].value
      == this.resetPasswordForm.controls['repeatedPassword'].value)) {
      if(this.reset){
        if(this.resetPasswordForm.controls['currentPassword'].valid) {
          this.authService.resetPassword(this.resetPasswordForm.controls['currentPassword'].value,
            this.resetPasswordForm.controls['newPassword'].value).subscribe(response => {
            localStorage.clear();
            sessionStorage.clear();
            if (this.web) { // Solo si se esta recuperando password desde la app web, se redirige al home - sino solo muestra en mensaje
              this.notificationService.genericsuccess('Éxito', 'Contraseña cambiada satisfactoriamente');
              this.router.navigateByUrl('/');
            } else {
              this.notificationService.genericsuccess('Éxito', 'Contraseña cambiada satisfactoriamente, inicia sesión desde la app');
            }
          }, (err) => {
            if(err == 'Error 401 Unauthorized') this.notificationService.error('Contraseña actual incorrecta');
            else this.notificationService.error('Error del servidor');
          });
        }
      }else{
          this.authService.forgotPassword(this.email, this.resetPasswordForm.controls['newPassword'].value,
          this.pin).subscribe(response => {
              if (this.web) { // Solo si se esta recuperando password desde la app web, se redirige al home - sino solo muestra en mensaje
                this.notificationService.genericsuccess('Éxito', 'Contraseña cambiada satisfactoriamente');
                this.router.navigateByUrl('/');
              } else {
                this.resetpage = false;
                this.success = true;
                //this.notificationService.genericsuccess('Éxito', 'Contraseña cambiada satisfactoriamente, inicia sesión desde la app');
              }
          },
            (err) => {
              if(err == 'Error 404 Not Found') this.notificationService.error('Información suministrada inválida');
              else if(err == 'Error 400 Bad Request') this.notificationService.error('Información suministrada inválida');
              else if(err == 'Error 401 Unauthorized') this.notificationService.error('Solicite de nuevo su correo de recuperación de contraseña');
              else this.notificationService.error('Error del servidor');
            }
          );
      }
    }
  }

}
