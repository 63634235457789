import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {SharedModule} from "../shared/shared.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {homeRouting} from "../home/home.routes";
import {HttpClientModule} from "@angular/common/http";
import {StatusService} from "../status/status.service";
import {SeasonService} from "./season.service";
import {SeasonComponent} from "./season.component";
import {SeasonListComponent} from "./season-list.component";
import {SeasonCreateComponent} from "./season-create.component";
import {SeasonUpdateComponent} from "./season-update.component";
import {SeasonReadComponent} from "./season-read.component";
import {SeasonHistoryService} from "../season-history/season-history.service";
import { SeasonGuardService } from "./season-guard.service";

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    homeRouting,
    ReactiveFormsModule,
    HttpClientModule
  ],
  declarations:[
    SeasonComponent,
    SeasonListComponent,
    SeasonCreateComponent,
    SeasonUpdateComponent,
    SeasonReadComponent
  ],
  providers:[
    SeasonService,
    StatusService,
    SeasonHistoryService,
    SeasonGuardService
  ]
})

export class SeasonModule { }
