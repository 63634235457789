import {BaseModel} from "../shared/models/base.model";
import {Status} from "../status/status";

export class Season extends BaseModel{
  name: string;
  startDate: Date;
  endDate: Date;
  status: Status;

  constructor(object){
    super(object);
    if(object.name != undefined)
      this.name = object.name;
    if(object.startDate != undefined)
      this.startDate = object.startDate;
    if(object.endDate != undefined)
      this.endDate = object.endDate;
    if(object.status != undefined)
      this.status = new Status(object.status);
  }
}
