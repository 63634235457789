import {Component} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {BaseService} from "../shared/services/base.service";
import {NotificationService} from "../shared/notification/notification.service";
import { Location } from '@angular/common';
import {User} from "../user/user";
import {UserService} from "../user/user.service";

@Component({
  templateUrl: './client.component.html',
  styleUrls: ['client.component.css']
})

export class ClientCreateComponent{

  public title: string = 'Crear cliente';
  public user: User;

  clientForm = this.fb.group({
    name: ['', [Validators.required, Validators.maxLength(125)]],
    lastName: ['', [Validators.required, Validators.maxLength(125)]],
    email: ['', [Validators.required, Validators.maxLength(100), Validators.pattern("^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$")]],
    password: ['', [Validators.required, Validators.minLength(6), Validators.pattern("[^\\w\\d]*(([0-9]+.*[A-Za-z]+.*)|[A-Za-z]+.*([0-9]+.*))")]],
    repeatedPassword: ['', [Validators.required, Validators.minLength(6), Validators.pattern("[^\\w\\d]*(([0-9]+.*[A-Za-z]+.*)|[A-Za-z]+.*([0-9]+.*))")]],
    balance: ['', [Validators.pattern("^[0-9]{1,5}([,.][0-9]{1,2})?$")]],
    performance: ['', [Validators.pattern("^[0-9]{1,5}([,.][0-9]{1,2})?$")]]
  });

  constructor(
    private fb: FormBuilder,
    private location: Location,
    private userService : UserService,
    private notificationService : NotificationService
  ){}

  ngOnInit(){
  }

  //Metodo que permite validar que solo se introduzcan digitos
  onlyNumberKey(event){
    if (event.charCode == 46) return true;
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  action($event){
    if(this.clientForm.valid && this.clientForm.controls['password'].value == this.clientForm.controls['repeatedPassword'].value) {

      let obj = BaseService.builderObject(this.clientForm.value);
      obj['authUser'] = { };
      obj['authUser']['email'] = obj['email'];
      obj['authUser']['password'] = obj['password'];
      this.user = new User(obj);
      this.userService.create(this.user).subscribe(response => {
        this.notificationService.sucessInsert(this.user.name);
        this.location.back();
      }, (err) => {
        if(err == 'Error 409 Conflict') this.notificationService.error('Email ya está siendo utilizado');
        else this.notificationService.error('Error al crear el usuario'); })
    }
    else{
      this.notificationService.error('Verifique los datos del formulario');
    }
  }
}
