<h3>{{title}}</h3>
<br>
<div class="container">
  <form [formGroup]="providerForm" name="providerForm" (ngSubmit)="action($event)" novalidate>
    <div class="form-group">
        <label for="name">Nombre</label>
        <input type="text" maxlength="125" formControlName="name" class="form-control" placeholder="Ingrese nombre del proveedor" required><br>
        <div *ngIf="!providerForm.controls.name.valid" class="help-block">Nombre del proveedor es inválido</div>
    </div>
    <div class="form-group">
      <label for="url">URL</label>
      <input type="url" formControlName="url" class="form-control" placeholder="Ingrese la URL del proveedor" required><br>
      <div *ngIf="!providerForm.controls.url.valid" class="help-block">URL del proveedor inválida</div>
    </div>
    <div class="form-group">
      <label for="type">Tipo</label>
      <input type="text" maxlength="125" formControlName="type" class="form-control" placeholder="Ingrese el tipo de proveedor" required><br>
      <div *ngIf="!providerForm.controls.type.valid" class="help-block">Tipo de proveedor inválido</div>
    </div>
    <div class="form-group">
      <label for="status">Estado</label>
      <select  [(ngModel)]="selectedStatus" [ngModelOptions]="{standalone: true}" required>
        <option *ngFor="let stat of status" [ngValue]="stat">{{stat.description}}</option>
      </select>
    </div>
    <br><br>
    <div class="form-group">
      <bt-text
        [title]="'Aceptar'"
        [type]="'submit'"
        [text]="'Guardar'"
        (clicked)="'null'">
      </bt-text>
    </div>
  </form>
</div>
