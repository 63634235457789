<h3>Información del instrumento</h3>

<tool-ns
  [tools]="tools"
  [toolsLeft]="toolsLeft">
</tool-ns>

<div class="read">
  <div *ngIf="instrument != undefined && issuer != undefined && clazz != undefined && status != undefined">
  <div class="form-group">
    <div class="form-subgroup">
      <p> <label>Idticker</label> {{ instrument.idticker }} </p>
    </div>
  </div>
  <div class="form-group">
    <div class="form-subgroup">
      <p><label>ISIN</label> {{ instrument.isin }} </p>
    </div>
  </div>
  <div class="form-group">
    <div class="form-subgroup">
      <p><label>Precio actual</label> {{ instrument.currentPrice }} </p>
    </div>
  </div>
  <div class="form-group">
    <div class="form-subgroup">
      <p><label>Último precio</label> {{ instrument.lastPrice }} </p>
    </div>
  </div>
  <div class="form-group">
    <div class="form-subgroup">
      <p><label>Variación en el precio</label> {{ instrument.variation }} </p>
    </div>
  </div>
    <div class="form-group">
      <div class="form-subgroup">
        <p><label>Porcentaje de variación en el precio</label> {{ instrument.variationPercent }} %</p>
      </div>
    </div>
  <div class="form-group" *ngIf="instrument.tradeVolume != null">
    <div class="form-subgroup">
      <p><label>Volumen de negociación</label> {{ instrument.tradeVolume }} </p>
    </div>
  </div>
  <div class="form-group" *ngIf="instrument.eps != null">
    <div class="form-subgroup">
      <p><label>EPS</label> {{ instrument.eps }} </p>
    </div>
  </div>
  <div class="form-group" *ngIf="instrument.weekHigh != null">
    <div class="form-subgroup">
      <p><label>Precio en su semana más alta</label> {{ instrument.weekHigh }} </p>
    </div>
  </div>
  <div class="form-group" *ngIf="instrument.weekLow != null">
    <div class="form-subgroup">
      <p><label>Precio en su semana más baja</label> {{ instrument.weekLow }} </p>
    </div>
  </div>
  <div class="form-group" *ngIf="instrument.marketCap != null">
    <div class="form-subgroup">
      <p><label>Capital de mercado</label> {{ instrument.marketCap }} </p>
    </div>
  </div>
  <div class="form-group" *ngIf="instrument.nominalValue != null">
    <div class="form-subgroup">
      <p><label>Valor nominal</label> {{ instrument.nominalValue }} </p>
    </div>
  </div>
  <div class="form-group" *ngIf="instrument.negotiatedAmount != null">
    <div class="form-subgroup">
      <p><label>Valor de negociación</label> {{ instrument.negotiatedAmount }} </p>
    </div>
  </div>
  <div class="form-group" *ngIf="instrument.serieAmount != null">
    <div class="form-subgroup">
      <p><label>Monto serie</label> {{ instrument.serieAmount }} </p>
    </div>
  </div>
  <div class="form-group" *ngIf="instrument.rate != null">
    <div class="form-subgroup">
      <p><label>Tasa</label> {{ instrument.rate }} </p>
    </div>
  </div>
  <div class="form-group" *ngIf="instrument.lastDateDividend != null">
    <div class="form-subgroup">
      <p><label>Fecha del último dividendo</label> {{ instrument.lastDateDividend | date:"dd/MM/yy"}} </p>
    </div>
  </div>
  <div class="form-group">
    <div class="form-subgroup">
      <p><label>Fecha de emisión</label> {{ instrument.issueDate | date:"dd/MM/yy"}} </p>
    </div>
  </div>
  <div class="form-group">
    <div class="form-subgroup">
      <p><label>Fecha de vencimiento</label> {{ instrument.expirationDate | date:"dd/MM/yy" }} </p>
    </div>
  </div>
  <div class="form-group">
    <div class="form-subgroup">
      <p> <label>Clase</label> {{ clazz.name }} </p>
    </div>
  </div>
  <div class="form-group">
    <div class="form-subgroup">
      <p> <label>Emisor</label> {{ issuer.name }} </p>
    </div>
  </div>
  <div class="form-group">
    <div class="form-subgroup">
      <p> <label>Estado</label> {{ status.description }} </p>
    </div>
  </div>
</div>
</div>
<br>
<br>
<confirm-ns
  [title]="confirmation.title"
  [message]="confirmation.message"
  [(hiddenClose)]="confirmation.hiddenClose"
  (yesClicked)="confirmation.yesClicked($event)">
</confirm-ns>
