import {BaseModel} from "../shared/models/base.model";
import {Status} from "../status/status";
export class IndiceType extends BaseModel{
  name: string;
  description: string;
  showIndiceHome: boolean;
  status: Status;

  constructor(object){
    super(object);
    if(object.name != undefined)
      this.name = object.name;
    if(object.description != undefined)
      this.description = object.description;
    if(object.showIndiceHome != undefined)
      this.showIndiceHome = object.showIndiceHome;
    if(object.status != undefined)
      this.status = new Status(object.status);
  }
}
