import {Routes} from "@angular/router/router";
import {ClientComponent} from "./client.component";
import {ClientListComponent} from "./client-list.component";
import {ClientCreateComponent} from "./client-create.component";
import {ClientReadComponent} from "./client-read.component";
import {ClientUpdateComponent} from "./client-update.component";
import { ClientGuardService } from './client-guard.service';

export const clientRoutes: Routes = [
  {
    path: 'clients',
    component: ClientComponent,
    canActivateChild: [ClientGuardService],
    data: {
      breadcrumb: "Clientes",
      icon: "list"
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: ClientListComponent,
        data: {
          breadcrumb: undefined
        }
      },
      {
        path:'create',
        component: ClientCreateComponent,
        data: {
          breadcrumb : "Crear"
        }
      },
      {
        path: ':userID',
        component: ClientComponent,
        data: {
          breadcrumb: "Detalle",
          icon: "detail"
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: ClientReadComponent,
            data: {
              breadcrumb: undefined,
            }
          },
          {
            path: 'update',
            component: ClientUpdateComponent,
            data: {
              breadcrumb: "Actualizar",
              icon: "update"
            }
          }
        ]
      }
    ]
  }
]
