import {Component} from '@angular/core';
import {Status} from "../status/status";
import {NotificationService} from "../shared/notification/notification.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Confirmation} from "../shared/confirmation-ns/confirmation-ns.service";
import {ToolBase} from "../shared/tool-ns/tool/tool-base";
import {IconTool} from "../shared/tool-ns/tool/tool-icon";
import {StatusService} from "../status/status.service";
import {Season} from "./season";
import {SeasonService} from "./season.service";
import {SeasonHistoryService} from "../season-history/season-history.service";
import {SeasonHistory} from "../season-history/season-history";

@Component({
  templateUrl: './season-read.component.html',
  styleUrls: ['season-read.component.css']
})

export class SeasonReadComponent{

  public season: Season;
  public status : Status;
  public histories : SeasonHistory[];
  toolsLeft: ToolBase<any>[] = [];

  tools: ToolBase<any>[] = [
    new IconTool({
      title: "Editar",
      icon: "update",
      clicked: this.update.bind(this)
    }),
    new IconTool({
      title: "Eliminar",
      icon: "delete",
      clicked: this.confirmationDelete.bind(this)
    })
  ];
  confirmation: Confirmation = {hiddenClose: true};


  constructor(
    private router: Router,
    private seasonService : SeasonService,
    private statusService : StatusService,
    private seasonHistoryService : SeasonHistoryService,
    private activatedRouteService : ActivatedRoute,
    private notificationService : NotificationService
  ){
  }

  ngOnInit(){
    this.activatedRouteService.parent.params.subscribe(param => {

      this.seasonService.getById(param['seasonID']).subscribe(result => {
        this.season = result;

          let stat = result.status.id;
          this.statusService.getById(stat).subscribe(status => {
            this.status = status;

            this.seasonHistoryService.getAll( {name: this.season.name, sort: '-createdAt'}).subscribe(resp => {
              this.histories = resp['result'];

            }, (err) => { this.notificationService.error('Hubo un problema cargando la información'); });

        }, (err) => { this.notificationService.error('Hubo un problema cargando la información'); });
      }, (err) => { this.notificationService.error('Hubo un problema cargando la información'); })
    }, (err) => { this.notificationService.error('Hubo un problema obteniendo los datos de la URL'); });
  }

  update(){
    this.router.navigate(['./update'], {relativeTo: this.activatedRouteService});
  }

  confirmationDelete(){
    this.confirmation = {
      message: "¿Está seguro que desea eliminar la temporada?",
      yesClicked: this.delete.bind(this),
      hiddenClose: false
    };
  }

  delete(this){
    if(!this.seasonService.checkRoles(['season_manager'])) {
      this.notificationService.error('Usted no cuenta con acceso a esta función. Contacte al administrador');
      return;
    }
    this.seasonService.getById(this.season.id).subscribe(season => {
      let sea = new Season(season);
      sea.status.id = 2;
      this.seasonService.update(sea).subscribe(any => {
        this.ngOnInit();
        this.notificationService.sucessUpdate(this.season.name);
      }, (err) => {this.notificationService.error(err); });
    }, (err) => { this.notificationService.error(err); });
  }
}
