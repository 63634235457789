import {Routes} from "@angular/router/router";
import {UserComponent} from "./user.component";
import {UserListComponent} from "./user-list.component";
import {UserCreateComponent} from "./user-create.component";
import {UserUpdateComponent} from "./user-update.component";
import {UserReadComponent} from "./user-read.component";
import { UserGuardService } from './user-guard.service';

export const userRoutes: Routes = [
  {
    path: 'users',
    component: UserComponent,
    canActivateChild: [UserGuardService],
    data: {
      breadcrumb: "Usuarios",
      icon: "list"
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: UserListComponent,
        data: {
          breadcrumb: undefined
        }
      },
      {
        path:'create',
        component: UserCreateComponent,
        data: {
          breadcrumb : "Crear"
        }
      },
      {
        path: ':userID',
        component: UserComponent,
        data: {
          breadcrumb: "Detalle",
          icon: "detail"
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: UserReadComponent,
            data: {
              breadcrumb: undefined,
            }
          },
          {
            path: 'update',
            component: UserUpdateComponent,
            data: {
              breadcrumb: "Actualizar",
              icon: "update"
            }
          }
        ]
      }
    ]
  }
]
