import {Routes} from "@angular/router/router";
import {ConfigComponent} from "./config.component";
import {ConfigGuardService} from "./config-guard.service";
import {ConfigUpdateComponent} from "./config-update.component";

export const configRoutes: Routes = [
  {
    path: 'config',
    component: ConfigComponent,
    canActivateChild: [ConfigGuardService],
    data: {
      breadcrumb: "Config",
      icon: "list"
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: ConfigUpdateComponent,
        data: {
          breadcrumb: undefined
        }
      }
      ]
  }
]
