import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventComponent } from './event.component';
import {SharedModule} from "../shared/shared.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {homeRouting} from "../home/home.routes";
import {HttpClientModule} from "@angular/common/http";
import { EventService } from './event.service';
import {AngularMultiSelectModule} from "../utils/angular2-multiselect-checkbox-dropdown/src/app/angular2-multiselect-dropdown/multiselect.component";

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    homeRouting,
    ReactiveFormsModule,
    AngularMultiSelectModule,
    HttpClientModule
  ],
  declarations: [EventComponent],
  providers:[
    EventService
  ]
})
export class EventModule { }
