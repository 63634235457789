import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '../../shared/notification/notification.service';

@Component({
	selector: 'usertool',
	templateUrl: './usertool.component.html',
	styleUrls: ['./usertool.component.css'],
})

export class UsertoolComponent{
	logged = false;
	username: string;
	constructor(private router: Router, private notificationService: NotificationService){}

	ngOnInit() {
		this.logged = JSON.parse(localStorage.getItem('panabolsawebadmin')) != null ? true : false;
		if (this.logged) {
      this.username = JSON.parse(localStorage.getItem('panabolsawebadmin')).user.name.toUpperCase();
		} else {
			this.notificationService.genericerror('No ha ingresado al sistema', 'Debe autenticarse');
			this.router.navigateByUrl('/');
			localStorage.clear();
			sessionStorage.clear();
		}
	}

	public logout() {
		this.notificationService.genericsuccess('Ha salido del sistema', 'Tenga un buen dia!');
		this.router.navigate(['/']);
		localStorage.clear();
		sessionStorage.clear();
	}

  public resetPassword(){
    this.router.navigate(['/users/reset-password']);
  }
}
