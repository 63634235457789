export class BaseModel {
  	id: number;
   	createdAt: string;
    updatedAt: string;

    constructor(object){
      if(object.id != undefined)
        this.id = object.id;
    }
}
