<h3>{{title}}</h3>
<br>
<div class="container">
  <form [formGroup]="stockIndiceForm" name="stockIndiceForm" (ngSubmit)="action($event)" novalidate>
    <div class="form-group">
        <label for="name">Nombre</label>
        <input type="text" maxlength="125" formControlName="name" class="form-control" placeholder="Ingrese nombre del índice" required><br>
        <div *ngIf="!stockIndiceForm.controls.name.valid" class="help-block">Nombre del índice es inválido</div>
    </div>
    <div class="form-group">
        <label for="type">Tipo</label>
        <input type="text" formControlName="type" class="form-control" placeholder="Ingrese el tipo del índice">
    </div>
    <div class="form-group">
      <label for="value">Valor</label>
      <input type="text" formControlName="value"  (keypress)="onlyNumberKey($event)" class="form-control" placeholder="Ingrese el valor del índice" required>
      <div *ngIf="!stockIndiceForm.controls.value.valid" class="help-block">Valor es inválido</div>
    </div>
    <div class="form-group">
      <label for="changeValue">Valor actual</label>
      <input type="text" formControlName="currentValue" class="form-control" placeholder="Ingrese el valor actual del índice">
      <div *ngIf="!stockIndiceForm.controls.currentValue.valid" class="help-block">Valor actual es inválido</div>
    </div>
    <div class="form-group">
      <label for="changeValue">Valor de cambio</label>
      <input type="text" formControlName="changeValue" (keypress)="onlyNumberKey($event)" class="form-control" placeholder="Ingrese el valor de cambio actual del índice">
      <div *ngIf="!stockIndiceForm.controls.changeValue.valid" class="help-block">Valor de cambio actual es inválido</div>
    </div>
    <div class="form-group">
      <label for="changeValue">Variación</label>
      <select  [(ngModel)]="variation" [ngModelOptions]="{standalone: true}" required>
        <option [ngValue]="1"> Aumento </option>
        <option [ngValue]="2"> Bajo </option>
        <option [ngValue]="3"> Mantiene </option>
      </select>
    </div>
    <div class="form-group">
      <label for="showMainHome">¿Mostrar en la pantalla principal?</label>
      <select  [(ngModel)]="selectedShowMainHome" [ngModelOptions]="{standalone: true}" required>
        <option [ngValue]="true"> Si </option>
        <option [ngValue]="false"> No </option>
      </select>
    </div>
    <div class="form-group">
      <label for="status">Estado</label>
      <select  [(ngModel)]="selectedStatus" [ngModelOptions]="{standalone: true}" required>
        <option *ngFor="let stat of status" [ngValue]="stat">{{stat.description}}</option>
      </select>
    </div>
    <br><br>
    <div class="form-group">
      <bt-text
        [title]="'Aceptar'"
        [type]="'submit'"
        [text]="'Guardar'"
        (clicked)="'null'">
      </bt-text>
    </div>
  </form>
</div>
