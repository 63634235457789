import {Component} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {Status} from "../status/status";
import {StatusService} from "../status/status.service";
import {NotificationService} from "../shared/notification/notification.service";
import { Location } from '@angular/common';
import {ActivatedRoute} from "@angular/router";
import {Instrument} from "./instrument";
import {Class} from "../class/class";
import {Issuer} from "../issuer/issuer";
import {InstrumentService} from "./instrument.service";
import {ClassService} from "../class/class.service";
import {IssuerService} from "../issuer/issuer.service";
import {BaseService} from "../shared/services/base.service";

@Component({
  templateUrl: './instrument.component.html',
  styleUrls: ['instrument.component.css']
})

export class InstrumentUpdateComponent{

  public title: string = 'Actualizar instrumento';
  public instrument: Instrument;
  public selectedClass : Class;
  public selectedIssuer : Issuer;
  public classes : Class[];
  public issuers : Issuer[];
  public status : Status[];
  public selectedStatus : Status;

  instrumentForm = this.fb.group({
    idticker: ['', [Validators.required]],
    isin: ['', [Validators.required]],
    currentPrice: ['', [Validators.required, Validators.pattern("^[0-9]{1,13}([,.][0-9]{1,2})?$")]],
    lastPrice: ['', [Validators.required, Validators.pattern("^[0-9]{1,13}([,.][0-9]{1,2})?$")]],
    variation: ['', [Validators.required, Validators.pattern("^[0-9]{1,7}([,.][0-9]{1,2})?$")]],
    tradeVolume: ['', [Validators.pattern("^[0-9]{1,13}([,.][0-9]{1,2})?$")]],
    eps: ['', [Validators.pattern("^[0-9]{1,13}([,.][0-9]{1,2})?$")]],
    marketCap: ['', [Validators.pattern("^[0-9]{1,18}([,.][0-9]{1,2})?$")]],
    nominalValue: ['', [Validators.pattern("^[0-9]{1,13}([,.][0-9]{1,2})?$")]],
    negotiatedAmount: ['', [Validators.pattern("^[0-9]{1,13}([,.][0-9]{1,2})?$")]],
    serieAmount: ['', [Validators.pattern("^[0-9]{1,13}([,.][0-9]{1,2})?$")]],
    rate: [''],
    lastDateDividend: [''],
    issueDate: ['', [Validators.required]],
    expirationDate: ['']
  });

  constructor(
    private fb: FormBuilder,
    private location: Location,
    private statusService : StatusService,
    private instrumentService : InstrumentService,
    private issuerService : IssuerService,
    private classService : ClassService,
    private activatedRouteService : ActivatedRoute,
    private notificationService : NotificationService
  ){

  }
  //Metodo que permite validar que solo se introduzcan digitos
  onlyNumberKey(event){
    if (event.charCode == 46) return true;
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  ngOnInit(){
    this.statusService.getAll({type: 'instrument'}).subscribe(response => {
      this.status = <Status[]> response['result'];

      this.issuerService.getAll().subscribe(resp => {

        this.issuers = <Issuer[]> resp['result'];

        this.classService.getAll().subscribe(res => {

          this.classes = <Class[]> res['result'];

        this.activatedRouteService.parent.params.subscribe(param => {

          this.instrumentService.getById(param['instrumentID']).subscribe(result => {
            this.instrument = <Instrument> result;
            this.instrumentForm.controls['idticker'].setValue(this.instrument.idticker);
            this.instrumentForm.controls['isin'].setValue(this.instrument.isin);
            this.instrumentForm.controls['currentPrice'].setValue(this.instrument.currentPrice);
            this.instrumentForm.controls['lastPrice'].setValue(this.instrument.lastPrice);
            this.instrumentForm.controls['rate'].setValue(this.instrument.rate);
            this.instrumentForm.controls['serieAmount'].setValue(this.instrument.serieAmount);
            this.instrumentForm.controls['variation'].setValue(this.instrument.variation);
            this.instrumentForm.controls['tradeVolume'].setValue(this.instrument.tradeVolume);
            this.instrumentForm.controls['eps'].setValue(this.instrument.eps);
            this.instrumentForm.controls['marketCap'].setValue(this.instrument.marketCap);
            this.instrumentForm.controls['nominalValue'].setValue(this.instrument.nominalValue);
            this.instrumentForm.controls['negotiatedAmount'].setValue(this.instrument.negotiatedAmount);
            this.instrumentForm.controls['lastDateDividend'].setValue(this.formatDateIn(this.instrument.lastDateDividend.toString()));
            this.instrumentForm.controls['issueDate'].setValue(this.formatDateIn(this.instrument.issueDate.toString()));
            this.instrumentForm.controls['expirationDate'].setValue(this.formatDateIn(this.instrument.expirationDate.toString()));

            let id = result.status.id;
            for(let stat of this.status){
              if(stat.id == id){
                this.selectedStatus = stat;
              }
            }

            let idi = result.issuer.id;
            for(let issuer of this.issuers){
              if(issuer.id == idi){
                this.selectedIssuer = issuer;
              }
            }

            let idc = result.instrumentClass.id;
            for(let clazz of this.classes){
              if(clazz.id == idc){
                this.selectedClass = clazz;
              }
            }

        }, (err) => {
          this.notificationService.error('Hubo un problema cargando la información');
        })
      }, (err) => {
        this.notificationService.error('Hubo un problema obteniendo los datos de la URL');
      });
      }, (err) => { this.notificationService.error('Hubo un problema cargando la información'); });
      }, (err) => { this.notificationService.error('Hubo un problema cargando la información'); });
    }, (err) => { this.notificationService.error('Hubo un problema cargando la información'); });
  }

  formatDateIn(format: string) : string{
    let date = new Date(format); // had to remove the colon (:) after the T in order to make it work
    let day = date.getDate().toString();
    let monthIndex = date.getMonth();
    let year = date.getFullYear();
    let month = (monthIndex+1).toString();
    if(month.length == 1) month = "0"+month;
    if(day.length == 1) day = "0"+day;
    return year+"-"+month+"-"+day;
  }

  formatDateOut(format: string) : string{
    let split = format.split("-");
    let date = new Date(Number.parseInt(split[0]),Number.parseInt(split[1])-1,Number.parseInt(split[2])); // had to remove the colon (:) after the T in order to make it work
    let day = date.getDate().toString();
    let hour = date.getHours().toString();
    let minutes = date.getMinutes().toString();
    let seconds = date.getSeconds().toString();
    let timezone = date.toString().match(/([-\+][0-9]+)\s/)[1];
    let monthIndex = date.getMonth();
    let year = date.getFullYear();
    let month = (monthIndex+1).toString();
    if(month.length == 1) month = "0"+month;
    if(day.length == 1) day = "0"+day;
    if(hour.length == 1) hour = "0"+hour;
    if(minutes.length == 1) minutes = "0"+minutes;
    if(seconds.length == 1) seconds = "0"+seconds;
    return year+"-"+month+"-"+day+"T"+hour+":"+minutes+":"+seconds+timezone;
  }

  action($event){
    if(this.instrumentForm.valid) {
      let id = this.instrument.id;

      this.instrument = <Instrument> this.instrumentForm.value;
      this.instrument.id = id;

      let obj = <Object> this.instrument;
      obj['status'] = {'id' : this.selectedStatus.id };
      obj['issuer'] = {'id': this.selectedIssuer.id };
      obj['instrumentClass'] = {'id' : this.selectedClass.id };
      obj['lastDateDividend'] = this.formatDateOut(this.instrument.lastDateDividend.toString());
      obj['issueDate'] = this.formatDateOut(this.instrument.issueDate.toString());
      obj['expirationDate'] = this.formatDateOut(this.instrument.expirationDate.toString());

      this.instrumentService.update(<Instrument> obj).subscribe(response => {
        this.notificationService.sucessUpdate(this.instrument.idticker);
        this.location.back();
      }, (err) => { this.notificationService.error('Error al actualizar el instrumento') })
    }
    else{
      this.notificationService.error('Verifique los datos del formulario');
    }
  }
}

