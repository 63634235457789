import {Injectable} from "@angular/core";
import {BaseService} from "../shared/services/base.service";
import {Observable} from "rxjs/Rx";
import {HttpClient, HttpParams} from "@angular/common/http";
import {catchError} from "rxjs/operators";
import {Instrument} from "./instrument";


@Injectable()
export class InstrumentService extends BaseService{

  BASE_URL: string = this.HOST +'/instruments';

  constructor(
    private http: HttpClient){
      super();
  }

  getAll(params : any = '{ }'): Observable<Object> {
      return this.http.get(this.BASE_URL, {
        params: params,
        headers: this.createAuthorizationHeader()
      }).pipe(
        catchError(this.handleError)
      );
  }

  getById(id: number) : Observable<Instrument>{
    return this.http.get(this.BASE_URL + '/' + id,
      { headers: this.createAuthorizationHeader() }).map(this.extractData).pipe(
      catchError(this.handleError)
    );
  }

  create(instrument : Instrument) : Observable<Instrument>{
    return this.http.post(this.BASE_URL, instrument, { headers: this.createAuthorizationHeader() }).map(this.extractData).pipe(
      catchError(this.handleError)
    );
  }

  update(instrument : Instrument) : Observable<Instrument>{
    return this.http.put(this.BASE_URL + '/' + instrument.id, instrument,
      { headers: this.createAuthorizationHeader() }).map(this.extractData).pipe(
      catchError(this.handleError)
    );
  }
}
