import { Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import {ForgotPasswordComponent} from './forgotPassword/forgotPassword.component';
import {ResetPasswordComponent} from './resetPassword/resetpassword.component';
import {ResetsuccessComponent} from './resetPassword/resetsuccess/resetsuccess.component';

export const authRoutes: Routes = [
	{
		path: '',
		pathMatch: 'full',
		component: LoginComponent
	},
	{
		path: 'forgotpassword',
		component: ForgotPasswordComponent
	},
  {
    path: 'users/reset-password',
    component: ResetPasswordComponent
  },
  {
    path: 'users/resetsuccess',
    component: ResetsuccessComponent
  }
];
