<div class="container">
	<img src="/assets/images/logo.png" alt="logo"/>
	<form [formGroup]="loginForm" (ngSubmit)="login($event)" #f="ngForm" novalidate>
		<div class="form-group">
			<label for="email">Email</label>
			<input type="text" formControlName="email" class="form-control">
			<div *ngIf="f.submitted && !loginForm.controls['email'].valid" class="help-block">Email is required</div>
		</div>
		<div class="form-group">
			<label for="password">Contraseña</label>
			<input type="password" formControlName="password" class="form-control">
			<div *ngIf="f.submitted && !loginForm.controls['password'].valid" class="help-block">Password is required</div>
		</div>
		<div class="form-group">
			<bt-text
	            [title]="'enter'"
	            [type]="'submit'"
				[text]="'Enter'"
	            (clicked)="'null'">
	        </bt-text>
			<a (click)="showDialog()">¿Olvidó su contraseña?</a>
		</div>
	</form>
</div>
<forgot-password *ngIf="opened" (opened)="onOpen($event)"></forgot-password>
