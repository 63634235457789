import {BaseModel} from "../shared/models/base.model";
import {Status} from "../status/status";
export class Provider extends BaseModel{
  name: string;
  url: string;
  type: string;
  status: Status;

  constructor(object){
    super(object);
    if(object.name != undefined)
      this.name = object.name;
    if(object.url != undefined)
      this.url = object.url;
    if(object.type != undefined)
      this.type = object.type;
    if(object.status != undefined)
      this.status = new Status(object.status);
  }
}
