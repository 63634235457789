import {TableService} from "../shared/table-ns/table-ns.service";
import {FilterService} from "../shared/filter-ns/filter-ns.service";
import {Component, OnInit} from "@angular/core";

@Component({
  selector: 'app-post',
  template: '<router-outlet></router-outlet>',
  providers: [
    TableService,
    FilterService
  ]
})

export class PostComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
