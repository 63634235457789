import {BaseModel} from "../shared/models/base.model";
export class AuthUser extends BaseModel{
  email : string;
  password : string;
  lastLogin : string;
  archived : boolean;
  verified : boolean;
  groups : Object;

  constructor(object){
    super(object);
    if(object.email != undefined) this.email = object.email;
    if(object.archived != undefined) this.archived = object.archived;
    if(object.verified != undefined) this.verified = object.verified;
    if(object.groups != undefined) this.groups = object.groups;
    if(object.password != undefined) this.password = object.password;
    if(object.lastLogin != undefined) this.lastLogin = object.lastLogin;
  }
}
