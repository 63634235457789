import {Injectable} from "@angular/core";
import {BaseService} from "../shared/services/base.service";
import {Observable} from "rxjs/Rx";
import {HttpClient, HttpParams,  HttpHeaders} from "@angular/common/http";
import {catchError} from "rxjs/operators";


@Injectable()
export class EventService extends BaseService{

  BASE_URL: string = this.HOST +'/configs';
  BASE_URL_EVENT: string = 'https://www.eventbriteapi.com/v3/';

  constructor(
    private http: HttpClient){
      super();
  }

  getCategories():Observable<any>{
    let headers = new HttpHeaders({ 'Authorization' : 'Bearer W3H3TKGWBFQIKJFKHQ43' });
    return this.http.get(this.BASE_URL_EVENT + 'categories/',{headers: headers}).pipe(
        catchError(this.handleError)
      );
  }

  getSubCategories():Observable<any>{
    let headers = new HttpHeaders({ 'Authorization' : 'Bearer W3H3TKGWBFQIKJFKHQ43' });
    return this.http.get(this.BASE_URL_EVENT + 'subcategories/',{headers: headers}).pipe(
        catchError(this.handleError)
      );
  }

  getMoreSubCategories(token:string):Observable<any>{
    let headers = new HttpHeaders({ 'Authorization' : 'Bearer W3H3TKGWBFQIKJFKHQ43' });
    return this.http.get(this.BASE_URL_EVENT + 'subcategories/?continuation='+token,{headers: headers}).pipe(
        catchError(this.handleError)
      );
  }

  create(event : any) : Observable<any>{
    return this.http.post(this.BASE_URL + "/event", event, { headers: this.createAuthorizationHeader() }).map(this.extractData).pipe(
      catchError(this.handleError)
    );
  }

  getConfigs():Observable<any>{
    return this.http.get(this.BASE_URL + "/get/event",{ headers: this.createAuthorizationHeader() }).pipe(
        catchError(this.handleError)
      );
  }
}
