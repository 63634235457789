import { Component, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../auth.service';
import { NotificationService} from 'app/shared/notification/notification.service';
import {Global} from "../../global";

@Component({
	selector: 'forgot-password',
	templateUrl: './forgotPassword.component.html',
	styleUrls: ['./forgotPassword.component.css']
})
export class ForgotPasswordComponent implements OnInit {
	@Output() opened: EventEmitter<boolean> = new EventEmitter<boolean>();

	sent: boolean;
	title: string;
	submitted: boolean;
	forgotForm: FormGroup;

	constructor(
		@Inject(FormBuilder) fb: FormBuilder,
		private router: Router,
		private authService: AuthService,
		private notificationService: NotificationService
	) {
		this.forgotForm = fb.group({
			email: ['', [Validators.required, Validators.pattern(Global.EMAIL_PATTERN)]]
		});
	}

	ngOnInit() {
		this.title = 'Recuperar contraseña';
		this.submitted = false;
	}

	closeDialog(): void {
		this.opened.emit(false);
	}

	sendMail(): void {
		this.submitted = true;
		let data = this.forgotForm.get('email');
		if (!data.invalid) {
			this.authService.forgotPassword(data.value, null, null)
				.subscribe(result => {
						this.notificationService.alert('Se han enviado sus credenciales de acceso a su correo electrónico');
					  this.closeDialog();
				}, (err) => {
						if(err == 'Error 404 Not Found')	this.notificationService.error('Email inválido')
            else this.notificationService.error('Intente de nuevo más tarde')
        });
		}
	}
}
