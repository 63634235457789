import {Routes} from "@angular/router/router";
import {PostComponent} from "./post.component";
import {PostListComponent} from "./post-list.component";
import {PostCreateComponent} from "./post-create.component";
import {PostReadComponent} from "./post-read.component";
import {PostUpdateComponent} from "./post-update.component";
import { PostGuardService } from './post-guard.service';

export const postRoutes: Routes = [
  {
    path: 'posts',
    component: PostComponent,
    canActivateChild: [PostGuardService],
    data: {
      breadcrumb: "Noticias",
      icon: "list"
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: PostListComponent,
        data: {
          breadcrumb: undefined
        }
      },
      {
        path:'create',
        component: PostCreateComponent,
        canActivate: [PostGuardService],
        data: {
          breadcrumb : "Crear"
        }
      },
      {
        path: ':postID',
        component: PostComponent,
        data: {
          breadcrumb: "Detalle",
          icon: "detail"
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: PostReadComponent,
            data: {
              breadcrumb: undefined,
            }
          },
          {
            path: 'update',
            component: PostUpdateComponent,
            canActivate: [PostGuardService],
            data: {
              breadcrumb: "Actualizar",
              icon: "update"
            }
          }
        ]
      }
    ]
  }
]
