import {Component} from '@angular/core';
import {Status} from "../status/status";
import {NotificationService} from "../shared/notification/notification.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Confirmation} from "../shared/confirmation-ns/confirmation-ns.service";
import {ToolBase} from "../shared/tool-ns/tool/tool-base";
import {IconTool} from "../shared/tool-ns/tool/tool-icon";
import {StatusService} from "../status/status.service";
import {Provider} from "./provider";
import {ProviderService} from "./provider.service";

@Component({
  templateUrl: './provider-read.component.html',
  styleUrls: ['provider-read.component.css']
})

export class ProviderReadComponent{

  public provider: Provider;
  public status : Status;
  toolsLeft: ToolBase<any>[] = [];

  tools: ToolBase<any>[] = [
    new IconTool({
      title: "Editar",
      icon: "update",
      clicked: this.update.bind(this)
    }),
    new IconTool({
      title: "Eliminar",
      icon: "delete",
      clicked: this.confirmationDelete.bind(this)
    })
  ];
  confirmation: Confirmation = {hiddenClose: true};


  constructor(
    private router: Router,
    private providerService : ProviderService,
    private statusService : StatusService,
    private activatedRouteService : ActivatedRoute,
    private notificationService : NotificationService
  ){
  }

  ngOnInit(){
    this.activatedRouteService.parent.params.subscribe(param => {

      this.providerService.getById(param['providerID']).subscribe(result => {
        this.provider = result;

        let stat = result.status.id;
        this.statusService.getById(stat).subscribe(status => {
          this.status = status;
        }, (err) => { this.notificationService.error('Hubo un problema cargando la información'); });

      }, (err) => { this.notificationService.error('Hubo un problema cargando la información'); })
    }, (err) => { this.notificationService.error('Hubo un problema obteniendo los datos de la URL'); });
  }

  update(){
    this.router.navigate(['./update'], {relativeTo: this.activatedRouteService});
  }

  confirmationDelete(){
    this.confirmation = {
      message: "¿Está seguro que desea eliminar el tipo de índice?",
      yesClicked: this.delete.bind(this),
      hiddenClose: false
    };
  }

  delete(this){
  if(!this.providerService.checkRoles(['provider_manager'])) {
    this.notificationService.error('Usted no cuenta con acceso a esta función. Contacte al administrador');
    return;
  }
  this.providerService.getById(this.provider.id).subscribe(provider => {
      let prov = new Provider(provider);
      prov.status.id = 2;
      this.providerService.update(prov).subscribe(any => {
        this.ngOnInit();
        this.notificationService.sucessUpdate(this.provider.name);
    }, (err) => {this.notificationService.error(err); });
    }, (err) => { this.notificationService.error(err); });
  }
}
