import {Component} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {NotificationService} from "../shared/notification/notification.service";
import { Location } from '@angular/common';
import {ActivatedRoute} from "@angular/router";
import {User} from "./user";
import {UserService} from "./user.service";
import {BaseService} from "../shared/services/base.service";
import {GroupService} from "../group/group.service";

@Component({
  templateUrl: './user.component.html',
  styleUrls: ['user.component.css']
})

export class UserUpdateComponent{

  public title: string = 'Actualizar usuario';
  public user: User;

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {
    singleSelection: false,
    text:"Seleccionar grupos",
    enableSearchFilter: true,
    enableCheckAll: false,
    classes:"myclass custom-class"};

  userForm = this.fb.group({
    name: ['', [Validators.required, Validators.maxLength(125)]],
    lastName: ['', [Validators.required, Validators.maxLength(125)]]
  });

  constructor(
    private fb: FormBuilder,
    private location: Location,
    private userService : UserService,
    private groupService : GroupService,
    private activatedRouteService : ActivatedRoute,
    private notificationService : NotificationService
  ){

  }

  ngOnInit(){
    this.activatedRouteService.parent.params.subscribe(param => {

      this.userService.getById(param['userID']).subscribe(result => {
        this.user = new User(result);
        this.userForm.controls['name'].setValue(this.user.name);
        this.userForm.controls['lastName'].setValue(this.user.lastName);

        this.groupService.getAll({type: 'webadmin'}).subscribe(params => {
            for(let g of params['result']){
              let item = [];
              item['id'] = g.id;
              item['itemName'] = g.id;
              this.dropdownList.push(item);
            }

            let param = [];
            param['email'] = this.user.authUser.email;
            this.groupService.getAll(param).subscribe(res => {
              for(let g of res['result']){
                let item = [];
                item['id'] = g.id;
                item['itemName'] = g.id;
                this.selectedItems.push(item);
              }
          }, (err) => {
            this.notificationService.error('Hubo un problema cargando la información') }); },
        (err) => {
          this.notificationService.error('Hubo un problema cargando la información')});
      }, (err) => {
        this.notificationService.error('Hubo un problema cargando la información');
      })
    }, (err) => {
      this.notificationService.error('Hubo un problema obteniendo los datos de la URL');
    });
  }

  onlyNumberKey(event){
    if (event.charCode == 46) return true;
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  action($event){
    if(this.userForm.valid) {
      let obj = BaseService.builderObject(this.userForm.value);
      obj['id'] = this.user.id;
      obj['authUser'] = {};
      obj['authUser']['groups'] = [];
      for(let item of this.selectedItems){
        let group = {};
        group['id'] = item.id;
        obj['authUser']['groups'].push(group);
      }
      this.user = new User(obj);
      this.userService.update(this.user).subscribe(response => {
        this.notificationService.sucessUpdate(this.user.name);
        this.location.back();
      }, (err) => { this.notificationService.error('Error al actualizar el usuario') })
    }
    else{
      this.notificationService.error('Verifique los datos del formulario');
    }
  }
}

