import {Routes} from "@angular/router/router";
import {EventComponent} from "./event.component";


export const eventRoutes: Routes = [
  {
    path: 'event',
    component: EventComponent,
    data: {
      breadcrumb: "Eventos",
      icon: "list"
    }
  }
]
