import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {catchError} from "rxjs/operators";
import {BaseService} from "../../shared/services/base.service";
import {AuthorizationRequest} from "./oauth2/authorizationRequest";
import {AccessTokenResponse} from "./oauth2/accessTokenResponse";

@Injectable()
export class AuthService extends BaseService{
	BASE_URL: string = this.HOST + '/users';
  private headers: HttpHeaders;

	constructor(private http: HttpClient) {
    super();
    this.headers = new HttpHeaders({'Content-Type' : 'application/x-www-form-urlencoded'});
  }

	login(authorizationRequest : AuthorizationRequest): Observable<AccessTokenResponse> {
    let body = new HttpParams().set('grant_type', authorizationRequest.grant_type)
      .set('username', authorizationRequest.username)
      .set('password', authorizationRequest.password)
      .set('client_id', authorizationRequest.client_id)
    return this.http.post<AccessTokenResponse>(this.HOST + '/oauth/token',  body.toString(), { headers: this.headers }).
    pipe(
      catchError(this.handleError));
		}

	logout() {
		localStorage.removeItem('panabolsawebadmin');
    sessionStorage.clear();
	}

	forgotPassword(email: string, newPassword: string, pin: string): Observable<Object> {
    return this.http.post<Object>(this.BASE_URL + '/recoverpassword',  {"email": email, "newPassword": newPassword, "pin": pin, "web": true})
      .pipe(
      catchError(this.handleError));
	}

  resetPassword(currentPassword: string, newPassword: string): Observable<Object> {
    return this.http.post<Object>(this.BASE_URL + '/resetpassword',  {"newPassword": newPassword, "currentPassword": currentPassword},
      { headers: this.createAuthorizationHeader() })
      .pipe(
        catchError(this.handleError));
  }
}
