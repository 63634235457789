import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {SharedModule} from "../shared/shared.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {homeRouting} from "../home/home.routes";
import {HttpClientModule} from "@angular/common/http";
import {StatusService} from "../status/status.service";
import {StockIndiceService} from "./stockindice.service";
import {StockIndiceComponent} from "./stockindice.component";
import {IndiceTypeService} from "../indice-type/indicetype.service";
import {StockIndiceListComponent} from "./stockindice-list.component";
import {StockIndiceCreateComponent} from "./stockindice-create.component";
import {StockIndiceUpdateComponent} from "./stockindice-update.component";
import {StockIndiceReadComponent} from "./stockindice-read.component";
import { StockIndiceGuardService } from "./stockindice-guard.service";
@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    homeRouting,
    ReactiveFormsModule,
    HttpClientModule
  ],
  declarations:[
    StockIndiceComponent,
    StockIndiceListComponent,
    StockIndiceCreateComponent,
    StockIndiceUpdateComponent,
    StockIndiceReadComponent
  ],
  providers:[
    StockIndiceService,
    IndiceTypeService,
    StatusService,
    StockIndiceGuardService
  ]
})

export class StockIndiceModule { }
