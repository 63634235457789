import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {SharedModule} from "../shared/shared.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {homeRouting} from "../home/home.routes";
import {HttpClientModule} from "@angular/common/http";
import {UserService} from "./user.service";
import {UserComponent} from "./user.component";
import {UserListComponent} from "./user-list.component";
import {UserCreateComponent} from "./user-create.component";
import {UserUpdateComponent} from "./user-update.component";
import {UserReadComponent} from "./user-read.component";
import {AngularMultiSelectModule} from "../utils/angular2-multiselect-checkbox-dropdown/src/app/angular2-multiselect-dropdown/multiselect.component";
import {GroupService} from "../group/group.service";
import { UserGuardService } from "./user-guard.service";

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    homeRouting,
    AngularMultiSelectModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  declarations:[
    UserComponent,
    UserListComponent,
    UserCreateComponent,
    UserUpdateComponent,
    UserReadComponent
  ],
  providers:[
    UserService,
    GroupService,
    UserGuardService
  ]
})

export class UserModule { }
