import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from "@angular/core";
import {CommonModule} from "@angular/common";
import {SharedModule} from "../shared/shared.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {homeRouting} from "../home/home.routes";
import {HttpClientModule} from "@angular/common/http";
import {StatusService} from "../status/status.service";
import {ProviderService} from "../provider/provider.service";
import {PostService} from "./post.service";
import {PostComponent} from "./post.component";
import {PostListComponent} from "./post-list.component";
import {PostCreateComponent} from "./post-create.component";
import {PostUpdateComponent} from "./post-update.component";
import {PostReadComponent} from "./post-read.component";
import {FroalaEditorModule, FroalaViewModule} from "angular-froala-wysiwyg/index";
import {SafeHtmlPipe} from "./utils/safehtmlpipe";
import {ImageUploadModule} from "angular2-image-upload/index";
import { PostGuardService } from "./post-guard.service";

@NgModule({
  imports: [
    CommonModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    ImageUploadModule.forRoot(),
    SharedModule,
    FormsModule,
    homeRouting,
    ReactiveFormsModule,
    HttpClientModule
  ],
  declarations:[
    PostComponent,
    PostListComponent,
    PostCreateComponent,
    PostUpdateComponent,
    PostReadComponent,
    SafeHtmlPipe
  ],
  providers:[
    ProviderService,
    PostService,
    StatusService,
    PostGuardService
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})

export class PostModule { }
