import {Component} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {BaseService} from "../shared/services/base.service";
import {Status} from "../status/status";
import {StatusService} from "../status/status.service";
import {NotificationService} from "../shared/notification/notification.service";
import { Location } from '@angular/common';
import {StockIndice} from "./stockindice";
import {Global} from "../utils/global";
import {StockIndiceService} from "./stockindice.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  templateUrl: './stockindice.component.html',
    styleUrls: ['stockindice.component.css']
})

export class StockIndiceCreateComponent{

  public title: string = 'Crear índice de mercado';
  public stockIndice: StockIndice;
  public selectedShowMainHome : boolean = true;
  public variation: number = 3;
  public status : Status[];
  public selectedStatus : Status;

  stockIndiceForm = this.fb.group({
    name: ['', [Validators.required, Validators.maxLength(125)]],
    type: [''],
    currentValue: ['', [Validators.required, Validators.maxLength(125)]],
    value: ['', [Validators.required, Validators.pattern("^[0-9\-]{1,5}([,.][0-9]{1,6})?$")]],
    changeValue: ['', [Validators.required, Validators.pattern("^[0-9\-]{1,5}([,.][0-9]{1,6})?$")]]
  });

  constructor(
    private fb: FormBuilder,
    private location: Location,
    private statusService : StatusService,
    private stockIndiceService : StockIndiceService,
    private activatedRouteService : ActivatedRoute,
    private notificationService : NotificationService
  ){}

  //Metodo que permite validar que solo se introduzcan digitos
  onlyNumberKey(event){
    if (event.charCode == 45) return true;
    if (event.charCode == 46) return true;
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  ngOnInit(){
    this.statusService.getAll({type: 'stockindice'}).subscribe(response => {
      this.status = <Status[]> response['result'];
      this.selectedStatus = this.status[0];
    }, (err) => { this.notificationService.error('Hubo un problema cargando la información'); });
  }

  action($event){
    this.stockIndice = <StockIndice> BaseService.builderObject(this.stockIndiceForm.value);

    if(this.stockIndiceForm.valid) {
      this.stockIndice.showMainHome = this.selectedShowMainHome;
      this.stockIndice.variation = this.variation;

      this.activatedRouteService.parent.parent.params.subscribe(param => {

        let obj = <Object> this.stockIndice;
        obj['indiceType'] = {'id' : param['indicetypeID'] }
        obj['status'] = {'id' : this.selectedStatus.id };

        this.stockIndiceService.create(<StockIndice> obj).subscribe(response => {
          this.notificationService.sucessInsert(this.stockIndice.name);
          this.location.back();
        }, (err) => { this.notificationService.error('Error al crear el índice de mercado') });

      }, (err) => { this.notificationService.error('Hubo un problema obteniendo los datos de la URL') });
    }
    else{
      this.notificationService.error('Verifique los datos del formulario');
    }
  }
}
