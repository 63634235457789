import {Component, ViewChild} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {BaseService} from "../shared/services/base.service";
import {NotificationService} from "../shared/notification/notification.service";
import { Location } from '@angular/common';
import {Group} from "./group";
import {GroupService} from "./group.service";
import {ActivatedRoute} from "@angular/router";
import {TableColumn} from "../shared/table-ns/table-ns-column";
import {TableService} from "../shared/table-ns/table-ns.service";
import {Role} from "../role/role";
import {RoleService} from "../role/role.service";
import {FilterService} from "../shared/filter-ns/filter-ns.service";
import {QuestionFilterTextbox} from "../shared/filter-ns/question-filter/question-filter-textbox";
import {QuestionFilterBase} from "../shared/filter-ns/question-filter/question-filter-base";

@Component({
  templateUrl: './group.component.html',
    styleUrls: ['group.component.css']
})

export class GroupCreateComponent{

  public title: string = 'Crear grupo';
  public group: Group;
  public selectedStatus : boolean = true;
  public tableTitle = 'Roles';
  public actions2: any[] = [];
  public actions: undefined;
  readonly = false;
  public items: Object[];
  @ViewChild('tableCmp') tableCmp;
  pager: any;
  questionFilters: QuestionFilterBase<any>[] = [];

  cols: TableColumn[] = [
    new TableColumn({name: 'Identificador', key: 'id', proportion: 6}),
    new TableColumn({name: 'Descripción', key: 'description', proportion: 14})
  ];

  groupForm = this.fb.group({
    id: ['', [Validators.required, Validators.maxLength(125), Validators.pattern('[0-9a-zA-Z_.-]*')]],
    description: ['', [Validators.maxLength(190)]]
  });

  constructor(
    private fb: FormBuilder,
    private location: Location,
    private tableService: TableService,
    private filterService : FilterService,
    private groupService : GroupService,
    private roleService : RoleService,
    private activatedRouteService : ActivatedRoute,
    private notificationService : NotificationService
  ){}

  ngOnInit(){

    //Numero de roles máximos por pagina del listado
    this.tableService.pager.pageSize = 15;

    this.tableService.setSort(this.cols[0].key);
    this.filter();
    this.list(this.tableService.pager.pageIndex);
  }

  read($event){

  }

  filter(){
    let questionFilterDescription =
      new QuestionFilterTextbox({
        key: 'description',
        label: 'Descripción',
        value: this.filterService.filter['description']!=undefined? this.filterService.filter['description']: '',
        optionsKey: 'description',
        proportion: 2
      });
    this.questionFilters = [
      questionFilterDescription
    ];
  }

  list(page?: number) {

    this.filterService.filter['type'] = "external";

    this.roleService.getAll(this.groupService.buildRequestOptionsFinder(this.tableService.sort, undefined, this.filterService.filter, {pageIndex: page, pageSize: this.tableService.pager.pageSize})).subscribe(response => {
      this.items = response['result'];
      this.pager = response['result']['pager'];
      this.tableService.pager.pageIndex = page;
      //this.tableCmp.deselectAll();

    }, (err) => {
      this.notificationService.error("Error al cargar la información");
    });
  }

  action($event){
    this.group = <Group> BaseService.builderObject(this.groupForm.value);

    if(this.groupForm.valid) {
      this.group.active = this.selectedStatus;
      this.group.roles = [];

      for(let it of this.tableService.getSelectKeys()){
        let rol = new Role();
        rol.id = it;
        this.group.roles.push(rol);
      }

      this.groupService.create(this.group).subscribe(response => {
        this.notificationService.sucessInsert(this.group.id);
        this.filterService.filter = {};
        this.location.back();
      }, (err) => {
        if(err == 'Error 409 Conflict') this.notificationService.error('Identificador del grupo ya se encuentra en uso');
        else this.notificationService.error('Error al crear el grupo')});
    }
    else{
      this.notificationService.error('Verifique los datos del formulario');
    }
  }
}
