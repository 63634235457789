import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { homeRouting } from './home.routes';
import { SharedModule } from '../shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule }  from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import {BreadcrumbsComponent} from "../utils/breadcrumbs/breadcrumbs.component";
import {UsertoolComponent} from "../utils/usertool/usertool.component";
import {MenuComponent} from "../utils/menu/menu.component";
import {MenuItemComponent} from "../utils/menu/menu-item/menu-item.component";
import {IndiceTypeModule} from "../indice-type/indicetype.module";
import {StockIndiceModule} from "../stock-indice/stockindice.module";
import {ProviderModule} from "../provider/provider.module";
import {PostModule} from "../post/post.module";
import {InstrumentModule} from "../instrument/instrument.module";
import {UserModule} from "../user/user.module";
import {ClientModule} from "../client/client.module";
import {GroupModule} from "../group/group.module";
import {LoadingPageModule, SlidingBarModule} from "angular-loading-page";
import {BannerModule} from "../banner/banner.module";
import {SeasonModule} from "../season/season.module";
import {ConfigModule} from "../config/config.module";
import { EventModule } from '../event/event.module';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    LoadingPageModule,
    SlidingBarModule,
    IndiceTypeModule,
    StockIndiceModule,
    ProviderModule,
    PostModule,
    EventModule,
    InstrumentModule,
    UserModule,
    ClientModule,
    GroupModule,
    BannerModule,
    ConfigModule,
    SeasonModule,
    homeRouting
  ],
  declarations: [
    BreadcrumbsComponent,
  	UsertoolComponent,
  	MenuComponent,
    MenuItemComponent,
  	HomeComponent
  ]
})
export class HomeModule { }
