import {Component} from '@angular/core';
import {IndiceType} from "./indicetype";
import {Status} from "../status/status";
import {NotificationService} from "../shared/notification/notification.service";
import {IndiceTypeService} from "./indicetype.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Confirmation} from "../shared/confirmation-ns/confirmation-ns.service";
import {ToolBase} from "../shared/tool-ns/tool/tool-base";
import {IconTool} from "../shared/tool-ns/tool/tool-icon";
import {StatusService} from "../status/status.service";

@Component({
  templateUrl: './indicetype-read.component.html',
  styleUrls: ['indicetype-read.component.css']
})

export class IndiceTypeReadComponent{

  public indiceType: IndiceType;
  public status : Status;
  toolsLeft: ToolBase<any>[] = [];

  tools: ToolBase<any>[] = [
    new IconTool({
      title: "Editar",
      icon: "update",
      clicked: this.update.bind(this)
    }),
    new IconTool({
      title: "Eliminar",
      icon: "delete",
      clicked: this.confirmationDelete.bind(this)
    })
  ];
  confirmation: Confirmation = {hiddenClose: true};


  constructor(
    private router: Router,
    private indicetypeService : IndiceTypeService,
    private statusService : StatusService,
    private activatedRouteService : ActivatedRoute,
    private notificationService : NotificationService
  ){
  }

  ngOnInit(){
    this.activatedRouteService.parent.params.subscribe(param => {

      this.indicetypeService.getById(param['indicetypeID']).subscribe(result => {
        this.indiceType = result;

        let stat = result.status.id;
        this.statusService.getById(stat).subscribe(status => {
          this.status = status;
        }, (err) => { this.notificationService.error('Hubo un problema cargando la información'); });

      }, (err) => { this.notificationService.error('Hubo un problema cargando la información'); })
    }, (err) => { this.notificationService.error('Hubo un problema obteniendo los datos de la URL'); });
  }

  update(){
    this.router.navigate(['./update'], {relativeTo: this.activatedRouteService});
  }

  confirmationDelete(){
    this.confirmation = {
      message: "¿Está seguro que desea eliminar el tipo de índice?",
      yesClicked: this.delete.bind(this),
      hiddenClose: false
    };
  }

  delete(this){
  if(!this.indicetypeService.checkRoles(['indicetype_manager'])) {
    this.notificationService.error('Usted no cuenta con acceso a esta función. Contacte al administrador');
    return;
  }
  this.indicetypeService.getById(this.indiceType.id).subscribe(indicetype => {
      let type = new IndiceType(indicetype);
      type.status.id = 2;
      this.indicetypeService.update(type).subscribe(any => {
        this.ngOnInit();
        this.notificationService.sucessUpdate(this.indiceType.name);
    }, (err) => {this.notificationService.error(err); });
    }, (err) => { this.notificationService.error(err); });
  }
}
