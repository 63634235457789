<h3>{{title}}</h3>
<br>
<div class="container">
  <form [formGroup]="postForm" name="postForm" (ngSubmit)="action($event)" novalidate>
    <div class="form-group">
        <label for="title">Título</label>
        <input type="text" maxlength="255" formControlName="title" class="form-control" placeholder="Ingrese título de la noticia" required><br>
        <div *ngIf="!postForm.controls.title.valid" class="help-block">Título de la noticia es invalido</div>
    </div>
    <div class="form-group">
        <label for="description">Descripción</label>
        <textarea formControlName="description" maxlength="255" class="form-control" placeholder="Ingrese la descripción de la noticia"></textarea>
        <div *ngIf="!postForm.controls.description.valid" class="help-block">Descripción de la noticia es invalida</div>
    </div>
    <div class="form-group">
      <label for="url">Imagen principal de la noticia</label>
      <image-upload #imageUpload
                    url="{{ urlImageUploader }}"
                    [headers]="headersImageUploader"
                    [max]="1" [extensions]="['jpeg','png']"
                    [buttonCaption]="'Seleccionar imagen'"
                    [dropBoxMessage]="'Suelta tu imagen aquí'"
                    (uploadFinished)="onUploadFinished($event)">
      </image-upload>
    </div>
    <div class="form-group">
      <label for="content">Contenido</label><br>
      <textarea formControlName="content" [froalaEditor]="options"></textarea>
      <div *ngIf="!postForm.controls.content.valid" class="help-block">Contenido de la noticia es invalido</div>
    </div>
    <div class="form-group">
      <label for="source">Fuente</label>
      <input type="text" maxlength="255" formControlName="source" class="form-control" placeholder="Ingrese fuente de la noticia" required><br>
      <div *ngIf="!postForm.controls.source.valid" class="help-block">Fuente de la noticia es invalida</div>
    </div>
    <div class="form-group">
      <label for="url">URL principal de la noticia</label>
      <input type="url" formControlName="url" class="form-control" placeholder="Ingrese URL principal de la noticia" required><br>
      <div *ngIf="!postForm.controls.url.valid" class="help-block">URL principal de la noticia es invalida. Ejemplo: http://www.panabolsa.com/news</div>
    </div>
    <div class="form-group">
      <label for="status">Estado</label>
      <select  [(ngModel)]="selectedStatus" [ngModelOptions]="{standalone: true}" required>
        <option *ngFor="let stat of status" [ngValue]="stat">{{stat.description}}</option>
      </select>
    </div>
    <br><br>
    <div class="form-group">
      <bt-text
        [title]="'Aceptar'"
        [type]="'submit'"
        [text]="'Guardar'"
        (clicked)="'null'">
      </bt-text>
    </div>
  </form>
</div>
