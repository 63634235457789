import {Component} from '@angular/core';
import {IndiceType} from "./indicetype";
import {FormBuilder, Validators} from "@angular/forms";
import {Status} from "../status/status";
import {StatusService} from "../status/status.service";
import {NotificationService} from "../shared/notification/notification.service";
import {IndiceTypeService} from "./indicetype.service";
import { Location } from '@angular/common';
import {ActivatedRoute} from "@angular/router";

@Component({
  templateUrl: './indicetype.component.html',
  styleUrls: ['indicetype.component.css']
})

export class IndiceTypeUpdateComponent{

  public title: string = 'Actualizar tipo de índice';
  public indiceType: IndiceType;
  public selectedShowIndiceHome : boolean;
  public status : Status[];
  public selectedStatus : Status;

  indiceTypeForm = this.fb.group({
    name: ['', [Validators.required, Validators.maxLength(125)]],
    description: ['', [Validators.required, Validators.maxLength(255)]]
  });

  constructor(
    private fb: FormBuilder,
    private location: Location,
    private statusService : StatusService,
    private indicetypeService : IndiceTypeService,
    private activatedRouteService : ActivatedRoute,
    private notificationService : NotificationService
  ){

  }

  ngOnInit(){
    this.statusService.getAll({type: 'indicetype'}).subscribe(response => {
      this.status = <Status[]> response['result'];

      this.activatedRouteService.parent.params.subscribe(param => {

        this.indicetypeService.getById(param['indicetypeID']).subscribe(result => {
          this.indiceType = new IndiceType(result);
          this.indiceTypeForm.controls['name'].setValue(this.indiceType.name);
          this.indiceTypeForm.controls['description'].setValue(this.indiceType.description);
          this.selectedShowIndiceHome = this.indiceType.showIndiceHome;

          let id = result.status.id;
          for(let stat of this.status){
            if(stat.id == id){
              this.selectedStatus = stat;
            }
          }

        }, (err) => {
          this.notificationService.error('Hubo un problema cargando la información');
        })
      }, (err) => {
        this.notificationService.error('Hubo un problema obteniendo los datos de la URL');
      });
    }, (err) => { this.notificationService.error('Hubo un problema cargando la información'); });
  }

  action($event){
    if(this.indiceTypeForm.valid) {
      this.indiceType.name = this.indiceTypeForm.controls['name'].value;
      this.indiceType.description = this.indiceTypeForm.controls['description'].value;
      this.indiceType.showIndiceHome = this.selectedShowIndiceHome;

      let obj = <Object> this.indiceType;
      obj['status'] = {'id' : this.selectedStatus.id };
      this.indicetypeService.update(<IndiceType> obj).subscribe(response => {
        this.notificationService.sucessUpdate(this.indiceType.name);
        this.location.back();
      }, (err) => { this.notificationService.error('Error al actualizar el tipo de índice') })
    }
    else{
      this.notificationService.error('Verifique los datos del formulario');
    }
  }
}

