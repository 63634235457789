import {Routes} from "@angular/router/router";
import {StockIndiceComponent} from "./stockindice.component";
import {StockIndiceListComponent} from "./stockindice-list.component";
import {StockIndiceCreateComponent} from "./stockindice-create.component";
import {StockIndiceReadComponent} from "./stockindice-read.component";
import {StockIndiceUpdateComponent} from "./stockindice-update.component";
import {StockIndiceGuardService} from "./stockindice-guard.service";

export const stockindiceRoutes: Routes = [
  {
    path: 'stockindices',
    component: StockIndiceComponent,
    canActivateChild: [StockIndiceGuardService],
    data: {
      breadcrumb: "Índices",
      icon: "list"
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: StockIndiceListComponent,
        data: {
          breadcrumb: undefined
        }
      },
      {
        path:'create',
        component: StockIndiceCreateComponent,
        canActivate: [StockIndiceGuardService],
        data: {
          breadcrumb : "Crear"
        }
      },
      {
        path: ':stockindiceID',
        component: StockIndiceComponent,
        data: {
          breadcrumb: "Detalle",
          icon: "detail"
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: StockIndiceReadComponent,
            data: {
              breadcrumb: undefined,
            }
          },
          {
            path: 'update',
            component: StockIndiceUpdateComponent,
            canActivate: [StockIndiceGuardService],
            data: {
              breadcrumb: "Actualizar",
              icon: "update"
            }
          }
        ]
      }
    ]
  }
]
