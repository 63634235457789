import {Component} from '@angular/core';
import {Status} from "../status/status";
import {NotificationService} from "../shared/notification/notification.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Confirmation} from "../shared/confirmation-ns/confirmation-ns.service";
import {ToolBase} from "../shared/tool-ns/tool/tool-base";
import {IconTool} from "../shared/tool-ns/tool/tool-icon";
import {StatusService} from "../status/status.service";
import {Post} from "./post";
import {PostService} from "./post.service";

@Component({
  templateUrl: './post-read.component.html',
  styleUrls: ['post-read.component.css']
})

export class PostReadComponent{

  public post: Post;
  public status: Status;
  toolsLeft: ToolBase<any>[] = [];

  tools: ToolBase<any>[] = [
    new IconTool({
      title: "Editar",
      icon: "update",
      clicked: this.update.bind(this)
    }),
    new IconTool({
      title: "Eliminar",
      icon: "delete",
      clicked: this.confirmationDelete.bind(this)
    })
  ];
  confirmation: Confirmation = {hiddenClose: true};


  constructor(
    private router: Router,
    private postService : PostService,
    private statusService : StatusService,
    private activatedRouteService : ActivatedRoute,
    private notificationService : NotificationService
  ){
  }

  ngOnInit(){
    this.activatedRouteService.parent.params.subscribe(param => {

      this.postService.getById(param['postID']).subscribe(result => {
        this.post = result;

        this.activatedRouteService.parent.parent.parent.params.subscribe(param => {

          if(this.post.provider.id != param['providerID']){
            this.notificationService.genericerror('URL no válida', '');
            this.router.navigateByUrl('/home');
            return;
          }

        let stat = result.status.id;
        this.statusService.getById(stat).subscribe(status => {
          this.status = status;

        }, (err) => { this.notificationService.error('Hubo un problema obteniendo los datos de la URL'); });
        }, (err) => { this.notificationService.error('Hubo un problema cargando la información'); });
      }, (err) => { this.notificationService.error('Hubo un problema cargando la información'); })
    }, (err) => { this.notificationService.error('Hubo un problema obteniendo los datos de la URL'); });
  }

  update(){
    this.router.navigate(['./update'], {relativeTo: this.activatedRouteService});
  }

  confirmationDelete(){
    this.confirmation = {
      message: "¿Está seguro que desea eliminar la noticia?",
      yesClicked: this.delete.bind(this),
      hiddenClose: false
    };
  }

  delete(this){
    if(!this.postService.checkRoles(['post_manager'])) {
      this.notificationService.error('Usted no cuenta con acceso a esta función. Contacte al administrador');
      return;
    }
  this.postService.getById(this.post.id).subscribe(post => {
      let p = new Post(post);
      p.status.id = 2;
      this.postService.update(p).subscribe(any => {
        this.ngOnInit();
        this.notificationService.sucessUpdate(this.post.title);
    }, (err) => {this.notificationService.error(err); });
    }, (err) => { this.notificationService.error(err); });
  }
}
