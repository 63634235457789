import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {SharedModule} from "../shared/shared.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {homeRouting} from "../home/home.routes";
import {HttpClientModule} from "@angular/common/http";
import {ConfigComponent} from "./config.component";
import {ConfigService} from "../config/config.service";
import {ConfigGuardService} from "./config-guard.service";
import {ConfigUpdateComponent} from "./config-update.component";

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    homeRouting,
    ReactiveFormsModule,
    HttpClientModule
  ],
  declarations:[
    ConfigComponent,
    ConfigUpdateComponent
  ],
  providers:[
    ConfigService,
    ConfigGuardService
  ]
})

export class ConfigModule { }
