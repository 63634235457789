import {Component} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {BaseService} from "../shared/services/base.service";
import {Status} from "../status/status";
import {StatusService} from "../status/status.service";
import {NotificationService} from "../shared/notification/notification.service";
import { Location } from '@angular/common';
import {Provider} from "./provider";
import {ProviderService} from "./provider.service";

@Component({
  templateUrl: './provider.component.html',
    styleUrls: ['provider.component.css']
})

export class ProviderCreateComponent{

  public title: string = 'Crear proveedor';
  public provider: Provider;
  public status : Status[];
  public selectedStatus : Status;

  providerForm = this.fb.group({
    name: ['', [Validators.required, Validators.maxLength(125)]],
    url: ['', [Validators.pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?"))]],
    type: ['', [Validators.maxLength(125)]]
  });

  constructor(
    private fb: FormBuilder,
    private location: Location,
    private statusService : StatusService,
    private providerService : ProviderService,
    private notificationService : NotificationService
  ){}

  ngOnInit(){
    this.statusService.getAll({type: 'provider'}).subscribe(response => {
      this.status = <Status[]> response['result'];
      this.selectedStatus = this.status[0];
    }, (err) => { this.notificationService.error('Hubo un problema cargando la información'); });
  }

  action($event){
    this.provider = <Provider> BaseService.builderObject(this.providerForm.value);

    if(this.providerForm.valid) {
      let obj = <Object> this.provider;
      obj['status'] = {'id' : this.selectedStatus.id }
      this.providerService.create(<Provider> obj).subscribe(response => {
        this.notificationService.sucessInsert(this.provider.name);
        this.location.back();
      }, (err) => { this.notificationService.error('Error al crear el proveedor') })
    }
    else{
      this.notificationService.error('Verifique los datos del formulario');
    }
  }
}
