import {Component, ViewChild} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {Status} from "../status/status";
import {StatusService} from "../status/status.service";
import {NotificationService} from "../shared/notification/notification.service";
import { Location } from '@angular/common';
import {ActivatedRoute, Router} from "@angular/router";
import {PostService} from "./post.service";
import {Post} from "./post";
import {FileHolder} from "angular2-image-upload/index";

@Component({
  templateUrl: './post.component.html',
  styleUrls: ['post.component.css']
})

export class PostUpdateComponent{

  @ViewChild('imageUpload') imageUpload : any;

  public title: string = 'Actualizar la noticia';
  public post: Post;
  public mainImageUrl : string = '';
  public status : Status[];
  public selectedStatus : Status;

  // ImgUr API for image upload https://apidocs.imgur.com
  public urlImageUploader : string = 'https://api.imgur.com/3/image';

  // Client secret 1f7b59c62b6605ef583ad96bbbb9ae32e8ff906e
  public headersImageUploader : Object = {
    'Authorization': 'Client-ID 2d2d8cbba514f15'
  };

  public options: Object = {
    placeholderText: 'Contenido de la noticia',
    language: 'es',
    fileUpload: false,
    imageUploadParam: 'image',
    imageUploadURL: this.urlImageUploader,
    requestHeaders: this.headersImageUploader,
    videoUpload: false,
    events: {
      'froalaEditor.image.uploaded' : function(e, editor, response) {
        let link = JSON.parse(response).data.link;
        editor.image.insert(link, false, null, editor.image.get(), response);

        return false;
      }
    }
  }

  postForm = this.fb.group({
    title: ['', [Validators.required]],
    description: ['', [Validators.required]],
    source: [''],
    content: ['', [Validators.required]],
    url: ['', [Validators.pattern("^(https?|ftp|file)://[-a-zA-Z0-9+&@#/%?=~_|!:,.;]*[-a-zA-Z0-9+&@#/%=~_|]")]]
   });

  constructor(
    private fb: FormBuilder,
    private location: Location,
    private router: Router,
    private statusService : StatusService,
    private postService : PostService,
    private activatedRouteService : ActivatedRoute,
    private notificationService : NotificationService
  ){

  }

  onUploadFinished(file : FileHolder){
    if(file.serverResponse.status == 200){
      let response = JSON.parse(file.serverResponse.response._body);
      this.mainImageUrl = response.data.link;
    }
    else{
      this.notificationService.error('Error al subir imagen');
    }
  }

  ngOnInit(){
    this.statusService.getAll({type: 'post'}).subscribe(response => {
      this.status = <Status[]> response['result'];

      this.activatedRouteService.parent.params.subscribe(param => {

        this.postService.getById(param['postID']).subscribe(result => {
          this.post = new Post(result);
          this.postForm.controls['title'].setValue(this.post.title);
          this.postForm.controls['description'].setValue(this.post.description);
          this.postForm.controls['content'].setValue(this.post.content);
          this.postForm.controls['source'].setValue(this.post.source);
          this.postForm.controls['url'].setValue(this.post.url);
          this.mainImageUrl = this.post.mainImageUrl;

          this.activatedRouteService.parent.parent.parent.params.subscribe(param => {

          if(this.post.provider.id != param['providerID']){
            this.notificationService.genericerror('URL no válida', '');
            this.router.navigateByUrl('/home');
            return;
          }

          let id = result.status.id;
          for(let stat of this.status){
            if(stat.id == id){
              this.selectedStatus = stat;
            }
          }
          }, (err) => {
            this.notificationService.error('Hubo un problema obteniendo los datos de la URL') });
        }, (err) => {
          this.notificationService.error('Hubo un problema cargando la información');
        })
      }, (err) => {
        this.notificationService.error('Hubo un problema obteniendo los datos de la URL');
      });
    }, (err) => { this.notificationService.error('Hubo un problema cargando la información'); });
  }

  action($event){
    if(this.postForm.valid) {

      this.post.title = this.postForm.controls['title'].value;
      this.post.description = this.postForm.controls['description'].value;
      this.post.source = this.postForm.controls['source'].value;
      this.post.content = this.postForm.controls['content'].value;
      this.post.url = this.postForm.controls['url'].value;

      if(this.imageUpload.files.length != 0)
        this.post.mainImageUrl = this.mainImageUrl;

      let obj = <Object> this.post;
      obj['provider'] = {'id' : this.post.provider.id };
      obj['status'] = {'id' : this.selectedStatus.id };
      this.postService.update(<Post> obj).subscribe(response => {
        this.notificationService.sucessUpdate(this.post.title);
        this.location.back();
      }, (err) => { this.notificationService.error('Error al actualizar la noticia') })
    }
    else{
      this.notificationService.error('Verifique los datos del formulario');
    }
  }
}

