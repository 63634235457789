import {Component, Input, Output, EventEmitter, ViewChild, ViewEncapsulation} from '@angular/core';
import { TableService } from '../table-ns.service';
import { TableColumn } from '../table-ns-column';
import {ContextMenuService} from "ngx-contextmenu/lib/contextMenu.service";

@Component({
  encapsulation: ViewEncapsulation.None,
	selector: 'table-ns-menu',
  	templateUrl: './table-ns-menu.component.html',
  	styleUrls: ['../table-ns.component.css'],
    providers: [ContextMenuService]
})

export class TableMenuComponent {
	@Input() title: string;
	@Input() items: any[];
	@Input() cols: TableColumn[];
	@Input() actions2: any[];
	@Input() actions: any[];
	@Output() read = new EventEmitter();
	@Output() list = new EventEmitter();

	constructor(
		private contextMenuService: ContextMenuService,
		private tableService: TableService
	){}

	selectToogle(item){
		this.tableService.toogleSelect(item);
	}

	selectOne(item){
		this.tableService.emptySelects();
		this.tableService.addSelect(item);
	}

	selectAllToogle($event){
		if($event.target.checked)
			this.tableService.addSelects(this.items);
		else
			this.tableService.emptySelects();
	}

	deselect(id: number){
		this.tableService.deleteSelect(id);
	}

	deselects(ids: number[]){
		ids.forEach(id => this.tableService.deleteSelect(id));
	}

	deselectAll(){
		this.tableService.emptySelects();
	}

	remove(id: number){
		this.deselect(id);
	}

	removes(ids: number[]){
		this.deselects(ids);
	}

	onRead(item){
		this.read.emit(item);
	}

	sortAsc(key: string){
		this.tableService.sort = key;
		this.list.emit(this.tableService.pager.pageIndex);
	}

	sortDesc(key: string){
		this.tableService.sort = '-' + key;
		this.list.emit(this.tableService.pager.pageIndex);
	}

	public onContextMenu2($event: MouseEvent, item: any): void {
		/*let e = {
			target: $event.target,
			buttons: $event.buttons,
			clientX: $event.clientX - 200, //width menucontext
			clientY: $event.clientY,
			layerX: $event.layerX,
			layerY: $event.layerY
		}*/
    	this.contextMenuService.show.next({
			anchorElement: $event.target,
      event: $event,
			item: item
	    });
      $event.preventDefault();
      $event.stopPropagation();
	    this.selectOne(item);
	}
}
