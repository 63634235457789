import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {SharedModule} from "../shared/shared.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {homeRouting} from "../home/home.routes";
import {HttpClientModule} from "@angular/common/http";
import {BannerComponent} from "./banner.component";
import {BannerUpdateComponent} from "./banner-update.component";
import {ConfigService} from "../config/config.service";
import {BannerGuardService} from "./banner-guard.service";

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    homeRouting,
    ReactiveFormsModule,
    HttpClientModule
  ],
  declarations:[
    BannerComponent,
    BannerUpdateComponent
  ],
  providers:[
    ConfigService,
    BannerGuardService
  ]
})

export class BannerModule { }
