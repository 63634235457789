import {BaseModel} from "../shared/models/base.model";
import {Status} from "../status/status";
import {Provider} from "../provider/provider";

export class Post extends BaseModel{
  title: string;
  description: string;
  content: string;
  source: string;
  date: Date;
  url: string;
  mainImageUrl: string;
  provider: Provider;
  status: Status;

  constructor(object){
    super(object);
    if(object.title != undefined)
      this.title = object.title;
    if(object.description != undefined)
      this.description = object.description;
    if(object.content != undefined)
      this.content = object.content;
    if(object.source != undefined)
      this.source = object.source;
    if(object.date != undefined)
      this.date = object.date;
    if(object.url != undefined)
      this.url = object.url;
    if(object.mainImageUrl != undefined)
      this.mainImageUrl = object.mainImageUrl;
    if(object.status != undefined)
      this.status = new Status(object.status);
    if(object.provider != undefined)
      this.provider = new Provider(object.provider);
  }
}
