
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs/Rx";
import {BaseService} from "../shared/services/base.service";
import {catchError} from "rxjs/operators";

@Injectable()
export class ConfigService extends BaseService{

  BASE_URL: string = this.HOST +'/configs';

  constructor(
    private http: HttpClient){
    super();
  }

  updateBanner(obj: any): Observable<Object> {
    return this.http.put(this.BASE_URL + '/banner', obj, {
      headers: this.createAuthorizationHeader()
    }).pipe(
      catchError(this.handleError)
    );
  }

  update(obj: any): Observable<Object> {
    return this.http.put(this.BASE_URL, obj, {
      headers: this.createAuthorizationHeader()
    }).pipe(
      catchError(this.handleError)
    );
  }

  getAll(params : any = '{ }'): Observable<Object> {
    return this.http.get(this.BASE_URL, {
      params: params,
      headers: this.createAuthorizationHeader()
    }).pipe(
      catchError(this.handleError)
    );
  }
}
