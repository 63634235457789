import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {SharedModule} from "../shared/shared.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {homeRouting} from "../home/home.routes";
import {HttpClientModule} from "@angular/common/http";
import {IndiceTypeComponent} from "./indicetype.component";
import {IndiceTypeListComponent} from "./indicetype-list.component";
import {IndiceTypeService} from "./indicetype.service";
import {StatusService} from "../status/status.service";
import {IndiceTypeReadComponent} from "./indicetype-read.component";
import {IndiceTypeCreateComponent} from "./indicetype-create.component";
import {IndiceTypeUpdateComponent} from "./indicetype-update.component";
import { IndiceTypeGuardService } from "./indicetype-guard.service";

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    homeRouting,
    ReactiveFormsModule,
    HttpClientModule
  ],
  declarations:[
    IndiceTypeComponent,
    IndiceTypeListComponent,
    IndiceTypeCreateComponent,
    IndiceTypeUpdateComponent,
    IndiceTypeReadComponent
  ],
  providers:[
    IndiceTypeService,
    StatusService,
    IndiceTypeGuardService
  ]
})

export class IndiceTypeModule { }
