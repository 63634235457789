
  <h3>Configurar evento</h3>
  <br>
  <div class="container">
    <form  (ngSubmit)="action($event)" novalidate>

      

      
      <div class="form-group">
          <label for="fechainicio">Buscar desde</label>
          <input type="date" name="since_date"  placeholder="None"  [(ngModel)]="since_date" >
      </div>

      <div class="form-group">
        <label for="fechainicio">Buscar desde</label>
        <input type="date" name="until_date"  placeholder="None"  [(ngModel)]="until_date" >
      </div>

  
      
    

      <br><br>
      <div class="form-group">
        <bt-text
          [title]="'Aceptar'"
          [type]="'submit'"
          [text]="'Guardar'"
          (clicked)="'null'">
        </bt-text>
      </div>
    </form>

<!--
    <button id="example-widget-trigger" type="button" (click)="checkout()">Comprar ticket modal</button>

    <div style="margin-top: 50px;" id="example-widget-trigger2"></div> -->

  </div>
  