<h3>Información del usuario</h3>

<tool-ns
  [tools]="tools"
  [toolsLeft]="toolsLeft">
</tool-ns>

<div *ngIf="user != undefined">
<div class="read">
    <div class="form-group">
      <div class="form-subgroup">
        <p> <label>Nombre y Apellido</label> {{ user.name + " " + user.lastName }} </p>
      </div>
    </div>
    <div class="form-group">
      <div class="form-subgroup">
        <p><label>Email</label> {{ user.authUser.email }} </p>
      </div>
    </div>
    <div class="form-group">
      <div class="form-subgroup">
        <p><label>Grupos</label></p>
      </div>
    </div>
</div>
  <div class="form-group groups" *ngFor="let group of groups">
    <div class="form-subgroup">
      <p> {{ group.id }} </p>
    </div>
    <div class="form-subgroup">
      <p> {{ group.description }} </p>
    </div>
  </div>
</div>
<confirm-ns
  [title]="confirmation.title"
  [message]="confirmation.message"
  [(hiddenClose)]="confirmation.hiddenClose"
  (yesClicked)="confirmation.yesClicked($event)">
</confirm-ns>
