import {Component} from '@angular/core';
import {Status} from "../status/status";
import {NotificationService} from "../shared/notification/notification.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Confirmation} from "../shared/confirmation-ns/confirmation-ns.service";
import {ToolBase} from "../shared/tool-ns/tool/tool-base";
import {IconTool} from "../shared/tool-ns/tool/tool-icon";
import {StatusService} from "../status/status.service";
import {Instrument} from "./instrument";
import {Class} from "../class/class";
import {Issuer} from "../issuer/issuer";
import {InstrumentService} from "./instrument.service";

@Component({
  templateUrl: './instrument-read.component.html',
  styleUrls: ['instrument-read.component.css']
})

export class InstrumentReadComponent{

  public instrument: Instrument;
  public clazz: Class;
  public issuer: Issuer;
  public status : Status;
  toolsLeft: ToolBase<any>[] = [];

  tools: ToolBase<any>[] = [
    new IconTool({
      title: "Editar",
      icon: "update",
      clicked: this.update.bind(this)
    }),
    new IconTool({
      title: "Eliminar",
      icon: "delete",
      clicked: this.confirmationDelete.bind(this)
    })
  ];
  confirmation: Confirmation = {hiddenClose: true};


  constructor(
    private router: Router,
    private instrumentService : InstrumentService,
    private statusService : StatusService,
    private activatedRouteService : ActivatedRoute,
    private notificationService : NotificationService
  ){
  }

  ngOnInit(){
    this.activatedRouteService.parent.params.subscribe(param => {

      this.instrumentService.getById(param['instrumentID']).subscribe(result => {
        this.instrument = result;
        this.clazz = <Class> result.instrumentClass;
        this.issuer = <Issuer> result.issuer;

        let stat = result.status.id;
        this.statusService.getById(stat).subscribe(status => {
          this.status = status;
        }, (err) => { this.notificationService.error('Hubo un problema cargando la información'); });

      }, (err) => { this.notificationService.error('Hubo un problema cargando la información'); })
    }, (err) => { this.notificationService.error('Hubo un problema obteniendo los datos de la URL'); });
  }

  update(){
    this.router.navigate(['./update'], {relativeTo: this.activatedRouteService});
  }

  confirmationDelete(){
    this.confirmation = {
      message: "¿Está seguro que desea eliminar el instrumento?",
      yesClicked: this.delete.bind(this),
      hiddenClose: false
    };
  }

  delete(this){
  this.instrumentService.getById(this.instrument.id).subscribe(instrument => {
      instrument.status = {"id": 2};
      instrument.issuer = {"id": instrument.issuer.id};
      instrument.instrumentClass = {"id": instrument.instrumentClass.id};
      delete instrument.createdAt;
      delete instrument.updatedAt;
      this.instrumentService.update(instrument).subscribe(any => {
        this.ngOnInit();
        this.notificationService.sucessUpdate(this.instrument.idticker);
    }, (err) => {this.notificationService.error(err); });
    }, (err) => { this.notificationService.error(err); });
  }
}
