import {Component, ViewChild} from "@angular/core";
import {TableColumn} from "../shared/table-ns/table-ns-column";
import {ToolBase} from "../shared/tool-ns/tool/tool-base";
import {Confirmation} from "../shared/confirmation-ns/confirmation-ns.service";
import {QuestionFilterBase} from "../shared/filter-ns/question-filter/question-filter-base";
import {Router, ActivatedRoute} from "@angular/router";
import {TableService} from "../shared/table-ns/table-ns.service";
import {NotificationService} from "../shared/notification/notification.service";
import {StatusService} from "../status/status.service";
import {QuestionFilterTextbox} from "../shared/filter-ns/question-filter/question-filter-textbox";
import {IconTool} from "../shared/tool-ns/tool/tool-icon";
import {FilterService} from "../shared/filter-ns/filter-ns.service";
import {SeasonService} from "./season.service";
import {Season} from "./season";

@Component({
  templateUrl: '../utils/crud/list/list.component.html',
  styleUrls: ['./season-list.component.css']
})

export class SeasonListComponent {
  public title: string = 'Temporadas';
  public tableTitle: string = '';
  public actions2: any[] = [];
  public items: Object[];

  cols: TableColumn[] = [
    new TableColumn({name: 'Nombre', key: 'name', proportion: 14}),
    new TableColumn({name: 'Inicio', key: 'startDate', proportion: 8}),
    new TableColumn({name: 'Fin', key: 'endDate', proportion: 8}),
    new TableColumn({name: 'Estado',key: 'updatedAt', proportion: 6})
  ];

  tools: ToolBase<any>[] = [
    new IconTool({
      title: 'Crear',
      icon: 'create',
      clicked: this.create.bind(this)
    })
  ];

  actions = [
    {
      html: (item) => 'Actualizar',
      click: (item) => this.update(item)
    },
    {
      html: (item) => 'Eliminar',
      click: (item) => this.confirmationDelete(item)
    }
  ];

  confirmation: Confirmation = {hiddenClose: true};
  @ViewChild('tableCmp') tableCmp;
  pager: any;
  questionFilters: QuestionFilterBase<any>[] = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private seasonService: SeasonService,
    private statusService : StatusService,
    private tableService: TableService,
    private filterService: FilterService,
    private notificationService: NotificationService,
  ){}

  ngOnInit(){
    this.tableService.setSort(this.cols[3].key);
    this.filter();
    this.list(this.tableService.pager.pageIndex);
  }

  formatDate(format: string) : string{
    let date = new Date(format); // had to remove the colon (:) after the T in order to make it work
    let day = date.getDate();
    let monthIndex = date.getMonth();
    let year = date.getFullYear();
    let month = (monthIndex+1).toString();
    if(month.length == 1) month = "0"+month;
    return day+"/"+month+"/"+year;
  }

  filter(){
    //Componente para filtrar de acuerdo al nombre de la temporada
    let questionFilterName =
      new QuestionFilterTextbox({
        key: 'iname',
        label: 'Nombre',
        value: this.filterService.filter['iname']!=undefined? this.filterService.filter['iname']: '',
        optionsKey: 'iname',
        proportion: 2
      });

    this.questionFilters = [
      questionFilterName
    ];
  }

  list(page?: number){

    //Fixing sort
    if(this.tableService.sort == 'updatedAt')
      this.tableService.sort = 'status';
    else if(this.tableService.sort == '-updatedAt')
      this.tableService.sort = '-status';

      this.seasonService.getAll(this.seasonService.buildRequestOptionsFinder(this.tableService.sort,
        undefined, this.filterService.filter, {pageIndex: page, pageSize: this.tableService.pager.pageSize})).subscribe(
        response => {
          this.items = response['result'];
          //Por cada temporada se busca su estado
          for (let item of this.items) {
            item['startDate'] = this.formatDate(item['startDate']);
            item['endDate'] = this.formatDate(item['endDate']);
            this.statusService.getById(item['status']['id']).subscribe(status => {
              item['updatedAt'] = status.description;
            });
          }
          this.pager = response['pager'];
          this.tableService.pager.pageIndex = page;
          this.tableCmp.deselectAll();
          this.filterService.filter = {};
        }, (err) => {
          this.notificationService.error('Error del servidor');
        }
      );
  }

  create(){
    this.router.navigate(['./create'], {relativeTo: this.activatedRoute});
  }

  read(item: Season){
    this.router.navigate(['./' + item.id], {relativeTo: this.activatedRoute});
  }

  update(item: Season){
    this.router.navigate(["./" + item.id + '/update'], {relativeTo: this.activatedRoute});
  }

  confirmationDelete(item){
    this.confirmation = {
      title : "Confirmacion de eliminación",
      message: "¿Está seguro que desea eliminar la temporada seleccionada?",
      yesClicked: this.delete.bind(this, item),
      hiddenClose: false
    };
  }

  delete(this, item: Season){
    if(!this.seasonService.checkRoles(['season_manager'])) {
      this.notificationService.error('Usted no cuenta con acceso a esta función. Contacte al administrador');
      return;
    }
    this.seasonService.getById(item.id).subscribe(season => {
      let sea = new Season(season);
      sea.status.id = 2;
      this.seasonService.update(sea).subscribe(any => {
        this.tableService.setSort(this.cols[0].key);
        this.filter();
        this.list(this.tableService.pager.pageIndex);
      }, (err) => {this.notificationService.error(err); });
    }, (err) => { this.notificationService.error(err); });
  }
}
