import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {SharedModule} from "../shared/shared.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {homeRouting} from "../home/home.routes";
import {HttpClientModule} from "@angular/common/http";
import {ClientListComponent} from "./client-list.component";
import {ClientUpdateComponent} from "./client-update.component";
import {ClientReadComponent} from "./client-read.component";
import {ClientCreateComponent} from "./client-create.component";
import {ClientComponent} from "./client.component";
import {UserService} from "../user/user.service";
import { ClientGuardService } from "./client-guard.service";

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    homeRouting,
    ReactiveFormsModule,
    HttpClientModule
  ],
  declarations:[
    ClientComponent,
    ClientListComponent,
    ClientUpdateComponent,
    ClientReadComponent,
    ClientCreateComponent
  ],
  providers:[
    UserService,
    ClientGuardService
  ]
})

export class ClientModule { }
