<h3>{{title}}</h3>

<filter-ns *ngIf="questionFilters!=undefined"
	[questionFilters]="questionFilters"
	(list)="list($event)"
	(borrar) = "borrar($event)">
</filter-ns>

<tool-ns
	[tools]="tools">
</tool-ns>

<table-ns-menu
	#tableCmp
	[title]="tableTitle"
	[items]="items"
	[cols]="cols"
	[actions2]="actions2"
	[actions]="actions"
	(read)="read($event)"
	(list)="list($event)">
</table-ns-menu>

<pagination *ngIf="pager!=undefined && pager.pages > 0"
    [totalItems]="pager.totalEntities"
    [initPage]="pager.pageIndex+1"
    [pageSize]="pager.pageSize"
    (paginated)=list($event)>
</pagination>

<confirm-ns
	[title]="confirmation.title"
	[message]="confirmation.message"
	[(hiddenClose)]="confirmation.hiddenClose"
	(yesClicked)="confirmation.yesClicked($event)">
</confirm-ns>
