import {Component} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {Status} from "../status/status";
import {StatusService} from "../status/status.service";
import {NotificationService} from "../shared/notification/notification.service";
import { Location } from '@angular/common';
import {ActivatedRoute, Router} from "@angular/router";
import {StockIndice} from "./stockindice";
import {StockIndiceService} from "./stockindice.service";
@Component({
  templateUrl: './stockindice.component.html',
  styleUrls: ['stockindice.component.css']
})

export class StockIndiceUpdateComponent{

  public title: string = 'Actualizar el índice de mercado';
  public stockIndice: StockIndice;
  public selectedShowMainHome : boolean;
  public variation: number = 3;
  public status : Status[];
  public selectedStatus : Status;

  stockIndiceForm = this.fb.group({
    name: ['', [Validators.required, Validators.maxLength(125)]],
    type: [''],
    currentValue: ['', [Validators.required, Validators.maxLength(125)]],
    value: ['', [Validators.required, Validators.pattern("^[0-9\-]{1,5}([,.][0-9]{1,6})?$")]],
    changeValue: ['', [Validators.required, Validators.pattern("^[0-9\-]{1,5}([,.][0-9]{1,6})?$")]]
  });

  constructor(
    private fb: FormBuilder,
    private location: Location,
    private router: Router,
    private statusService : StatusService,
    private stockindiceService : StockIndiceService,
    private activatedRouteService : ActivatedRoute,
    private notificationService : NotificationService
  ){

  }

  onlyNumberKey(event){
    if (event.charCode == 45) return true;
    if (event.charCode == 46) return true;
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  ngOnInit(){
    this.statusService.getAll({type: 'stockindice'}).subscribe(response => {
      this.status = <Status[]> response['result'];

      this.activatedRouteService.parent.params.subscribe(param => {

        this.stockindiceService.getById(param['stockindiceID']).subscribe(result => {
          console.log(result);
          this.stockIndice = new StockIndice(result);
          this.stockIndiceForm.controls['name'].setValue(this.stockIndice.name);
          this.stockIndiceForm.controls['type'].setValue(this.stockIndice.type);
          this.stockIndiceForm.controls['value'].setValue(this.stockIndice.valueNoRound);
          this.stockIndiceForm.controls['changeValue'].setValue(this.stockIndice.changeValueNoRound);
          this.stockIndiceForm.controls['currentValue'].setValue(this.stockIndice.currentValue);
          this.variation = this.stockIndice.variation;

          this.activatedRouteService.parent.parent.parent.params.subscribe(param => {

          if(this.stockIndice.indiceType.id != param['indicetypeID']){
            this.notificationService.genericerror('URL no válida', '');
            this.router.navigateByUrl('/home');
            return;
          }

          this.selectedShowMainHome = this.stockIndice.showMainHome;

          let id = result.status.id;
          for(let stat of this.status){
            if(stat.id == id){
              this.selectedStatus = stat;
            }
          }
          }, (err) => {
            this.notificationService.error('Hubo un problema obteniendo los datos de la URL') });
        }, (err) => {
          this.notificationService.error('Hubo un problema cargando la información');
        })
      }, (err) => {
        this.notificationService.error('Hubo un problema obteniendo los datos de la URL');
      });
    }, (err) => { this.notificationService.error('Hubo un problema cargando la información'); });
  }

  action($event){
    if(this.stockIndiceForm.valid) {
      this.stockIndice.name = this.stockIndiceForm.controls['name'].value;
      this.stockIndice.type = this.stockIndiceForm.controls['type'].value;
      this.stockIndice.value = this.stockIndiceForm.controls['value'].value;
      this.stockIndice.changeValue = this.stockIndiceForm.controls['changeValue'].value;
      this.stockIndice.showMainHome = this.selectedShowMainHome;
      this.stockIndice.variation = this.variation;
      this.stockIndice.currentValue = this.stockIndiceForm.controls['currentValue'].value;

      let obj = <Object> this.stockIndice;
      obj['indiceType'] = {'id' : this.stockIndice.indiceType.id };
      obj['status'] = {'id' : this.selectedStatus.id };
      this.stockindiceService.update(<StockIndice> obj).subscribe(response => {
        this.notificationService.sucessUpdate(this.stockIndice.name);
        this.location.back();
      }, (err) => { this.notificationService.error('Error al actualizar el índice de mercado') })
    }
    else{
      this.notificationService.error('Verifique los datos del formulario');
    }
  }
}

