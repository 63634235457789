import {Injectable} from "@angular/core";
import {BaseService} from "../shared/services/base.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs/Rx";
import {User} from "./user";
import {catchError} from "rxjs/operators";

@Injectable()
export class UserService extends BaseService {

  BASE_URL:string = this.HOST + '/users';

  constructor(private http:HttpClient) {
    super();
  }

  getById(id: number) : Observable<User>{
    let params = new HttpParams().append('id', id.toString());
    return this.http.get(this.HOST + '/user', { headers: this.createAuthorizationHeader(),
    params: params }).map(this.extractData).pipe(
      catchError(this.handleError)
    );
  }

  getAll(params : any = '{ }') : Observable<Object>{
    return this.http.get(this.BASE_URL, {
      params: params,
      headers: this.createAuthorizationHeader()
    }).pipe(
      catchError(this.handleError)
    );
  }

  create(user : User) : Observable<User>{
    return this.http.post(this.BASE_URL, user, { headers: this.createAuthorizationHeader() }).map(this.extractData).pipe(
      catchError(this.handleError)
    );
  }

  update(user : User) : Observable<User>{
    let params = new HttpParams().append('id', user.id.toString());
    return this.http.put(this.BASE_URL, user,
      { params: params,
      headers: this.createAuthorizationHeader() }).map(this.extractData).pipe(
      catchError(this.handleError)
    );
  }
}
