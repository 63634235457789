import {Routes} from "@angular/router/router";
import {IndiceTypeComponent} from "./indicetype.component";
import {IndiceTypeListComponent} from "./indicetype-list.component";
import {IndiceTypeCreateComponent} from "./indicetype-create.component";
import {IndiceTypeUpdateComponent} from "./indicetype-update.component";
import {IndiceTypeReadComponent} from "./indicetype-read.component";
import {stockindiceRoutes} from "../stock-indice/stockindice.routes";
import {IndiceTypeGuardService} from "./indicetype-guard.service";

export const indicetypeRoutes: Routes = [
  {
    path: 'indicetypes',
    component: IndiceTypeComponent,
    canActivateChild: [IndiceTypeGuardService],
    data: {
      breadcrumb: "Tipos de índices",
      icon: "list"
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: IndiceTypeListComponent,
        data: {
          breadcrumb: undefined
        }
      },
      {
        path:'create',
        component: IndiceTypeCreateComponent,
        canActivate: [IndiceTypeGuardService],
        data: {
          breadcrumb : "Crear"
        }
      },
      {
        path: ':indicetypeID',
        component: IndiceTypeComponent,
        data: {
          breadcrumb: "Detalle",
          icon: "detail"
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: IndiceTypeReadComponent,
            data: {
              breadcrumb: undefined,
            }
          },
          {
            path: 'update',
            component: IndiceTypeUpdateComponent,
            canActivate: [IndiceTypeGuardService],
            data: {
              breadcrumb: "Actualizar",
              icon: "update"
            }
          },
          ...stockindiceRoutes
        ]
      }
    ]
  }
]
