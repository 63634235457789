import { Component, OnInit, Input } from '@angular/core';
import {NotificationsService} from "angular2-notifications";

@Component({
	selector: 'notification',
	template: `
		<simple-notifications 
			[options]="options"
			(onCreate)="onCreate($event)"
			(onDestroy)="onDestroy($event)">
		</simple-notifications>
	`
})
export class NotificationComponent implements OnInit {
	@Input() content: JSON;

	public options = {
		position: ['bottom', 'right'],
		timeOut: 2500,
		preventDuplicates: true
	};

	constructor(private service: NotificationsService) { }

	ngOnInit() {
		if (this.content) {
			this.createNotification();
		};
	}

	createNotification() {
		switch (this.content['type']) {
			case 'success':
				this.service.success('Notification', this.content['message']);
				break;
			case 'alert':
				this.service.alert('Notification', this.content['message']);
				break;
			case 'error':
				this.service.error('Error', this.content['message']);
				break;
		}
	}

	onCreate(event) { /*console.log(event);*/ }

	onDestroy(event) { /*console.log(event);*/ }
}
