import {BaseModel} from "../shared/models/base.model";
import {Status} from "../status/status";
import {IndiceType} from "../indice-type/indicetype";
export class StockIndice extends BaseModel{
  name: string;
  value: number;
  valueNoRound: number;
  changeValueNoRound: number;
  changeValue: number;
  variation: number;
  currentValue: string;
  type: string;
  showMainHome: boolean;
  indiceType: IndiceType;
  status: Status;

  constructor(object){
    super(object);
    if(object.name != undefined)
      this.name = object.name;
    if(object.currentValue != undefined)
      this.currentValue = object.currentValue;
    if(object.variation != undefined)
      this.variation = object.variation;
    if(object.value != undefined)
      this.value = object.value;
    if(object.valueNoRound != undefined)
      this.valueNoRound= object.valueNoRound;
    if(object.changeValue != undefined)
      this.changeValue = object.changeValue;
    if(object.changeValueNoRound != undefined)
      this.changeValueNoRound = object.changeValueNoRound;
    if(object.type != undefined)
      this.type = object.type;
    if(object.showMainHome != undefined)
      this.showMainHome = object.showMainHome;
    if(object.status != undefined)
      this.status = new Status(object.status);
    if(object.indiceType != undefined)
      this.indiceType = new IndiceType(object.indiceType);
  }
}
