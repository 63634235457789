import {Routes} from "@angular/router/router";
import {ProviderComponent} from "./provider.component";
import {ProviderListComponent} from "./provider-list.component";
import {ProviderCreateComponent} from "./provider-create.component";
import {ProviderReadComponent} from "./provider-read.component";
import {ProviderUpdateComponent} from "./provider-update.component";
import {postRoutes} from "../post/post.routes";
import { ProviderGuardService } from './provider-guard.service';

export const providerRoutes: Routes = [
  {
    path: 'providers',
    component: ProviderComponent,
    canActivateChild: [ProviderGuardService],
    data: {
      breadcrumb: "Proveedores",
      icon: "list"
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: ProviderListComponent,
        data: {
          breadcrumb: undefined
        }
      },
      {
        path:'create',
        component: ProviderCreateComponent,
        canActivate: [ProviderGuardService],
        data: {
          breadcrumb : "Crear"
        }
      },
      {
        path: ':providerID',
        component: ProviderComponent,
        data: {
          breadcrumb: "Detalle",
          icon: "detail"
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: ProviderReadComponent,
            data: {
              breadcrumb: undefined,
            }
          },
          {
            path: 'update',
            component: ProviderUpdateComponent,
            canActivate: [ProviderGuardService],
            data: {
              breadcrumb: "Actualizar",
              icon: "update"
            }
          },
          ...postRoutes
        ]
      }
    ]
  }
]
