import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule }  from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';

import { AuthService } from './auth.service';
import { AuthGuard } from './auth.guard';
import { ForgotPasswordComponent } from './forgotPassword/forgotPassword.component';
import { LoginComponent } from './login/login.component';
import {UserService} from '../../user/user.service';
import {ResetPasswordComponent} from './resetPassword/resetpassword.component';
import { ResetsuccessComponent } from './resetPassword/resetsuccess/resetsuccess.component';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		FormsModule,
		ReactiveFormsModule
	],
	declarations: [
		ForgotPasswordComponent,
    ResetPasswordComponent,
		LoginComponent,
		ResetsuccessComponent
	],
	providers: [
		AuthService,
    UserService,
		AuthGuard
	]
})

export class AuthModule { }
