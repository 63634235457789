import {Component} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {NotificationService} from "../shared/notification/notification.service";
import { Location } from '@angular/common';
import {ActivatedRoute} from "@angular/router";
import {BaseService} from "../shared/services/base.service";
import {User} from "../user/user";
import {UserService} from "../user/user.service";
import {ConfigService} from "../config/config.service";
import {Status} from "../status/status";
import {StatusService} from "../status/status.service";

@Component({
  templateUrl: './banner.component.html',
  styleUrls: ['banner.component.css']
})

export class BannerUpdateComponent{

  public title: string = 'Actualizar banner';
  public obj = {};
  public image;
  public url;
  public status : Status[];
  public selectedStatus : Status;

  constructor(
    private fb: FormBuilder,
    private location: Location,
    private configService: ConfigService,
    private statusService: StatusService,
    private activatedRouteService : ActivatedRoute,
    private notificationService : NotificationService
  ){

  }

  readUrl(event:any) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.onload = (event: ProgressEvent) => {
        this.image = (<FileReader>event.target).result;
      }

      reader.readAsDataURL(event.target.files[0]);
    }
  }

  ngOnInit(){
    this.statusService.getAll({type: 'banner'}).subscribe(response => {
      this.status = <Status[]> response['result'];

      this.configService.getAll({ key: 'main_banner_status' }).subscribe(resp => {
        let stat = <number> resp['result'][0]['valueConfig'];

        this.status.forEach( (st) => {
          if(st.id == stat){
            this.selectedStatus = st;
          }
        });

        this.configService.getAll({ key: 'main_banner_url' }).subscribe(resp => {
          this.url = resp['result'][0]['valueConfig'];

        }, (err) => { this.notificationService.error('Hubo un problema cargando la información'); });

      }, (err) => { this.notificationService.error('Hubo un problema cargando la información'); });

    }, (err) => { this.notificationService.error('Hubo un problema cargando la información'); });

  }

  action($event){
    this.obj = {
      media: this.image,
      status: this.selectedStatus.id
    };
    console.log(this.obj);
    this.configService.updateBanner(this.obj).subscribe(resp => {
      this.notificationService.sucessUpdate("Banner");
    }, (err) => { this.notificationService.error('Hubo un problema actualizando la información'); });
  }
}

